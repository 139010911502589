<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useMessageStore } from '../../stores/message'

import IconClose from '../icons/IconClose.vue'

const props = withDefaults(defineProps<{ timeout?: number }>(), { timeout: 3000 })

const messageStore = useMessageStore()
const { resetStore } = messageStore
const { message, type } = storeToRefs(messageStore)

const close = () => {
  resetStore()
}

watch(message, () => {
  if (message.value) {
    setTimeout(resetStore, props.timeout)
  }
})
</script>

<template>
  <div class="">
    <div v-if="message" class="modal alert-modal" v-motion-slide-visible-bottom>
      <div class="close">
        <IconClose class="text-primary" :style="{ width: '28px', height: '28px' }" @click="close" />
      </div>
      <div class="text" :class="[type == 'error' ? 'error' : 'success']">{{ message }}</div>
    </div>
  </div>
</template>

<style scoped>
.alert-modal {
  z-index: 110;
  padding: 72px 24px 48px;
}
.text {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}
.success {
  color: var(--app-primary);
}
.error {
  color: var(--app-red);
}
</style>

import { useApi } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type User = App.DTO.User.UserData

export const useAuthStore = defineStore('auth', () => {
  const token = ref<string | null>(null)
  const user = ref<User | null>(
    null
    // {
    //   id: '2',
    //   name: 'user',
    //   created_at: '12345',
    //   updated_at: '12345',
    //   telegram_id: 7474223,
    //   referer_code: 234,
    //   rank_id: 2,
    //   profile_image_url: '',
    //   wallet_address_shortened: '',
    //   bull_avatar_url: '/img/7_Cosmo_nohorn.webp'
    // }
  )
  const userPhotoUrl = ref<any>('')
  const userLocale = ref<string>('en')

  const refererCode = computed(() => user.value?.referer_code)

  const userBullAvatarUrl = ref<string | null>(null)

  async function loginUser(
    initData: string,
    languageCode: string | undefined
  ): Promise<User | undefined> {
    if (!initData) {
      console.warn('No initData provided')
      return
    }

    if (languageCode) {
      userLocale.value = languageCode
    }

    if (user.value) {
      return
    }

    if (!token.value) {
      const loginResponse = await useApi().post(`/telegram-webapp/login?${initData}`)

      if (loginResponse.data.access_token) {
        token.value = loginResponse.data.access_token
      }
    }

    try {
      return await getUser()
    } catch (error) {
      console.error('Error logging in', error)
    }
  }

  async function getUser(): Promise<User> {
    const { data } = await useApi().get<User>('/api/user')

    if (data.profile_image_url) {
      userPhotoUrl.value = data.profile_image_url
    }

    if (data.bull_avatar_url) {
      userBullAvatarUrl.value = data.bull_avatar_url
    }

    return (user.value = data)
  }

  function setUserRankId(rankId: number) {
    if (user.value) {
      user.value.rank_id = rankId
    }
  }

  return {
    user,
    loginUser,
    token,
    userPhotoUrl,
    getUser,
    setUserRankId,
    refererCode,
    userLocale,
    userBullAvatarUrl
  }
})

<script setup lang="ts">
import { useUserBalanceStore } from '@/stores/userBalance'
import {
  usePassiveIncomeStore,
  type PassiveIncome,
  type PassiveIncomesForCoins
} from '@/stores/userPassiveIncome'
import { amountFormat } from '@/utils/amountFormat'
import WebApp from '@twa-dev/sdk'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import SpecialOfferBanner from './common/SpecialOfferBanner.vue'

const TAB_FOR_STARS = 'passiveIncome.forStars'
const TAB_FOR_COINS = 'passiveIncome.forCoins'
// const TAB_FOR_VIDEO = 'passiveIncome.forVideo'

const activeTab = ref(TAB_FOR_STARS)

const { t } = useI18n()

const passiveIncome = usePassiveIncomeStore()
const { passiveIncomes, passiveIncomesForCoins } = storeToRefs(passiveIncome)
const {
  getUserTotalPassiveIncome,
  getPassiveIncomes,
  getPassiveIncomesForCoins,
  buyPassiveIncomeForCoins
} = passiveIncome

const userBalanceStore = useUserBalanceStore()
const { getBalance } = userBalanceStore
const { balance } = storeToRefs(userBalanceStore)

const isStarsTab = computed(() => activeTab.value == TAB_FOR_STARS)
const isCoinsTab = computed(() => activeTab.value == TAB_FOR_COINS)
// const isVideosTab = computed(() => activeTab.value == TAB_FOR_VIDEO)

const hasSpecialOffer = computed(() => {
  switch (activeTab.value) {
    case TAB_FOR_STARS:
      return passiveIncomes.value.some((el) => el.special_cost !== el.stars_cost)

    // case TAB_FOR_COINS:
    //   return passiveIncomesForCoins.value.some((el) => el.special_cost)

    default:
      return false
  }
})

const openPassiveIncomeInvoice = (invoiceLink: string) => {
  WebApp.openInvoice(invoiceLink, (status) => {
    if (status === 'paid') {
      setTimeout(() => {
        getUserTotalPassiveIncome()
      }, 5000)
    }
  })
}

const payByCoins = async (income: PassiveIncomesForCoins) => {
  if (balance.value < income.coins_cost) return

  try {
    await buyPassiveIncomeForCoins(income.id)
    await getBalance()
  } catch {
    //
  }
}

const discountValue = (income: PassiveIncome) => {
  const costDiff = income.stars_cost - (income.special_cost || 0)

  return Math.floor((costDiff / income.stars_cost) * 100)
}

onMounted(() => {
  Promise.all([getPassiveIncomes(), getPassiveIncomesForCoins()])
})
</script>

<template>
  <div class="">
    <div class="passive-income-tabs text-primary">
      <button class="passive-income-tab" @click="activeTab = TAB_FOR_STARS">
        <img
          :src="isStarsTab ? `/img/passive/for_stars_active.webp` : `/img/passive/for_stars.webp`"
          class="ofi-a"
        />
        <div class="passive-income-tab-text">{{ t('passiveIncome.forStars') }}</div>
      </button>
      <button class="passive-income-tab" @click="activeTab = TAB_FOR_COINS">
        <img
          :src="isCoinsTab ? `/img/passive/for_coins_active.webp` : `/img/passive/for_coins.webp`"
          class="ofi-a"
        />
        <div class="passive-income-tab-text">{{ t('passiveIncome.forCoins') }}</div>
      </button>
    </div>

    <!-- specialOffer  -->

    <SpecialOfferBanner class="mt-4" v-if="hasSpecialOffer" />

    <div class="mt-4">
      <!-- For Stars  -->
      <div v-if="isStarsTab" class="passive-income-grid">
        <div
          class="passive-income-grid-item"
          v-for="income in passiveIncomes"
          :key="income.id"
          @click="openPassiveIncomeInvoice(income.invoice_link)"
        >
          <img src="/img/passive/green_button_star.webp" class="ofi-a" />
          <div class="passive-income-grid-item-title">
            {{ `${income.coins_per_hour}/${t('common.hr')}` }}
          </div>
          <div class="passive-income-grid-item-value flex items-center gap-1">
            <span
              v-if="income.special_cost !== income.stars_cost"
              class="passive-income-grid-offer-value"
            >
              {{ income.special_cost }}
            </span>

            <span
              :class="{
                'passive-income-grid-item-value_muted': income.special_cost !== income.stars_cost
              }"
            >
              {{ income.stars_cost }}
            </span>
          </div>

          <div v-if="income.special_cost !== income.stars_cost" class="card-discount">
            -{{ discountValue(income) }}%
          </div>
        </div>
      </div>

      <!-- For Coins  -->
      <div v-if="isCoinsTab" class="passive-income-grid">
        <div
          class="passive-income-grid-item"
          v-for="income in passiveIncomesForCoins"
          :key="income.id"
          :class="{ disabled: balance < income.coins_cost }"
          @click="payByCoins(income)"
        >
          <img src="/img/passive/green_button_coin.webp" class="ofi-a" />
          <div class="passive-income-grid-item-title">
            {{ `${income.coins_per_hour}/${t('common.hr')}` }}
          </div>
          <div class="passive-income-grid-item-value">
            {{ amountFormat(income.coins_cost) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.passive-income-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.passive-income-tabs {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.passive-income-tab {
  padding: 0;
  width: 100%;
  height: 40px;
  border: none;
  background: none;
  position: relative;
}
.passive-income-tab-text {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 39%;
  transform: translateY(-50%);
  font-family: Urbanist;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--white);
}
.passive-income-grid-item {
  width: 100%;
  aspect-ratio: 1;
  background: transparent;
  position: relative;
}
.passive-income-grid-item-title {
  color: var(--white, #fff);
  font-family: Urbanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.075px;
}
.passive-income-grid-item-value {
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.065px;
}
.passive-income-grid-item-value_muted {
  color: #97a688;
  font-size: 10px;
  text-decoration: line-through;
}

.passive-income-grid-offer-value {
  color: var(--red, #fe5258);
  margin-left: 16%;
}
.passive-income-grid-item-title,
.passive-income-grid-item-value {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-wrap: nowrap;
}

.passive-income-grid-item-title {
  top: 12%;
}
.passive-income-grid-item-value {
  bottom: 19%;
}
.passive-income-grid-item.disabled {
  opacity: 0.5;
}
.card-discount {
  position: absolute;
  bottom: 35px;
  right: 10px;
  padding: 1px 2px;
  border-radius: 11px;
  background: var(--red);
  color: var(--white);
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.05px;
}

@media (min-width: 410px) {
  .passive-income-tabs {
    gap: 16px;
  }
  .passive-income-grid {
    gap: 12px;
  }
}
</style>

<script setup lang="ts">
import type { RoundTickers } from '@/stores/tournament'
import { computed } from 'vue'
import IconDecrease from '../icons/IconDecrease.vue'
import IconIncrease from '../icons/IconIncrease.vue'

interface Props {
  round: RoundTickers
  isInProgress: boolean
}

const props = defineProps<Props>()

const isSelectedUp = computed(() => props.round?.user_bid == 'up')
const isSelectedDown = computed(() => props.round?.user_bid == 'down')
const isBlurred = computed(() => props.round.is_registered || props.isInProgress)
</script>

<template>
  <div class="ticker" :class="{ blurred: isBlurred }">
    <div class="ticker-img">
      <img :src="`/img/currencies/${round.ticker}.webp`" alt="" />
      <div class="ticker-badge" :class="{ down: isSelectedDown, up: isSelectedUp }">
        <IconDecrease v-if="isSelectedDown" class="text-black" style="width: 12px" />
        <IconIncrease v-if="isSelectedUp" class="text-black" style="width: 12px" />
      </div>
    </div>
    <div class="">{{ round.ticker }}</div>
  </div>
</template>

<style scoped>
.ticker {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.005em;
  gap: 15px;
  text-transform: uppercase;
}
.ticker.blurred {
  opacity: 0.5;
}
.ticker-img {
  position: relative;
  width: 54px;
  height: 54px;
}
.ticker-img img {
  width: 100%;
  min-width: 100%;
  object-fit: contain;
}
.ticker-badge {
  position: absolute;
  top: 0;
  right: -5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticker-badge.down {
  background: var(--red);
}
.ticker-badge.up {
  background: var(--primary);
}
</style>

<script setup lang="ts">
import { useGameEventsStore } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import ComingSoon from './common/ComingSoon.vue'
import Container from './common/Container.vue'
import EventTab from './event/Event.vue'
import Registration from './event/Registration.vue'
import Results from './event/Results.vue'

const userEventStore = useGameEventsStore()
const { getCurrentEventPhase } = userEventStore
const { activeEventPhase, eventId } = storeToRefs(userEventStore)

const currentEventPhase = computed(() => {
  if (!activeEventPhase.value) return null

  switch (activeEventPhase.value?.id) {
    case 7:
      return Registration
    case 8:
      return EventTab
    case 9:
      return Results
    default:
      return null
  }
})

const componentForNonRegistered = computed(() => {
  switch (activeEventPhase.value?.id) {
    case 7:
    case 8:
      return Registration
    case 9:
      return Results
    default:
      return null
  }
})

onMounted(async () => {
  await getCurrentEventPhase(eventId.value)
})
</script>

<template>
  <Container>
    <div v-if="currentEventPhase === null">
      <ComingSoon />
    </div>
    <component v-else-if="activeEventPhase?.is_registered" :is="currentEventPhase" />
    <component v-else :is="componentForNonRegistered" />
  </Container>
</template>

<style scoped></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke-width="1.5"
    stroke="currentColor"
    fill="currentColor"
    class="size-6"
  >
    <path
      d="M16.59 7.41L6.31 17.69C5.83 18.17 5.01 18.06 4.72 17.45C4.2 16.38 3.9 15.17 3.9 13.9C3.88 8.38 9.48 3.66 11.38 2.21C11.75 1.93 12.25 1.93 12.61 2.21C13.48 2.87 15.11 4.24 16.64 6.04C16.98 6.44 16.96 7.04 16.59 7.41Z"
    />
    <path
      opacity="0.4"
      d="M20.1 13.91C20.1 18.37 16.47 22 12 22C10.21 22 8.54 21.42 7.19 20.42C6.7 20.06 6.66 19.34 7.09 18.91L17.16 8.84C17.63 8.37 18.42 8.47 18.74 9.05C19.56 10.56 20.11 12.2 20.1 13.91Z"
    />
  </svg>
</template>

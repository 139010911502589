import { useApi } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type PassiveIncome = App.DTO.PassiveIncome.PassiveIncomeData
//TODO delete "&{special_cost?: number}" after adding at the BE
export type PassiveIncomesForCoins = { id: number; coins_per_hour: number; coins_cost: number }

export const usePassiveIncomeStore = defineStore('passiveIncome', () => {
  const passiveIncomes = ref<PassiveIncome[]>([])
  const passiveIncomesForCoins = ref<PassiveIncomesForCoins[]>([])

  const userTotalPassiveIncome = ref(0)

  async function getPassiveIncomes() {
    try {
      passiveIncomes.value = (await useApi().get('/api/passive-income'))?.data?.data || []
    } catch (error) {
      console.error('Error getting passive incomes', error)
      // passiveIncomes.value = [
      //   { id: 1, coins_per_hour: 500, stars_cost: 250, invoice_link: '' },
      //   { id: 2, coins_per_hour: 1000, stars_cost: 400, invoice_link: '', special_cost: 300 },
      //   { id: 3, coins_per_hour: 2000, stars_cost: 750, invoice_link: '' },
      //   { id: 4, coins_per_hour: 5000, stars_cost: 1600, invoice_link: '' }
      // ]
    }
  }
  async function getPassiveIncomesForCoins() {
    try {
      passiveIncomesForCoins.value =
        (await useApi().get('/api/passive-income-for-coins'))?.data?.data || []
    } catch (error) {
      console.error('Error getting passive incomes', error)
      // passiveIncomesForCoins.value = [
      //   { id: 1, coins_per_hour: 500, coins_cost: 500000 },
      //   { id: 2, coins_per_hour: 750, coins_cost: 750000 },
      //   { id: 3, coins_per_hour: 1000, coins_cost: 950000 },
      //   { id: 4, coins_per_hour: 1500, coins_cost: 1350000 }
      // ]
    }
  }

  async function buyPassiveIncomeForCoins(passiveIncomeId: number) {
    await useApi()
      .post(`/api/buy-passive-for-coins/${passiveIncomeId}`)
      .then(() => {
        getPassiveIncomes()
        getUserTotalPassiveIncome()
      })
  }

  async function getUserTotalPassiveIncome() {
    try {
      userTotalPassiveIncome.value =
        (await useApi().get('/api/user-total-passive-income'))?.data?.data || 0
    } catch (error) {
      console.error('Error getting user total passive income', error)
    }
  }

  return {
    passiveIncomes,
    passiveIncomesForCoins,
    getPassiveIncomes,
    getPassiveIncomesForCoins,
    userTotalPassiveIncome,
    getUserTotalPassiveIncome,
    buyPassiveIncomeForCoins
  }
})

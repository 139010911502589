<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Container from './Container.vue'

const { t } = useI18n()

withDefaults(defineProps<{ title: string }>(), { title: 'ranking.yourPosition' })
</script>

<template>
  <Container class="current-position">
    <div class="current-position-text">{{ t(title) }}</div>

    <div class="current-position-item">
      <slot />
    </div>
  </Container>
</template>

<style scoped>
.current-position {
  position: fixed;
  z-index: 9;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 24px 24px 0px 0px;
  background: #0d0e0c;
}
.current-position-text {
  margin-top: 30px;
  margin-bottom: 10px;
  color: var(--white, #f3ffe9);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.current-position-item {
  margin-bottom: 120px;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 23 22"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
    width="23"
    height="22"
  >
    <path
      d="M15.1666 11.825V15.675C15.1666 18.8834 13.8833 20.1667 10.6749 20.1667H6.82492C3.61659 20.1667 2.33325 18.8834 2.33325 15.675V11.825C2.33325 8.61671 3.61659 7.33337 6.82492 7.33337H10.6749C13.8833 7.33337 15.1666 8.61671 15.1666 11.825Z"
    />
    <path
      opacity="0.3"
      d="M16.175 1.83337H12.325C9.16253 1.83337 7.8792 3.08921 7.84253 6.18754H10.675C14.525 6.18754 16.3125 7.97504 16.3125 11.825V14.6575C19.4109 14.6209 20.6667 13.3375 20.6667 10.175V6.32504C20.6667 3.11671 19.3834 1.83337 16.175 1.83337Z"
    />
  </svg>
</template>

<script setup lang="ts">
import useReferral from '@/application/services/useReferral'
import { useAuthStore } from '@/stores/auth'
import { notify } from '@/stores/message'
import { useUserClaimStore } from '@/stores/userClaim'
import { amountFormat } from '@/utils/amountFormat'
import { dateYYYYMMDD } from '@/utils/date'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import FullReferralList from './claim/FullReferralList.vue'
import ActionBtn from './common/ActionBtn.vue'
import BtnPrimary from './common/BtnPrimary.vue'
import DialogModal from './common/DialogModal.vue'
import ListItem from './common/ListItem.vue'
import UsersList, { type ListUser } from './common/UsersList.vue'
import IconFriend from './icons/IconFriend.vue'
import IconStarAverage from './icons/IconStarAverage.vue'
import IconUsd from './icons/IconUsd.vue'

const moreReferralsModal = ref(false)
const loading = ref(false)

const { t } = useI18n()

const { sharableLink } = useReferral()

const claimsStore = useUserClaimStore()
const { getReferralPayments, getTotalClaimAmountAvailable, submitClaim, getClaims } = claimsStore
const { referralPayments, totalClaimAmountAvailable, userClaims } = storeToRefs(claimsStore)

const authStore = useAuthStore()
const { getUser } = authStore
const { user } = storeToRefs(authStore)

const referralLinks = computed(() => {
  return referralPayments.value.map((referral) => ({
    id: referral.id,
    name: referral.user_name,
    balance: referral.balance,
    rank_id: referral.rank_id,
    profit: referral.total_payed
  })) as ListUser[]
})

const showMoreReferralsBtn = computed(() => referralLinks.value?.length > 10)

onMounted(async () => {
  loading.value = true
  await Promise.allSettled([
    getUser(),
    getReferralPayments(1, 10),
    getTotalClaimAmountAvailable(),
    getClaims()
  ]).finally(() => {
    loading.value = false
  })
})

const claim = () => {
  if (totalClaimAmountAvailable.value === 0) {
    notify('No claim available', 'error')
    return
  }

  submitClaim(totalClaimAmountAvailable.value).then(() => {
    notify(
      `Claimed! The amount will be sent to ${user.value?.wallet_address_shortened} within 24hrs`,
      'success'
    )
  })
}
const refreshUserList = () => {}

const inviteHandler = () => {
  if (!sharableLink.value) {
    notify('Async: No link to share', 'error')
    return
  }

  const link = document.createElement('a')

  link.href = sharableLink.value
  document.body.appendChild(link)
  link.click()
  link.remove()
}
const openMoreReferralsModal = () => {
  moreReferralsModal.value = true
}
</script>

<template>
  <div class="claim-body">
    <h2 class="mt-4 page-mid-title">{{ t('claimTab.getCashback') }}!</h2>

    <div class="text-center mt-8">
      <div class="earned">{{ t('claimTab.youEarned') }}</div>
      <div class="text-primary flex items-center justify-center earn-value">
        <IconUsd style="width: 48px; height: 48px" class="mr-1" />{{ totalClaimAmountAvailable }}
      </div>
      <button class="mt-4 claim-btn" @click="claim" disabled>{{ t('claimTab.claim') }}</button>
    </div>

    <UsersList
      class="mt-8"
      listTitle="claimTab.usersList"
      noUsersText="claimTab.noPurchases"
      :users="referralLinks"
      :loading="loading"
      @refresh="refreshUserList"
    >
      <template #profitIcon>
        <IconStarAverage style="width: 26px; height: 26px" class="text-yellow" />
      </template>
    </UsersList>

    <div v-if="showMoreReferralsBtn && !loading" class="my-4 text-center">
      <BtnPrimary @click="openMoreReferralsModal">{{ t('common.viewMore') }}</BtnPrimary>
    </div>

    <div class="section-title mt-4">{{ t('claimTab.claimed') }}</div>

    <ListItem>
      <div v-if="userClaims.length > 0" class="list-container">
        <div
          class="earns-container text-gray"
          style="padding: 0 8px; margin-bottom: 4px; margin-top: 12px"
        >
          <div class="">Date</div>
          <div class="earns-amount">Amount</div>
          <div class="earns-hash">Hash</div>
        </div>
        <div class="earn-body">
          <div v-for="claim in userClaims" :key="claim.id" class="my-2">
            <div class="earns-container">
              <div class="earn-date">{{ dateYYYYMMDD(claim.created_at) }}</div>
              <div class="earns-amount text-primary flex items-center">
                <IconUsd style="width: 16px; height: 16px" />
                <div class="text-bold">{{ amountFormat(claim.amount) }} TON</div>
              </div>
              <div class="earns-hash text-primary">
                <div>{{ claim.wallet_address_shortened }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>{{ t('claimTab.noEarningsYet') }}</div>
    </ListItem>

    <div class="invite-btn">
      <ActionBtn :text="t('claimTab.inviteAFriend')" @click="inviteHandler">
        <template #append-icon>
          <IconFriend style="width: 22px; height: 22px; max-width: 22px" />
        </template>
      </ActionBtn>
    </div>

    <DialogModal v-if="moreReferralsModal" @close="moreReferralsModal = false">
      <FullReferralList />
    </DialogModal>
  </div>
</template>

<style scoped>
.claim-body {
  padding-bottom: 70px;
}
.earned {
  font-size: 14px;
  color: #707d62;
}
.earn-value {
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 0.5%;
}
.claim-btn {
  padding: 8px 16px;
  background-color: var(--app-primary);
  border: none;
  border-radius: 18px;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 22px;
  font-weight: bold;
  color: var(--app-text-black);
}
.claim-btn:disabled {
  background-color: var(--app-primary-muted);
}
.earns-container {
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.5%;
}
.earns-container div {
  text-transform: capitalize;
}
.earns-amount {
  padding: 0 4px;
  justify-self: center;
  text-wrap: nowrap;
}
.earns-hash {
  justify-self: end;
  letter-spacing: 0.01rem;
}
.earn-body {
  max-height: 180px;
  overflow-y: auto;
}
.invite-btn {
  position: fixed;
  z-index: 10;
  margin: 0 8px;
  left: 0;
  right: 0;
  bottom: 114px;
}
.referral-full-list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--app-background);
  z-index: 101;
  padding: 0px 16px 40px;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke-width="1.5"
    stroke="currentColor"
    fill="currentColor"
    class="size-6"
  >
    <path
      d="M12 3.67V20.33L11.2 21.24C10.09 22.5 9.18 22.16 9.18 20.48V13.28H6.09C4.69 13.28 4.3 12.42 5.23 11.37L12 3.67Z"
    />
    <path
      opacity="0.4"
      d="M18.77 12.63L12 20.33V3.67L12.8 2.76C13.91 1.5 14.82 1.84 14.82 3.52V10.72H17.91C19.31 10.72 19.7 11.58 18.77 12.63Z"
    />
  </svg>
</template>

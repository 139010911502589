<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    stroke-width="1.5"
    class="size-6"
  >
    <!-- <g clip-path="url(#clip0_35_520)">
      <path
        opacity="0.4"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 24 12 24Z"
      />
      <path
        d="M16.0973 6.69759H7.90226C6.39548 6.69759 5.44046 8.32294 6.19851 9.6369L11.2562 18.4032C11.5862 18.9756 12.4133 18.9756 12.7434 18.4032L17.802 9.6369C18.5591 8.32504 17.604 6.69759 16.0983 6.69759H16.0973ZM11.2521 15.7743L10.1506 13.6426L7.49284 8.88917C7.31751 8.58493 7.53407 8.19506 7.90123 8.19506H11.251V15.7754L11.2521 15.7743ZM16.5046 8.88814L13.8479 13.6436L12.7464 15.7743V8.19403H16.0962C16.4634 8.19403 16.68 8.5839 16.5046 8.88814Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_520">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs> -->
    <g clip-path="url(#clip0_4079_3551)">
      <path
        opacity="0.4"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16343 24.8366 0 16 0C7.16343 0 0 7.16343 0 16C0 24.8366 7.16343 32 16 32Z"
      />
      <path
        d="M21.463 8.93011H10.5363C8.52724 8.93011 7.25386 11.0973 8.26461 12.8492L15.0082 24.5377C15.4482 25.3009 16.551 25.3009 16.9911 24.5377L23.736 12.8492C24.7454 11.1001 23.472 8.93011 21.4643 8.93011H21.463ZM15.0027 21.0325L13.534 18.1901L9.99038 11.8522C9.75661 11.4466 10.0453 10.9267 10.5349 10.9267H15.0013V21.0338L15.0027 21.0325ZM22.0061 11.8509L18.4638 18.1915L16.9952 21.0325V10.9254H21.4616C21.9511 10.9254 22.2399 11.4452 22.0061 11.8509Z"
      />
    </g>
    <!-- <defs>
      <clipPath id="clip0_4079_3551">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs> -->
  </svg>
</template>

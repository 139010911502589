<script setup lang="ts">
import { amountFormat } from '@/utils/amountFormat'
import { bonuses, type Bonus } from '../mockData/mock'

import { onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './common/Button_old.vue'
import CardWithBorderGradient from './common/CardWithBorderGradient.vue'
import IconBalance from './icons/IconBalance.vue'

const { t } = useI18n()
const bonusesList = reactive<Bonus[]>([])

const breakWords = (text: string): string => {
  const array = text.split(' ')
  return array.join('<br />')
}
onMounted(() => {
  bonusesList.push(...bonuses)
})
</script>

<template>
  <div v-motion-fade-visible>
    <div class="mt-9">
      <div class="bonus-container gap-3 mb-5">
        <Button reverse-bg style="padding: 10px 6px">{{ t('common.level') }}</Button>
        <Button reverse-bg style="padding: 10px 6px">{{ t('inviteTab.forFriend') }}</Button>
        <Button reverse-bg style="padding: 10px 6px">{{ t('common.premium') }}</Button>
      </div>

      <!-- <div v-s="bonusesList.length > 0"> -->
      <CardWithBorderGradient class="my-2" v-for="(bonus, ind) in bonusesList" :key="ind">
        <div class="bonus-item">
          <div class="bonus-container">
            <div class="flex items-center gap-2 bonus-container-name">
              <div class="circle">
                <img v-lazy="`/img/trophy/trophy_${bonus.id}.png`" style="width: 24px" />
              </div>
              <div class="bonus-title truncate" v-html="breakWords(t(bonus.level))"></div>
            </div>
            <div class="bonus-value">
              <IconBalance style="width: 17px; height: 17px" />
              <div>{{ amountFormat(bonus.forFriend) }}</div>
            </div>
            <div class="bonus-value bonus-value-premium pl-1">
              <IconBalance style="width: 17px; height: 17px" />
              <div>{{ amountFormat(bonus.premium) }}</div>
            </div>
          </div>
        </div>
      </CardWithBorderGradient>
      <!-- </div> -->

      <!-- <div v-else>
        <CardWithBorderGradient> No bonuses yet </CardWithBorderGradient>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.bonus-item {
  border-radius: 12px;
  background: #314222;
  padding: 10px 0;
  display: flex;
  width: 100%;
  align-items: center;
}
.bonus-container {
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 1fr 74px 74px;
  align-items: center;
  justify-items: center;
  text-transform: capitalize;
  gap: 12px;
}
.bonus-container {
  text-transform: capitalize;
}
.bonus-value {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.065px;
  justify-content: center;
  width: 100%;
}
.bonus-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  overflow: hidden;
  max-width: 100%;
  text-wrap: nowrap;
}
.circle {
  position: relative;
  overflow: hidden;
  width: 28px;
  aspect-ratio: 1;
  /* height: 31px; */
  border-radius: 100%;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}
.circle img {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}
.bonus-container-name {
  justify-self: self-start;
  padding-left: 10px;
}
</style>

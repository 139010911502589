<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import IconBalance from '../icons/IconBalance.vue'

interface Props {
  passiveIncome: number
  energy: string
}

defineProps<Props>()

const { t } = useI18n()
</script>

<template>
  <div class="flex justify-between items-center info">
    <div class="flex-icon">
      <img src="/img/icons/energy_v2.webp" width="24" height="24" alt="" />
      <div class="income-text">
        {{ energy }}
      </div>
    </div>
    <div class="flex-icon">
      <IconBalance class="info-drop" style="width: 28px; height: 28px" />
      <div class="income-text">+{{ `${passiveIncome}/${t('common.hr')}` }}</div>
    </div>
  </div>
</template>

<style scoped>
.flex-icon {
  display: flex;
  align-items: center;
  gap: 4px;
}
.income-text {
  color: var(--white);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
}
</style>

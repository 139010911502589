<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{ size?: number }>(), {
  size: 56
})

const styles = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`
}))
</script>

<template>
  <div class="icon-bg" id="icon-bg" :style="styles">
    <img src="../../assets/img/bg_small.png " alt="" />
    <div class="icon">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.icon-bg {
  position: relative;
}
.icon-bg img {
  width: 100%;
}
.icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script setup lang="ts">
import { type ICoinTransform, type INumberInfo } from '@/motions'
import { useAuthStore } from '@/stores/auth'
import { useUserTapPowerStore } from '@/stores/userAbilities'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const coinScale = 1
const numberAnimationDurationMs = 500
const initialCoinTransform = { scale: coinScale, translateZ: 0, rotateX: 0, rotateY: 0 }

const emits = defineEmits(['tap'])

const coinTransform = ref<ICoinTransform>({ ...initialCoinTransform })
const numbers = ref<INumberInfo[]>([])
const { userBullAvatarUrl } = storeToRefs(useAuthStore())
const bullRef = ref<HTMLElement | null>(null)

const { currentTapPower: tapPower } = storeToRefs(useUserTapPowerStore())

const handleTouchStart = (event: any) => {
  if (bullRef.value) {
    const touches = event.touches ? [...event.touches] : [event]
    // inf.value = event

    const touch = touches[0]
    const rect = bullRef.value.getBoundingClientRect()
    const centerX = rect.left + rect.width / 2
    const centerY = rect.top + rect.height / 2

    const offsetX = touch.clientX - centerX
    const offsetY = centerY - touch.clientY

    const rotateXValue = offsetY * 0.15
    const rotateYValue = offsetX * 0.15

    coinTransform.value = {
      scale: coinScale,
      translateZ: -5,
      rotateX: rotateXValue,
      rotateY: rotateYValue
    }

    // add number animation
    touches.forEach((touch: any, ind: number) => {
      const randomNumberBetweenTenAndMinusTen = Math.floor(Math.random() * 21) - 10

      const newNumber: INumberInfo = {
        id: `${Date.now()}-${ind}`,
        value: tapPower.value, // * 5,
        x: touch.clientX + randomNumberBetweenTenAndMinusTen - 40,
        y: touch.clientY
      }
      numbers.value = [...numbers.value, newNumber]

      // Remove the number after the animation duration
      setTimeout(() => {
        numbers.value = numbers.value.filter((number) => number.id !== newNumber.id)
      }, numberAnimationDurationMs + 50)
    })

    // reset animation to initial
    if (!event.touches) {
      setTimeout(() => {
        handleTouchEnd()
      }, 150)
    }
  }
}

const handleTouchEnd = () => {
  coinTransform.value = { ...initialCoinTransform }
}

const clickCredits = (e: Event) => {
  handleTouchStart(e)
  emits('tap')
}
</script>

<template>
  <div class="flex justify-center main-image relative" @click.prevent="clickCredits">
    <img
      v-if="userBullAvatarUrl"
      ref="bullRef"
      v-motion
      :initial="{ opacity: 0 }"
      :enter="{ opacity: 1, y: 0, scale: coinScale }"
      :delay="0"
      :duration="400"
      :src="userBullAvatarUrl"
      class="bull"
      @touchstart.prevent="clickCredits"
      :style="{
        transform: `
                scale(${coinTransform.scale})
                translateZ(${coinTransform.translateZ}px)
                rotateX(${coinTransform.rotateX}deg)
                rotateY(${coinTransform.rotateY}deg)
              `
      }"
      @touchend="handleTouchEnd"
    />

    <img
      v-for="number in numbers"
      :key="number.id"
      v-motion
      class="click-amount"
      :initial="{ opacity: 1, y: number.y - 50, x: number.x, scale: 1 }"
      :enter="{
        opacity: 0,
        y: number.y - 180,
        scale: 0.5,
        transition: {
          duration: numberAnimationDurationMs
        }
      }"
      src="/logo.svg"
      alt=""
    />
    <div class="main-shadow"></div>
  </div>
</template>

<style>
.main-image {
  margin: 0px auto;
  z-index: 10;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.bull {
  position: absolute;
  height: auto;
  max-width: 80%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  /* background: url('../assets/img/bg.png') no-repeat center; */
}
.main-shadow {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  transform: translate(-50%, -40%);
  background: var(--background);
  box-shadow: 0px 0px 100px 80px rgba(178, 255, 107, 0.3);
  z-index: -1;
  border-radius: 100%;
}

.click-amount {
  top: 0;
  left: 0;
  position: fixed;
  font-size: 46px;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  color: var(--white);
}
@media (min-width: 390px) {
  .main-shadow {
    box-shadow: 0px 0px 100px 110px rgba(178, 255, 107, 0.3);
  }
}
</style>

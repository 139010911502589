<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  size?: number
}
const props = withDefaults(defineProps<Props>(), { size: 58 })

const styles = computed(() => ({
  width: `${props.size}px`,
  padding: `${props.size * 0.15}px`
}))
</script>

<template>
  <div class="loader" :style="styles"></div>
</template>

<style scoped>
.loader {
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--app-primary);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>

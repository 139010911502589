<script setup lang="ts">
import { useLuckyTradeStore } from '@/stores/luckyTradeStore'
import { useUserBalanceStore } from '@/stores/userBalance'
import { amountFormat, fixedDigitsAfterPoint } from '@/utils/amountFormat'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import BalanceWidget from '../common/BalanceWidget.vue'
import Container from '../common/Container.vue'
import DialogModal from '../common/DialogModal.vue'
import EventTimer from '../common/EventTimer.vue'
import ResultsWidget from '../common/ResultsWidget.vue'
import IconBack from '../icons/IconBack.vue'
import IconDecrease from '../icons/IconDecrease.vue'
import IconIncrease from '../icons/IconIncrease.vue'
import PassiveIncomeSection from '../PassiveIncomeSection.vue'

interface Bet {
  id: number
  value: number
}
type BetVariant = 'up' | 'down'
const ROUND_SECONDS: number = 5

const { t } = useI18n()
const userBalanceStore = useUserBalanceStore()
const { balance } = storeToRefs(userBalanceStore)

const luckyTradeStore = useLuckyTradeStore()
const { bid, closeBet } = luckyTradeStore

const bets = shallowRef<Bet[]>([
  { id: 1, value: 5000 },
  { id: 2, value: 10000 },
  { id: 3, value: 25000 },
  { id: 4, value: 50000 }
])

type AggTrade = {
  /**
   * Inner agg trade id
   */
  id: string
  /**
   * Price
   */
  p: number
}
type UserBet = App.DTO.SingleHandTournament.SingleHandTournamentUserBidData
type CloseBetData = App.DTO.SingleHandTournament.SingleHandTournamentCloseBetData

const aggTradeInfo = ref<AggTrade | null>(null)
const btcPrice = ref(0)
const endDate = ref<Date | undefined>(undefined)
const selectedBet = ref(bets.value[0])

const startBid = ref(0)
const endBid = ref(0)

const waitingResultsBet = ref<BetVariant | null>(null)
const showResults = ref(false)
const openPassiveIncomeModal = ref(false)
const unmounted = ref(false)
const win = ref(false)

const updateTicker = (aggTrade: AggTrade) => {
  aggTradeInfo.value = aggTrade
  btcPrice.value = +aggTrade.p
}

const notEnoughCoins = computed(() => balance.value < bets.value[0].value)

function subscribeToUpdates() {
  window.Echo.channel('btcusdt@aggTrade').listen(
    'BinanceAggTradeUpdateReceived',
    (event: { data: AggTrade }) => {
      //   console.log('BinanceAggTradeUpdateReceived', event.data)

      updateTicker({
        id: event.data.id,
        p: +event.data.p
      })
    }
  )
}

function unsubscribeFromUpdates() {
  window.Echo.leaveChannel('btcusdt@aggTrade')
}

onMounted(() => {
  subscribeToUpdates()
})
onBeforeUnmount(() => {
  unmounted.value = true
  unsubscribeFromUpdates()
})

const selectBet = (bet: Bet) => {
  if (bet.value > balance.value || waitingResultsBet.value) return
  selectedBet.value = bet
}

const waitTimeout = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(unmounted.value ? false : true)
    }, ROUND_SECONDS * 1000)
  })
}

const setNewBetValue = () => {
  const nearestBet = [...bets.value].reverse().find((el) => el.value < balance.value)
  if (nearestBet) {
    selectedBet.value = { ...nearestBet }
  }
}

const makeBet = async (variant: BetVariant) => {
  if (waitingResultsBet.value || (selectedBet.value && balance.value < selectedBet.value.value))
    return

  const dateNow = new Date(Date.now())

  const secondsNow = dateNow.getSeconds()
  const datePlusRoundSeconds = dateNow.setSeconds(secondsNow + ROUND_SECONDS + 1)

  endDate.value = new Date(datePlusRoundSeconds)

  waitingResultsBet.value = variant
  startBid.value = btcPrice.value

  if (!aggTradeInfo.value) {
    resetValues()
    return
  }

  const userBet: UserBet = {
    id: aggTradeInfo.value.id,
    userBidAmount: selectedBet.value.value,
    priceMovementDirectionBid: variant
  }

  try {
    bid(userBet)
  } catch (e) {
    resetValues()
    waitingResultsBet.value = null
  }

  const res = await waitTimeout()

  if (!res) {
    resetValues()
    waitingResultsBet.value = null
    return
  }

  const closeBetData: CloseBetData = {
    id: aggTradeInfo.value.id
  }

  try {
    const betResult = await closeBet(closeBetData)

    win.value = betResult.is_winner
    endBid.value = Number(betResult.end_agg_trade_price)

    waitingResultsBet.value = null
    showResults.value = true

    if (balance.value < selectedBet.value.value) setNewBetValue()
  } catch (e) {
    resetValues()
    waitingResultsBet.value = null
  }
}
const resetValues = () => {
  startBid.value = 0
  endBid.value = 0
}
const closeResultsWidget = () => {
  resetValues()
  showResults.value = false
}
</script>

<template>
  <Container class="game">
    <div class="relative mt-10">
      <div v-if="!waitingResultsBet" class="mt-5">
        <div class="available-coins gradient-text">{{ t('luckyTrade.availablePoints') }}</div>
        <BalanceWidget class="mt-3" :balance="balance" />
        <div class="need-more mt-1" @click="openPassiveIncomeModal = true">
          {{ t('luckyTrade.needMoreCoins') }}
        </div>
      </div>
      <div v-else class="timer-container mt-11 mb-4">
        <EventTimer
          :end-date="endDate"
          :size="60"
          hide-days
          divider-symbol=":"
          hide-letters
          hide-hours
        />
      </div>

      <IconBack
        class="back-icon text-light-green"
        style="width: 20px; height: 20px; top: 20%"
        @click="$emit('close')"
      />
    </div>

    <div class="mt-7 flex justify-center">
      <div class="price-circle" :class="{ spin: waitingResultsBet }">
        <img src="/img/bids/chip.webp" class="ofi" />
        <div class="price-inner flex flex-col items-center justify-center">
          <div class="ticker-name">{{ t('luckyTrade.btcPrice') }}</div>
          <div class="ticker-value mt-2 mb-2">
            ${{ fixedDigitsAfterPoint(aggTradeInfo?.p || 0) }}
          </div>
          <!-- <div class="ticker-text" v-html="t('luckyTrade.priceFrom')"></div> -->
          <div class="ticker-text">
            {{ startBid ? `$${fixedDigitsAfterPoint(startBid)}` : ' ' }}
          </div>
        </div>
      </div>
    </div>

    <div class="bet-variants">
      <div
        v-for="bet in bets"
        :key="bet.id"
        class="bet-variants-item"
        :class="{ disabled: balance < bet.value }"
        @click="selectBet(bet)"
      >
        <img :src="selectedBet.id == bet.id ? `/img/bids/bid_active.webp` : `/img/bids/bid.webp`" />
        <div class="flex items-center justify-center w-100 bet-variants-value">
          {{ amountFormat(bet.value) }}
        </div>
      </div>
    </div>

    <div class="bet-actions">
      <div class="info-text">
        {{ t('luckyTrade.guessPrice') }}
      </div>

      <div
        class="mt-5 flex justify-center gap-4 items-center btn"
        :class="{ blurred: notEnoughCoins }"
      >
        <button class="bet-btn ofi-btn" @click="makeBet('down')">
          <!-- :class="{ highlighted: waitingResultsBet == 'down' }" -->
          <img
            :src="
              waitingResultsBet == 'down'
                ? `/img/bids/doom_active_v2_lt.webp`
                : `/img/bids/doom_v2_lt.webp`
            "
            class="ofi"
          />
          <div class="bet-btn-text" :class="{ active: waitingResultsBet == 'down' }">
            {{ t('luckyTrade.down') }}
            <IconDecrease style="width: 24px; height: 24px" />
          </div>
        </button>

        <button class="bet-btn ofi-btn" @click="makeBet('up')">
          <!-- :class="{ highlighted: waitingResultsBet == 'up' }" -->
          <img
            :src="
              waitingResultsBet == 'up'
                ? `/img/bids/moon_active_v2_lt.webp`
                : `/img/bids/moon_v2_lt.webp`
            "
            class="ofi"
          />
          <div class="bet-btn-text" :class="{ active: waitingResultsBet == 'up' }">
            {{ t('luckyTrade.up') }}
            <IconIncrease style="width: 24px; height: 24px" />
          </div>
        </button>
      </div>
    </div>

    <DialogModal
      v-if="openPassiveIncomeModal"
      @close="openPassiveIncomeModal = false"
      :title="t('passiveIncome.passiveEarn')"
      :description="t('passiveIncome.passiveEarnDescription')"
    >
      <div class="mt-3 mb-10">
        <PassiveIncomeSection @close="openPassiveIncomeModal = false" />
      </div>
    </DialogModal>

    <ResultsWidget
      v-if="showResults"
      @click="closeResultsWidget"
      :win="win"
      :start-bid="startBid"
      :end-bid="endBid"
    />
  </Container>
</template>

<style scoped>
.game {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.available-coins {
  text-align: center;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.09px;
}
.need-more {
  color: var(--white, #f3ffe9);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.price-circle {
  width: 260px;
  height: 260px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}
.price-circle.spin img {
  animation: spin 2s linear infinite;
}
.price-circle svg {
  width: 100%;
  height: 100%;
}
.price-inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.ticker-name {
  text-transform: capitalize;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticker-value {
  color: var(--white, #f3ffe9);
  font-family: Courier, monospace;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.ticker-text {
  color: #fff;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 40%;
  text-align: center;
  height: 14.5px;
}
.bet-variants {
  margin-top: 20px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 60px);
  gap: 10px;
}
.bet-variants-item {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bet-variants-item img {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.bet-variants-item.disabled {
  opacity: 0.4;
}
.bet-variants-value {
  color: var(--main, #b2ff6b);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.info-text {
  color: var(--white, #f3ffe9);
  text-align: center;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bet-btn {
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 200px;
  transition: all 0.3s linear;
}
.bet-btn-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--white);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 18px 16px;
  text-transform: uppercase;
}
.bet-btn-text.active {
  transform: translateY(-52%);
}
.betBtn.increase {
  border-radius: 12px;
  /* background: linear-gradient(123deg, #d1ffa6 6.07%, #71be2b 82.15%); */

  /* box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12); */
}
.betBtn.decrease {
  border-radius: 12px;
  /* background: linear-gradient(123deg, #ffc1c3 6.07%, #fe5258 82.15%); */

  /* box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12); */
}
.btn.blurred {
  opacity: 0.3;
}
/* .betBtn.highlighted {
  box-shadow: 0 0px 18px 10px var(--primary);
} */

.bet-actions {
  margin-top: 20px;
  margin-bottom: 30px;
}
@media (min-width: 390px) {
  .bet-variants {
    margin-top: 40px;
  }
  .bet-actions {
    margin-top: 40px;
    margin-bottom: 32px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>

import { registerUserBet, closeUserBet } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { useUserBalanceStore } from './userBalance'

export const useLuckyTradeStore = defineStore('luckyTrade', () => {
  const userBalanceStore = useUserBalanceStore()

  async function bid(userBet: App.DTO.SingleHandTournament.SingleHandTournamentUserBidData) {
    await registerUserBet(userBet)
  }

  async function closeBet(
    closeData: App.DTO.SingleHandTournament.SingleHandTournamentCloseBetData
  ) {
    const result = await closeUserBet(closeData)
    userBalanceStore.getBalance()
    return result
  }

  return {
    bid,
    closeBet
  }
})

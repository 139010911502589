<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
    width="24"
    height="24"
  >
    <path
      opacity="0.4"
      d="M5.7401 16C5.8501 15.51 5.6501 14.81 5.3001 14.46L2.8701 12.03C2.1101 11.27 1.8101 10.46 2.0301 9.76C2.2601 9.06 2.9701 8.58 4.0301 8.4L7.1501 7.88C7.6001 7.8 8.1501 7.4 8.3601 6.99L10.0801 3.54C10.5801 2.55 11.2601 2 12.0001 2C12.7401 2 13.4201 2.55 13.9201 3.54L15.6401 6.99C15.7701 7.25 16.0401 7.5 16.3301 7.67L5.5601 18.44C5.4201 18.58 5.1801 18.45 5.2201 18.25L5.7401 16Z"
    />
    <path
      d="M18.7001 14.46C18.3401 14.82 18.1401 15.51 18.2601 16L18.9501 19.01C19.2401 20.26 19.0601 21.2 18.4401 21.65C18.1901 21.83 17.8901 21.92 17.5401 21.92C17.0301 21.92 16.4301 21.73 15.7701 21.34L12.84 19.6C12.38 19.33 11.62 19.33 11.16 19.6L8.23005 21.34C7.12005 21.99 6.17005 22.1 5.56005 21.65C5.33005 21.48 5.16005 21.25 5.05005 20.95L17.21 8.79002C17.67 8.33002 18.3201 8.12002 18.9501 8.23002L19.96 8.40002C21.02 8.58002 21.7301 9.06002 21.9601 9.76002C22.18 10.46 21.8801 11.27 21.1201 12.03L18.7001 14.46Z"
    />
  </svg>
</template>

export const userTaskSequence = [
  {
    id: 1,
    reward: 500,
    is_completed: false,
    sub_task_cycle_id: 1,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Trunks.webp'
  },
  {
    id: 2,
    reward: 1000,
    is_completed: false,
    sub_task_cycle_id: 1,
    image: null
  },
  {
    id: 3,
    reward: 2500,
    is_completed: false,
    sub_task_cycle_id: 1,
    image: null
  },
  {
    id: 4,
    reward: 5000,
    is_completed: false,
    sub_task_cycle_id: 1,
    image: null
  },
  {
    id: 5,
    reward: 10000,
    is_completed: false,
    sub_task_cycle_id: 1,
    image: null
  },
  {
    id: 6,
    reward: 25000,
    is_completed: false,
    sub_task_cycle_id: 1,
    image: null
  },
  {
    id: 7,
    reward: 50000,
    is_completed: false,
    sub_task_cycle_id: 1,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Sleeve.webp'
  },
  {
    id: 8,
    reward: 500,
    is_completed: false,
    sub_task_cycle_id: 2,
    image: null
  },
  {
    id: 9,
    reward: 1000,
    is_completed: false,
    sub_task_cycle_id: 2,
    image: null
  },
  {
    id: 10,
    reward: 2500,
    is_completed: false,
    sub_task_cycle_id: 2,
    image: null
  },
  {
    id: 11,
    reward: 5000,
    is_completed: false,
    sub_task_cycle_id: 2,
    image: null
  },
  {
    id: 12,
    reward: 10000,
    is_completed: false,
    sub_task_cycle_id: 2,
    image: null
  },
  {
    id: 13,
    reward: 25000,
    is_completed: false,
    sub_task_cycle_id: 2,
    image: null
  },
  {
    id: 14,
    reward: 50000,
    is_completed: false,
    sub_task_cycle_id: 2,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Shirts.webp'
  },
  {
    id: 15,
    reward: 500,
    is_completed: false,
    sub_task_cycle_id: 3,
    image: null
  },
  {
    id: 16,
    reward: 1000,
    is_completed: false,
    sub_task_cycle_id: 3,
    image: null
  },
  {
    id: 17,
    reward: 2500,
    is_completed: false,
    sub_task_cycle_id: 3,
    image: null
  },
  {
    id: 18,
    reward: 5000,
    is_completed: false,
    sub_task_cycle_id: 3,
    image: null
  },
  {
    id: 19,
    reward: 10000,
    is_completed: false,
    sub_task_cycle_id: 3,
    image: null
  },
  {
    id: 20,
    reward: 25000,
    is_completed: false,
    sub_task_cycle_id: 3,
    image: null
  },
  {
    id: 21,
    reward: 50000,
    is_completed: false,
    sub_task_cycle_id: 3,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/T-shirt.webp'
  },
  {
    id: 22,
    reward: 500,
    is_completed: false,
    sub_task_cycle_id: 4,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Paper%20bug.webp'
  },
  {
    id: 23,
    reward: 1000,
    is_completed: false,
    sub_task_cycle_id: 4,
    image: null
  },
  {
    id: 24,
    reward: 2500,
    is_completed: false,
    sub_task_cycle_id: 4,
    image: null
  },
  {
    id: 25,
    reward: 5000,
    is_completed: false,
    sub_task_cycle_id: 4,
    image: null
  },
  {
    id: 26,
    reward: 10000,
    is_completed: false,
    sub_task_cycle_id: 4,
    image: null
  },
  {
    id: 27,
    reward: 25000,
    is_completed: false,
    sub_task_cycle_id: 4,
    image: null
  },
  {
    id: 28,
    reward: 50000,
    is_completed: false,
    sub_task_cycle_id: 4,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Pants.webp'
  },
  {
    id: 29,
    reward: 500,
    is_completed: false,
    sub_task_cycle_id: 5,
    image: null
  },
  {
    id: 30,
    reward: 1000,
    is_completed: false,
    sub_task_cycle_id: 5,
    image: null
  },
  {
    id: 31,
    reward: 2500,
    is_completed: false,
    sub_task_cycle_id: 5,
    image: null
  },
  {
    id: 32,
    reward: 5000,
    is_completed: false,
    sub_task_cycle_id: 5,
    image: null
  },
  {
    id: 33,
    reward: 10000,
    is_completed: false,
    sub_task_cycle_id: 5,
    image: null
  },
  {
    id: 34,
    reward: 25000,
    is_completed: false,
    sub_task_cycle_id: 5,
    image: null
  },
  {
    id: 35,
    reward: 50000,
    is_completed: false,
    sub_task_cycle_id: 5,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Jacket.webp'
  },
  {
    id: 36,
    reward: 500,
    is_completed: false,
    sub_task_cycle_id: 6,
    image: null
  },
  {
    id: 37,
    reward: 1000,
    is_completed: false,
    sub_task_cycle_id: 6,
    image: null
  },
  {
    id: 38,
    reward: 2500,
    is_completed: false,
    sub_task_cycle_id: 6,
    image: null
  },
  {
    id: 39,
    reward: 5000,
    is_completed: false,
    sub_task_cycle_id: 6,
    image: null
  },
  {
    id: 40,
    reward: 10000,
    is_completed: false,
    sub_task_cycle_id: 6,
    image: null
  },
  {
    id: 41,
    reward: 25000,
    is_completed: false,
    sub_task_cycle_id: 6,
    image: null
  },
  {
    id: 42,
    reward: 50000,
    is_completed: false,
    sub_task_cycle_id: 6,
    image:
      'https://stage-supa-dupa-gdf-tma.fra1.digitaloceanspaces.com/bull_avatars/attributes/centered/Chain.webp'
  }
]

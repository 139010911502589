import type { TonConnectUI } from '@tonconnect/ui'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTonConnect = defineStore('tonConnect', () => {
  const tonConnectUI = ref<TonConnectUI | null>(null)

  return {
    tonConnectUI
  }
})

<script setup lang="ts">
import { useUserEnergyStore, useUserTapPowerStore } from '@/stores/userAbilities'
import { useUserBalanceStore } from '@/stores/userBalance'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ListItem from '../components/common/ListItem.vue'
import ActionBtn from './common/ActionBtn.vue'
import BalanceWidget from './common/BalanceWidget.vue'
import BtnPrimary from './common/BtnPrimary.vue'
import Container from './common/Container.vue'
import DialogModal from './common/DialogModal.vue'
import ExpansionPanel from './common/ExpansionPanel.vue'
import HowItWorks from './HowItWorks.vue'
import IconBoltRounded from './icons/IconBoltRounded.vue'
import IconBoltSolidRounded from './icons/IconBoltSolidRounded.vue'

type BoostVariant = 'limit' | 'power'

const { t } = useI18n()

const confirmationModal = ref<BoostVariant | null>(null)
const loading = ref(false)

const userBalanceStore = useUserBalanceStore()
const { getBalance } = userBalanceStore
const { balance } = storeToRefs(userBalanceStore)

const userEnergyStore = useUserEnergyStore()
const { getAvailableEnergyBoost, upgradeEnergyBoost } = userEnergyStore
const { energyBoost, currentEnergyLimit } = storeToRefs(userEnergyStore)

const energyBoostAvailable = computed(() => {
  return energyBoost.value && balance.value >= energyBoost.value.price
})
const confirmationModalTitle = computed(() => {
  if (confirmationModal.value == 'power') return t('common.tapPower')
  return t('common.energyLimit')
})

const { getAvailableTapPowerBoost, upgradeTapPowerBoost } = useUserTapPowerStore()
const { tapPowerBoost, currentTapPower } = storeToRefs(useUserTapPowerStore())

const tapPowerBoostAvailable = computed(() => {
  return tapPowerBoost.value && balance.value >= tapPowerBoost.value.price
})

onMounted(async () => {
  try {
    await Promise.all([getAvailableEnergyBoost(), getAvailableTapPowerBoost(), getBalance()])
  } catch (error) {
    //
  }
})

const upgradeEnergy = async () => {
  if (!energyBoostAvailable.value) return

  await upgradeEnergyBoost()
  await getBalance()
}
const upgradeTapPower = async () => {
  if (!tapPowerBoostAvailable.value) return

  await upgradeTapPowerBoost()
  await getBalance()
}

const openConfirmation = (variant: BoostVariant) => {
  confirmationModal.value = variant
}
const boost = async () => {
  if (loading.value) return
  try {
    loading.value = true
    if (confirmationModal.value == 'limit') await upgradeEnergy()
    if (confirmationModal.value == 'power') await upgradeTapPower()
  } catch (error) {
    //
  } finally {
    loading.value = false
    confirmationModal.value = null
  }
}
</script>

<template>
  <Container>
    <BalanceWidget :balance="balance" class="mt-15" />

    <div class="list-container mt-15">
      <ListItem
        :title="
          energyBoost
            ? t('common.energyLimit') + ': ' + `${energyBoost.energy_limit}`
            : t('boostTab.maxEnergyLevelCaptured')
        "
        :price="energyBoost?.price || ''"
        :disabled="!energyBoostAvailable"
        @click.prevent="openConfirmation('limit')"
      >
        <template #prepend>
          <IconBoltRounded style="width: 48px; height: 48px; max-width: 48px" />
        </template>
        <template #append>
          <BtnPrimary :paddingX="32" class="boost-btn">{{ t('boostTab.boost') }}</BtnPrimary>
        </template>
      </ListItem>

      <ListItem
        :title="
          tapPowerBoost
            ? t('common.tapPower') + ': ' + `${tapPowerBoost.tap_points}`
            : t('boostTab.maxTapPowerReached')
        "
        :price="tapPowerBoost?.price || ''"
        :disabled="!tapPowerBoostAvailable"
        @click.prevent="openConfirmation('power')"
      >
        <template #prepend>
          <IconBoltSolidRounded style="width: 48px; height: 48px; max-width: 48px" />
        </template>
        <template #append>
          <BtnPrimary :paddingX="32" class="boost-btn">{{ t('boostTab.boost') }}</BtnPrimary>
        </template>
      </ListItem>
    </div>

    <DialogModal
      v-if="confirmationModal"
      @close="confirmationModal = null"
      :title="confirmationModalTitle"
    >
      <div class="mt-4 mb-4">
        <template v-if="confirmationModal == 'power'">
          <div class="text-bold">
            {{ currentTapPower }} -> {{ tapPowerBoost?.tap_points || '' }}
          </div>

          <!-- <div class="">{{ `${t('common.cost')}: ${tapPowerBoost?.price} ` }}</div> -->
        </template>

        <template v-if="confirmationModal == 'limit'">
          <div class="text-bold">
            {{ currentEnergyLimit }} -> {{ energyBoost?.energy_limit || '' }}
          </div>
        </template>
      </div>

      <ActionBtn class="mt-4" :text="t('boostTab.boost')" @click="boost" :loading="loading">
      </ActionBtn>
    </DialogModal>

    <ExpansionPanel class="mt-8" more-btn-text="common.howItWorks">
      <HowItWorks />
    </ExpansionPanel>
  </Container>
</template>

<style scoped>
.balance {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.balance-icon {
  width: 40px;
  height: 40px;
}
.boost-btn {
  display: none;
}
@media (min-width: 390px) {
  .boost-btn {
    display: flex;
  }
}
</style>

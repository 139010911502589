<script setup lang="ts">
const { radius } = withDefaults(defineProps<{ radius?: number; gradientReverse?: boolean }>(), {
  radius: 12,
  gradientReverse: false
})
</script>

<template>
  <div class="card-box" :style="{ 'border-radius': `${radius}px` }">
    <div class="card-box-bg">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.card-box {
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box; /* !importanté */
  border: solid 1px transparent;
}
.card-box:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(to right, #314222, var(--primary));
}
.card-box-bg {
  background: #314222;
  border-radius: inherit;
}
</style>

<script setup lang="ts">
import { useGameEventsStore } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionBtn from '../common/ActionBtn.vue'
import BtnText from '../common/BtnText.vue'
import EventTimer from '../common/EventTimer.vue'
import IconMore from '../icons/IconMore.vue'
import PrizeTable from './PrizeTable.vue'

const { t } = useI18n()

const userEventStore = useGameEventsStore()
const { checkUserRegistration, registerForPhase } = userEventStore
const { isUserRegisteredForEvent, registrationPhase } = storeToRefs(userEventStore)

const eventId = 3
const phaseId = 7

const isShowHowToParticipate = ref(false)
const toggleHowToParticipateModal = () => {
  isShowHowToParticipate.value = !isShowHowToParticipate.value
}

const hasTimeLeft = computed(() => {
  if (!registrationPhase.value?.ended_at) return false

  return new Date(registrationPhase.value?.ended_at) > new Date()
})

const registrationNotAllowed = computed(() => !isUserRegisteredForEvent.value && !hasTimeLeft.value)
const registerAfterEventStarted = computed(
  () => !hasTimeLeft.value && registrationPhase.value?.can_register_after_registration_time_passed
)

const registration = async () => {
  await registerForPhase(phaseId)
}
const registrationFinished = () => {
  console.log('registrationFinished: ')
}
onMounted(async () => {
  await checkUserRegistration(eventId, phaseId)
})
</script>

<template>
  <div class="">
    <div style="max-width: 75%" class="ma-auto">
      <h2 class="page-title mt-12" v-html="t('eventTab.title')"></h2>

      <h3 class="page-sub-title mt-5">
        {{ t('eventTab.description') }}
      </h3>
    </div>

    <!-- Timer → Event starts in -->
    <div v-if="!registrationNotAllowed" class="mt-8 timer-widget">
      <div>{{ t('eventTab.register.eventStarts') }}</div>
      <EventTimer
        :endDate="registrationPhase?.ended_at"
        :size="30"
        @finished="registrationFinished"
      />
    </div>

    <ActionBtn
      v-if="!isUserRegisteredForEvent && hasTimeLeft"
      class="mt-8"
      :text="t('eventTab.register.registerButton')"
      @click="registration"
    >
    </ActionBtn>
    <ActionBtn
      v-if="isUserRegisteredForEvent"
      class="mt-8"
      :text="t('eventTab.register.youAreRegistered')"
    >
    </ActionBtn>

    <div v-else-if="registerAfterEventStarted" class="mt-8 page-mid-title">
      {{ t('eventTab.register.notRegisteredButStillCanJoin') }}
    </div>

    <div v-else-if="registrationNotAllowed" class="mt-8 page-mid-title">
      {{ t('eventTab.register.notRegistered') }}
    </div>

    <PrizeTable />

    <div class="mt-8 flex justify-center">
      <BtnText @click="toggleHowToParticipateModal">
        🔓 {{ t('eventTab.howToParticipate') }}
        <template #appendIcon>
          <IconMore
            style="width: 14px; height: 14px"
            :class="{ rotate90: isShowHowToParticipate }"
          />
        </template>
      </BtnText>
    </div>

    <div class="mt-4" v-if="isShowHowToParticipate">
      <ol class="how-to-participate">
        <li>{{ t('eventTab.participateCondition.first') }}</li>
        <li>
          {{ t('eventTab.participateCondition.second.title') }}
          <ul>
            <li>{{ t('eventTab.participateCondition.second.sub1') }}</li>
            <li>{{ t('eventTab.participateCondition.second.sub2') }}</li>
          </ul>
        </li>
      </ol>
    </div>

    <div v-if="isShowHowToParticipate" class="my-4 flex justify-center">
      <BtnText @click="toggleHowToParticipateModal">
        {{ t('common.hide') }}
        <template #appendIcon>
          <IconMore style="width: 14px; height: 14px; transform: rotate(90deg)" />
        </template>
      </BtnText>
    </div>

    <ActionBtn
      v-if="registerAfterEventStarted"
      class="mt-8"
      :text="t('eventTab.register.registerButton')"
      @click="registration"
    >
    </ActionBtn>
  </div>
</template>

<style scoped>
.timer-widget {
  text-align: center;
  font-size: 24px;
}

.how-to-participate {
  padding-left: 30px;
  counter-reset: list;
}

.how-to-participate > li {
  list-style: none;
}

.how-to-participate > li:before {
  content: counter(list) ') ';
  counter-increment: list;
}

.how-to-participate > ol > ul {
  list-style-type: none;
}

.how-to-participate > ol > ul > li:before {
  content: '- ';
}
</style>

export const comboBulls = [
  { id: 1, url: '/img/combo/16.webp', color: 'rgba(180, 255, 114)' },
  { id: 2, url: '/img/combo/8.webp', color: 'rgba(253, 255, 107)' },
  { id: 3, url: '/img/combo/3.webp', color: 'rgba(254, 82, 88)' },
  { id: 4, url: '/img/combo/4.webp', color: 'rgba(180, 255, 114)' },
  { id: 5, url: '/img/combo/5.webp', color: 'rgba(243, 255, 233)' },
  { id: 6, url: '/img/combo/6.webp', color: 'rgba(47, 54, 40)' },
  { id: 7, url: '/img/combo/7.webp', color: 'rgba(113, 190, 43)' },
  { id: 8, url: '/img/combo/2.webp', color: 'rgba(253, 255, 107)' },
  { id: 9, url: '/img/combo/10.webp', color: 'rgba(180, 255, 114)' },
  { id: 10, url: '/img/combo/9.webp', color: 'rgba(254, 82, 88)' },
  { id: 11, url: '/img/combo/11.webp', color: 'rgba(243, 255, 233)' },
  { id: 12, url: '/img/combo/12.webp', color: 'rgba(47, 54, 40)' },
  { id: 13, url: '/img/combo/13.webp', color: 'rgba(113, 190, 43)' },
  { id: 14, url: '/img/combo/15.webp', color: 'rgba(254, 82, 88)' },
  { id: 15, url: '/img/combo/14.webp', color: 'rgba(253, 255, 107)' },
  { id: 16, url: '/img/combo/1.webp', color: 'rgba(113, 190, 43)' }
]

export const localeMessages = {
  en: {
    common: {
      hr: 'hr',
      boosts: 'Boosts',
      energyLimit: 'Energy limit',
      tapPower: 'Tap power',
      howItWorks: 'How it works',
      comingSoon: 'Coming soon',
      claimReward: 'Claim Reward',
      hide: 'Hide',
      level: 'Level',
      premium: 'Premium',
      serverError: 'Server Error',
      success: 'Success',
      less: 'Less',
      more: 'More',
      back: 'Back',
      viewMore: 'View More',
      check: 'Check',
      join: 'Join',
      notEnoughCoins: 'Not enough coins',
      games: 'Games',
      tournaments: 'Tournaments',
      d: 'd',
      h: 'h',
      m: 'm',
      s: 's',
      above: 'above',
      below: 'below',
      cost: 'Cost',
      loading: 'Loading'
    },
    welcomeSlides: {
      welcome: {
        title: 'Welcome to GDF Trade',
        subTitle:
          'Start your adventure with GDF: tap, collect coins, climb the leagues, and earn more tokens in the airdrop!'
      },
      inviteFriends: 'Invite friends and earn rewards to level up even faster!',
      claimRealTon: 'Earn a 10% cashback in TON from your referrals.',
      participateInRaffle: 'Join the raffle for a chance to win top prizes.',
      next: 'Next',
      letsGo: 'LET’S GO'
    },
    tab: {
      home: 'Home',
      boosts: 'Boosts',
      invite: 'Invite',
      earns: 'Tasks',
      claim: 'Claim',
      raffle: 'Raffle',
      event: 'Event',
      tournament: 'Tourney'
    },
    homeTab: {
      info: {
        tournamentStarts: 'Our tournament starts in',
        takePartInEvent: 'Take part in the Event',
        winWithUs: 'Win with us!',
        getInvolved: 'Get involved',
        stayTuned: 'Stay tuned',
        newEventsAreComing: 'New events are coming'
      }
    },
    boostTab: {
      maxEnergyLevelCaptured: 'Max energy level captured',
      maxTapPowerReached: 'Max tap power reached',
      boost: 'Boost',
      howItWorks: {
        description:
          'Earning coins is easy as tap the screen. But you can earn even faster with Boosts!',
        title1: '1. Multitap Boost:',
        text1:
          'This boost allows you to earn multiple coins with each tap. It can be upgraded multiple times (each tap will spend more energy to speed the process up and will give more coins).',
        tableLevelTitle: 'Level/Boost',
        tableTapPowerTitle: 'Tap Power',
        tableEnergyLimitTitle: 'Energy limit',
        tablePriceTitle: 'Price',
        title2: '2. Energy Limit Boost:',
        text2:
          'This boost increases your energy capacity, allowing you to tap more. It refills within 15 min on a linear base in seconds.',
        lvl: ({ named }: any) => `${named('level')} Lvl`
      },
      tapPowerUpdateErr: 'Not enough credits to upgrade Tap Power level',
      tapPowerSuccessUpdate: 'Tap Power upgraded successfully',
      energyLevelUpdateErr: 'Not enough credits to upgrade energy level',
      energyLevelSuccessUpdate: 'Energy level upgraded successfully'
    },
    earnTab: {
      earn: 'Earn',
      taskList: 'Task List',
      completeTasksDaily: 'Complete tasks daily to get more rewards',

      tasks: {
        joinGdFundTelegramChannel: {
          title: 'Subscribe to GDF Announcements',
          description:
            'Join our Telegram channel and stay updated with our latest news and updates.',
          buttonTitle: 'Subscribe'
        },
        joinGdFundTelegramChannel2: {
          title: 'Subscribe to GD Fund channel',
          description:
            'Join our Telegram channel and stay updated with our latest news and updates.',
          buttonTitle: 'Join'
        },
        followGdFundOnX: {
          title: 'Follow GD Fund on X',
          description: 'Follow us on X and stay updated with our latest news and updates.',
          buttonTitle: 'Follow'
        },
        invite3Friends: {
          title: 'Invite 3 Friends',
          description: 'Invite 3 friends to join GD Fund and get 25000 points.',
          buttonTitle: 'Claim Reward'
        },
        dailyLogin: {
          title: 'Daily Login',
          description: 'Login daily to GD Fund and get rewarded.',
          buttonTitle: 'Claim reward'
        },
        connectWallet: {
          title: 'Connect Wallet',
          description: 'Connect your wallet to GD Fund and get rewarded.',
          connectButtonTitle: 'Connect Wallet',
          disconnectButtonTitle: 'Disconnect Wallet',
          messages: {
            success: 'Wallet connected successfully',
            error: 'Failed to connect wallet',
            disconnect: 'Wallet disconnected successfully'
          }
        },
        joinToTonNewsChain: {
          title: 'Join TON News Chain',
          description: 'Join TON News Chain and get rewarded.',
          buttonTitle: 'Join'
        },
        joinDropCryptoZona: {
          title: 'Join Drop CryptoZona',
          description: 'Join Drop CryptoZona and get rewarded.',
          buttonTitle: 'Join'
        },
        joinGreenCrypto: {
          title: 'Join Green Crypto',
          description: 'Join Green Crypto and get rewarded.',
          buttonTitle: 'Join'
        },
        launchCapllyBot: {
          title: 'Launch Caplly Bot',
          description: 'Launch Caplly Bot and get rewarded.',
          buttonTitle: 'Launch'
        },
        joinCapllyCommunity: {
          title: 'Join Caplly Community',
          description: 'Join Caplly Community and get rewarded.',
          buttonTitle: 'Join'
        },
        launchGreenGoldBot: {
          title: 'Launch GreenGold Bot',
          description: 'Launch GreenGold Bot and get rewarded.',
          buttonTitle: 'Launch'
        },
        joinTestTheMillion: {
          title: 'Join Test The Million',
          description: 'Join Test The Million and get rewarded.',
          buttonTitle: 'Join'
        },
        subscribeToDailyComboNotifications: {
          title: 'Join Daily Combo channel',
          description: 'Join Daily Combo channel and get rewarded.',
          buttonTitle: 'Join'
        },
        launchPiggyBankGame: {
          title: 'Score $BALLS in PiggyBank!',
          description: 'Launch Piggy Bank Game and get rewarded.',
          buttonTitle: 'Launch'
        }
      },
      notifications: {
        taskCompletedSuccessfully: 'Task completed successfully. You received {reward} coins!',
        taskWasNotFound: 'Task was not found',
        taskAlreadyCompleted: 'Task already completed',
        taskConditionsNotMet: 'The conditions for completing the task have not been met',
        failedToCompleteTask: 'Failed to complete the task'
      },
      daily: {
        title: 'Weeks of Fortune',
        description: 'Accumulate coins and earn rewards for daily logins without skipping a day.',
        cycle: 'Level',
        day: 'day {day}',
        shorts: 'Shorts',
        tShort: 'T-short!',
        pressToClose: 'Press to close'
      }
    },
    inviteTab: {
      tabs: { invite: 'Invite', claim: 'Claim' },
      copyLink: 'Copy link',
      noReferralsYet: 'No referrals yet',
      inviteFriends: 'Invite friends',
      moreReferrals: 'More referrals - more benefits',
      inviteYourFriends: 'Invite your friends to play and grow together',
      gifts: {
        inviteAFriend: {
          title: 'Invite a friend',
          subtitle: 'for you and your friend',
          invite: 'Invite'
        },
        inviteAFriendWithTG: {
          title: 'Invite a friend with TG Premium',
          subtitle: 'for you and your friend'
        }
      },
      moreBonuses: 'More bonuses',
      bonusForLevellingUp: 'Bonus for levelling up',
      forFriend: 'For Friend',
      listOfYourFriends: 'List of your friends',
      inviteAFriendButton: 'Invite a friend'
    },
    passiveIncome: {
      passiveEarn: 'Passive Earn',
      passiveEarnDescription:
        'Works 24/7 without stops. Coin balance and passive income affect airdrop rating find the perfect balance!',
      forCoins: 'For Coins',
      forStars: 'For Stars',
      forVideo: 'For Videos',
      specialOffer: 'Special offer for you!',
      specialOffer1: 'BFCM SALES! Offer ends in:',
      boughtSuccessfully: 'Passive income bought successfully',
      earnedWhileOffline: 'Offline Earnings',
      passiveIncomePercentToAverageIncomePerUser: `You’re <span {classes}>{percent}% {aboveOrBelow}</span> average`,
      passiveIncomeIsZero: 'Your rank is below all users in the app.',
      upgrade: 'Get more'
    },
    claimTab: {
      claim: 'Claim',
      getCashback: "Get 10% cashback from your referrals' purchases",
      youEarned: 'You earned',
      noEarningsYet: 'No earnings yet',
      inviteAFriend: 'Invite a friend',
      claimed: 'Claimed',
      usersList: 'TOP-10 Referrals',
      usersFullList: 'Referrals',
      noPurchases: 'No purchases yet',
      tableHead: {
        date: 'Date',
        amount: 'Amount',
        hash: 'Hash'
      }
    },
    userList: {
      title: 'List of your friends',
      noFriendsYet: 'No friends yet',
      noUsersYet: 'No results yet'
    },
    eventTab: {
      event: 'Event',
      title: 'Collect coins for a chance to split $2,000 worth of prizes',
      description:
        '💰 Collect as many coins as possible: earn with faucets, passive income, invite friends and help them advance in leagues. The more coins, the higher the chances of winning! 🏆',
      howToParticipate: 'How to participate?',
      prizeTable: {
        header: '🎁 Prize pool',
        firstRow: '🥇 1st place – 1000 USD',
        secondRow: '🥈 2nd place – 150 USD',
        thirdRow: '🥉 3rd place – 100 USD',
        fourthRow: '4th-8th places – 50 USD',
        fifthRow: '9th-10th places – 50 USD in passive income',
        sixthRow: '11th-15th places – 40 USD in passive income',
        seventhRow: '16th-20th places – 20 USD in passive income',
        eighthRow: '21th-30th places - 10 USD in passive income'
      },
      participateCondition: {
        first: 'Connect your TON wallet in the "Tasks" section.',
        second: {
          title: 'Fulfill one of the following conditions:',
          sub1: 'Invite 5 friends during registration',
          sub2: 'Purchase passive income worth 750 stars or more'
        }
      },
      register: {
        eventStarts: 'Event starts in',
        registerButton: 'Register',
        youAreRegistered: 'You are registered for the event',
        notRegistered:
          'Sorry, you’re not registered for this event. Please wait for another one to join',
        notRegisteredButStillCanJoin:
          'Sorry you missed the registration phase, but you can still join if the following criteria is met'
      },
      tapPhase: {
        title:
          'Collect coins to get a chance to share rewards in <span class="text-yellow text-bold">150 USD</span>',
        subTitle:
          'Earn as much coins as you can by tapping, inviting friends, leveling up or getting passive income. The Top-5 will share the valuable prizes',
        amountOfCoinsEarned: 'Coins collected:',
        eventEndsIn: 'Event Ends in',
        leaderBoard: 'Leaderboard'
      },
      resultPhase: {
        title:
          'Collect coins to get a chance to share rewards in <span class="text-yellow text-bold">150 USD</span>',
        eventIsOver: 'This event is over. Wait for the next one to compete'
      },
      notifications: {
        noPhaseFound: 'No phase found for the event.',
        notEnoughReferralsOrPurchasePassiveIncome:
          'Registration failed. You need to have at least 5 referrals registered or purchase passive income worth 750 stars or more during the event phase.',
        noConnectedWallets:
          'Registration failed. You need to connect your wallet to participate in the event phase.',
        registrationSuccess: 'You have successfully registered for the event phase.',
        noActiveEvents: 'No active events found.'
      }
    },
    tournamentTab: {
      tournament: 'Tournament',
      register: {
        joinTournament: 'Tournament Title',
        description: 'Tournament description.',
        tournamentStarts: 'Tournament starts in'
      },
      tabs: {
        tournaments: 'Tournaments',
        history: 'History',
        ratings: 'Rating'
      },
      tournaments: {
        title: 'Trading Tournaments',
        description:
          "Participate in our Daily Tournaments and analyze/forecast the price of a specif crypto asset. If your prediction is correct - you'll get ranking update. This rating will further affect the airdrop as a multiplicator to coins balance. <span class='gradient-text'>The higher rating -> the better your chance to get on top of the airdrop!</span>",
        categories: {
          fiveMin: '5 minutes',
          fifteenMin: '15 minutes',
          oneHour: '1 hour',
          oneDay: '1 day',
          fiveMinDescription: 'Predict the price of a specif crypto asset in 5 minutes',
          fifteenMinDescription: 'Predict the price of a specif crypto asset in 15 minutes',
          oneHourDescription: 'Predict the price of a specif crypto asset in 1 hour',
          oneDayDescription: 'Predict the price of a specif crypto asset in 1 day',
          pointsAchievedToday: 'Points achieved today:'
        },
        bet: {
          roundTitle: ({ named }: any) =>
            `Predict the price of <span class="uppercase text-bold">${named('ticker')}</span> in the next ${named('title')}.<br/> ${named('message')}`,
          guessPrice: ({ named }: any) =>
            `Guess the <span class="uppercase text-bold"${named('ticker')}</span> price`,
          attemptsLeft: 'Attempts left for today: {attempts}',
          priceFromBinance: 'Price from binance <span class="uppercase">spot</span>',
          tickerPrice: ({ named }: any) => `${named('ticker').toUpperCase()} Price:`,
          down: 'down',
          up: 'up',
          bid: 'bid',
          value: 'Value',
          successfullyRegisteredForTheRound: {
            up: 'You have successfully registered for the round with prediction: up',
            down: 'You have successfully registered for the round with prediction: down',
            number: 'You have successfully registered for the round'
          }
        },
        registrationStartsIn: 'Registration starts in:',
        registrationEndsIn: 'Registration ends in:',
        tournamentEndsIn: 'Tournament ends in:',
        resultsEndsIn: 'Calculating results:',
        attemptsLeftForToday: 'Attempts left for today:',
        dontHaveTournaments: "Don't have tournaments",
        dontHaveCategories: "Don't have categories"
      },
      history: {
        title: 'History of your tournaments',
        description: '',
        total: 'In total',
        lose: 'Lose',
        winnings: 'Winnings',
        noHistory: "Don't have any records"
      },
      ratings: {
        title: 'Tournament Ratings',
        description: '',
        score: 'Score: {score}',
        winRate: 'Win rate',
        noRatingsAvailable: 'No ratings available'
      }
    },
    ranking: {
      from: 'from',
      yourPosition: 'Your position',
      stoneLeague: 'Stone League',
      bronzeLeague: 'Bronze League',
      silverLeague: 'Silver League',
      goldLeague: 'Gold League',
      platinumLeague: 'Platinum League',
      diamondLeague: 'Diamond League',
      cosmosLeague: 'Cosmos League',
      iceLeague: 'Ice League'
    },
    raffleTab: {
      title: '1000 random participants will share the prize of 10000 TON',
      description: 'The more tickets you have, the higher your chances of winning!',
      raffleEndsIn: 'Raffle ends in:',
      yourTickets: 'Your tickets',
      wantMoreTickets: 'Want more tickets?',
      buyTickets: 'Buy tickets',
      howRaffleWorks: {
        title: 'Collection methods:',
        option1: 'shopping with stars',
        option2: 'one ticket for 5 invited friends',
        option3: 'one ticket to watch 50 promotional videos',
        description:
          'When you make purchases using stars in the lottery or passive income, you will receive a free ticket.'
      },
      youLoose: "Sorry, looks you're not in the list of Winners :(",
      shareResults: 'Share your results in X in order to claim the reward.'
    },
    games: {
      title: 'Games',
      description:
        "Participate in our Daily Tournaments and analyse/forcast the price of a specif crypto asset. If your prediction is correct - you'll get ranking update.",
      quiz: 'Quiz',
      combo: 'Combo',
      prediction: 'Lucky Trade'
    },
    luckyTrade: {
      availablePoints: 'Available coins',
      needMoreCoins: 'Need more coins?',
      guessPrice: 'Guess the BTC price',
      btcPrice: 'BTC Price:',
      priceFrom: 'Price from binance <span class="uppercase">spot</span>',
      down: 'DOOM',
      up: 'MOON',
      results: {
        win: 'WIN!',
        lose: 'LOOSE',
        btcPrice: `BTC Price <span {classes}>{diff}%</span>`,
        from: ({ named }: any) => `From $${named('from')} to $${named('to')}`
      }
    },
    puzzleGame: {
      puzzle: 'Combo',
      userComboFailed: 'Not this time :(',
      userComboSuccess: 'Congrats! You earned 100,000 coins!',
      findCorrectCombination: 'Find the correct combination of 4 icons. You only have one try!',
      check: 'Check',
      notLucky: 'Luck wasn`t on side today',
      nextGameStarts: 'The next game starts in'
    },
    maintenance: {
      text: "We're down for maintenance. Be right back!"
    }
  },
  ru: {
    common: {
      hr: 'ч',
      boosts: 'Бусты',
      energyLimit: 'Лимит энергии',
      tapPower: 'Сила тапа',
      howItWorks: 'Как это работает',
      comingSoon: 'Скоро',
      claimReward: 'Получить награду',
      hide: 'Скрыть',
      level: 'Уровень',
      premium: 'Премиум',
      serverError: 'Ошибка сервера',
      success: 'Успешно',
      less: 'Меньше',
      more: 'Больше',
      back: 'Назад',
      viewMore: 'Показать больше',
      check: 'Проверить',
      join: 'Подпишись',
      notEnoughCoins: 'Недостаточно монет',
      games: 'Игры',
      tournaments: 'Турниры',
      d: 'д',
      h: 'ч',
      m: 'м',
      s: 'с',
      above: 'выше',
      below: 'ниже',
      cost: 'Цена',
      loading: 'Загрузка'
    },
    welcomeSlides: {
      welcome: {
        title: 'Добро пожаловать в GDF Trade',
        subTitle:
          'Начни приключение с GDF: тапай, собирай монеты, поднимайся по лигам и получай больше токенов на аирдроп!'
      },
      inviteFriends: 'Приглашай друзей и получай награды, чтобы прокачиваться еще быстрее!',
      claimRealTon: 'Получай 10% кэшбэк с рефераллов в TON.',
      participateInRaffle: 'Учавствуй в раффле и получи возможность выиграть ТОП призы.',
      next: 'Далее',
      letsGo: 'Поехали'
    },
    tab: {
      home: 'Домой',
      boosts: 'Бусты',
      invite: 'Друзья',
      earns: 'Таски',
      claim: 'Claim',
      raffle: 'Раффл',
      event: 'Турнир',
      tournament: 'Турнир'
    },
    homeTab: {
      info: {
        tournamentStarts: 'Наш турнир начнется через',
        takePartInEvent: 'Присоединяйся к событию',
        winWithUs: 'Побеждай с нами!',
        getInvolved: 'Принять участие',
        stayTuned: 'Оставайся на связи',
        newEventsAreComing: 'Новые ивенты уже скоро'
      }
    },
    boostTab: {
      maxEnergyLevelCaptured: 'Достигнут лимит энергии',
      maxTapPowerReached: 'Достигнут лимит силы тапов',
      boost: 'Прокачать',
      howItWorks: {
        description:
          'Зарабатывать монеты легко, просто нажимая на экран. Но с Бустами вы можете зарабатывать еще быстрее!',
        title1: '1. Многократное усиление:',
        text1:
          'Это усиление позволяет вам зарабатывать несколько монет за каждое нажатие. Его можно улучшать несколько раз (каждое нажатие будет тратить больше энергии для ускорения процесса и давать больше монет).',
        tableLevelTitle: 'Уровень',
        tableTapPowerTitle: 'Сила',
        tableEnergyLimitTitle: 'Энергия',
        tablePriceTitle: 'Цена',
        title2: '2. Повышение лимита энергии:',
        text2:
          'Это усиление увеличивает ваш энергетический потенциал, позволяя вам тапать больше. Он пополняется в течение 15 минут по линейному принципу.',
        lvl: ({ named }: any) => `${named('level')} уровень`
      },
      tapPowerUpdateErr: 'Недостаточно кредитов для повышения уровня силы тапа.',
      tapPowerSuccessUpdate: 'Сила тапа успешно прокачана',
      energyLevelUpdateErr: 'Недостаточно кредитов для повышения лимита энергиию',
      energyLevelSuccessUpdate: 'Лимит энергии успешно прокачан'
    },
    earnTab: {
      earn: 'Задания',
      taskList: 'Список заданий',
      completeTasksDaily: 'Выполняй задания ежедневно, чтобы получить больше наград',
      tasks: {
        joinGdFundTelegramChannel: {
          title: 'Подпишись на наш Telegram канал',
          description:
            'Подпишись на наш Telegram-канал и будьте в курсе наших последних новостей и обновлений.',
          buttonTitle: 'Подпишись'
        },
        joinGdFundTelegramChannel2: {
          title: 'Подпишись на канал GD Fund',
          description:
            'Подпишись на наш Telegram-канал и будьте в курсе наших последних новостей и обновлений.',
          buttonTitle: 'Подпишись'
        },
        followGdFundOnX: {
          title: 'Следи за нами в Х',
          description:
            'Подпишитесь на нас в X и будьте в курсе наших последних новостей и обновлений.',
          buttonTitle: 'Подписаться'
        },
        invite3Friends: {
          title: 'Пригласи 3 друзей',
          description: 'Пригласите 3 друзей присоединиться к GD Fund и получите 25000 очков.',
          buttonTitle: 'Получить награду'
        },
        dailyLogin: {
          title: 'Ежедневный логин',
          description: 'Входите в GD Fund ежедневно и получайте вознаграждение.',
          buttonTitle: 'Получить награду'
        },
        connectWallet: {
          title: 'Подключить кошелек',
          description: 'Подключите свой кошелек к GD Fund и получите вознаграждение.',
          connectButtonTitle: 'Подключить кошелек',
          disconnectButtonTitle: 'Отключить кошелек',
          messages: {
            success: 'Кошелек успешно подключен',
            error: 'Не удалось подключить кошелек',
            disconnect: 'Кошелек успешно отключен'
          }
        },
        joinToTonNewsChain: {
          title: 'Подпишись на TON News Chain',
          description: 'Подпишись на TON News Chain и получите вознаграждение.',
          buttonTitle: 'Подпишись'
        },
        joinDropCryptoZona: {
          title: 'Подпишись на Drop CryptoZona',
          description: 'Подпишись на Drop CryptoZona и получите вознаграждение.',
          buttonTitle: 'Подпишись'
        },
        joinGreenCrypto: {
          title: 'Подпишись на Green Crypto',
          description: 'Подпишись на Green Crypto и получите вознаграждение.',
          buttonTitle: 'Подпишись'
        },
        launchCapllyBot: {
          title: 'Запусти Caplly бot',
          description: 'Запусти Caplly бot и получите вознаграждение.',
          buttonTitle: 'Запустить'
        },
        joinCapllyCommunity: {
          title: 'Подпишись на Caplly Community',
          description: 'Подпишись на Caplly Community и получите вознаграждение.',
          buttonTitle: 'Подпишись'
        },
        launchGreenGoldBot: {
          title: 'Запусти GreenGold бот',
          description: 'Запусти GreenGold бот и получите вознаграждение.',
          buttonTitle: 'Запустить'
        },
        joinTestTheMillion: {
          title: 'Подпишись на Test The Million',
          description: 'Подпишись на Test The Million и получите вознаграждение.',
          buttonTitle: 'Подпишись'
        },
        subscribeToDailyComboNotifications: {
          title: 'Подпишись на DAILY COMBO',
          description: 'Подпишись на DAILY COMBO и получи вознаграждение.',
          buttonTitle: 'Подпишись'
        },
        launchPiggyBankGame: {
          title: 'Заработай $BALLS в PiggyBank!',
          description: 'Запусти Piggy Bank Game и получи вознаграждение.',
          buttonTitle: 'Запустить'
        }
      },
      notifications: {
        taskCompletedSuccessfully: 'Задание успешно выполнено. Вы получили {reward} монет!',
        taskWasNotFound: 'Задание не найдено',
        taskAlreadyCompleted: 'Задание уже выполнено',
        taskConditionsNotMet: 'Не выполнены условия задания',
        failedToCompleteTask: 'Не удалось выполнить задание'
      },
      daily: {
        title: 'Недели Удачи',
        description:
          'Накапливайте монеты и получайте награды за ежедневный вход в игру без пропусков',
        cycle: 'Уровень',
        day: 'день {day}',
        shorts: 'Shorts',
        tShort: 'T-short!',
        pressToClose: 'Нажмите, чтобы закрыть'
      }
    },
    inviteTab: {
      tabs: { invite: 'Пригласить', claim: 'Вывод' },
      copyLink: 'Скопировать ссылку',
      noReferralsYet: 'Пока нет рефералов',
      inviteFriends: 'Пригласить друзей',
      moreReferrals: 'Больше рефералов - больше бонусов',
      inviteYourFriends: 'Пригласи друзей играть и развивайтесь вместе!',
      gifts: {
        inviteAFriend: {
          title: 'Пригласить друга',
          subtitle: 'для тебя и твоего друга',
          invite: 'Пригласить'
        },
        inviteAFriendWithTG: {
          title: 'Пригласи друга с Telegram Premium',
          subtitle: 'для тебя и твоего друга'
        }
      },
      moreBonuses: 'Больше бонусов',
      bonusForLevellingUp: 'Бонус за повышение уровня',
      forFriend: 'Стандарт',
      listOfYourFriends: 'Список друзей',
      inviteAFriendButton: 'Пригласи'
    },
    passiveIncome: {
      passiveEarn: 'Пассивный доход',
      passiveEarnDescription:
        'Работает 24/7 без остановок. Баланс монет и Пассивный доход влияют на рейтинг аирдропа - найди идеальный баланс!',
      forCoins: 'Монеты',
      forStars: 'Звезды',
      forVideo: 'Видео',
      specialOffer: 'Специальное предложение для вас!',
      specialOffer1: 'BFCM скидки! Действуют до:',
      boughtSuccessfully: 'Пассивный доход успешно приобретен',
      earnedWhileOffline: 'Офлайн заработок',
      passiveIncomePercentToAverageIncomePerUser:
        'Вы <span {classes}>{aboveOrBelow}</span> среднего значения на <span {classes}>{percent}%</span>',
      passiveIncomeIsZero: 'Ваш ранг ниже всех пользователей',
      upgrade: 'Получить больше'
    },
    claimTab: {
      claim: 'Вывод',
      getCashback: 'Получай 10% кешбек за покупки своих рефералов',
      youEarned: 'Твой заработок',
      noEarningsYet: 'Пока нет заработка',
      inviteAFriend: 'Пригласить друга',
      claimed: 'Выведено',
      usersList: 'TOП-10 рефералов',
      usersFullList: 'Рефералы',
      noPurchases: 'Пока нет покупок',
      tableHead: {
        date: 'Дата',
        amount: 'Сумма',
        hash: 'Хэш'
      }
    },
    userList: {
      title: 'Список ваших друзей',
      noFriendsYet: 'Пока нет друзей',
      noUsersYet: 'Пока нет результатов'
    },
    eventTab: {
      event: 'Турнир',
      title:
        'Собирай монеты, чтобы получить шанс разделить призы на сумму <span class="text-yellow text-bold">2000 USD</span>',
      description:
        '💰 Собирай как можно больше монет: зарабатывай через тапы, пассивный доход, приглашай друзей и помогай им продвигаться по лигам. Чем больше монет – тем выше шанс выиграть приз! 🏆',
      howToParticipate: 'Как принять участие?',
      prizeTable: {
        header: '🎁 Призовой фонд',
        firstRow: '🥇 1 место – 1000 USD',
        secondRow: '🥈 2 место – 150 USD',
        thirdRow: '🥉 3 место – 100 USD',
        fourthRow: '4-8 места – 50 USD',
        fifthRow: '9-10 места – 50 USD в виде пассивного дохода',
        sixthRow: '11-15 места – 40 USD в виде пассивного дохода',
        seventhRow: '16-20 места – 20 USD в виде пассивного дохода',
        eighthRow: '21-30 места - 10 USD в виде пассивного дохода'
      },
      participateCondition: {
        first: 'Подключи TON кошелек в разделе "Задания".',
        second: {
          title: 'Выполните одно из следующих условий:',
          sub1: 'Пригласи 5 друзей во время регистрации',
          sub2: 'Купи пассивный доход на сумму от 750 звезд.'
        }
      },
      register: {
        eventStarts: 'Турнир стартует через',
        registerButton: 'Принять участие',
        youAreRegistered: 'Вы зарегистрированы на турнир',
        notRegistered:
          'Извините, вы не зарегистрированы на турнир. Пожалуйста, подождите следующий.'
      },
      tapPhase: {
        title:
          'Собирайте монеты, чтобы получить шанс разделить призы на сумму <span class="text-yellow text-bold">150 USD</span>',
        subTitle:
          'Заработайте как можно больше монет, тапая, приглашая друзей, повышая уровень или получая пассивный доход. Топ-5 победителей разделят ценные призы',
        amountOfCoinsEarned: 'Монет собрано',
        eventEndsIn: 'Завершение турнира через',
        leaderBoard: 'Рейтинг участников'
      },
      resultPhase: {
        title:
          'Собирайте монеты, чтобы получить шанс разделить призы на сумму <span class="text-yellow text-bold">150 USD</span>',
        eventIsOver: 'Турнир завершен. Дождитесь следующий, чтобы принять участие'
      },
      notifications: {
        noPhaseFound: 'Фаза для турнира не найдена.',
        notEnoughReferralsOrPurchasePassiveIncome:
          'Регистрация не удалась. Вам нужно зарегистрировать как минимум 5 рефералов или купить пассивный доход на сумму 750 звезд или более во время фазы турнира.',
        noConnectedWallets:
          'Регистрация не удалась. Вам нужно подключить свой кошелек, чтобы принять участие в фазе турнира.',
        registrationSuccess: 'Вы успешно зарегистрированы на фазу турнира.',
        noActiveEvents: 'Активные турниры не найдены.'
      }
    },
    tournamentTab: {
      tournament: 'Турнир',
      register: {
        joinTournament: 'Tournament Title',
        description: 'Tournament description.',
        tournamentStarts: 'Турнир начнется через'
      },
      tabs: {
        tournaments: 'Турниры',
        history: 'История',
        ratings: 'Рейтинг'
      },
      tournaments: {
        title: 'Торговые турниры',
        description:
          "Участвуйте в наших ежедневных турнирах и анализируйте/прогнозируйте цену определенной криптопары. Если ваш прогноз верен – вы получите обновление рейтинга. Этот рейтинг в дальнейшем повлияет на раздачу монет как мультипликатор баланса монет. <span class='gradient-text'>Чем выше рейтинг -> тем больше у вас шансов попасть на вершину airdrop!</span>",
        categories: {
          fiveMin: '5 минут',
          fifteenMin: '15 минут',
          oneHour: '1 час',
          oneDay: '1 день',
          fiveMinDescription: 'Предскажите цену крипто пары на 5 минут',
          fifteenMinDescription: 'Предскажите цену крипто пары на 15 минут',
          oneHourDescription: 'Предскажите цену крипто пары на 1 час',
          oneDayDescription: 'Предскажите цену крипто пары на 1 день',
          pointsAchievedToday: 'Набрано сегодня:'
        },
        bet: {
          roundTitle: ({ named }: any) =>
            `Предскажи цену <span class="uppercase text-bold">${named('ticker')}</span> на следующие ${named('title')}.<br/> ${named('message')}`,
          guessPrice: ({ named }: any) =>
            `Предскажите цену <span class="uppercase text-bold">${named('ticker')}</span>`,
          attemptsLeft: 'Осталось попыток: {attempts}',
          priceFromBinance: 'Цена с binance <span class="uppercase">spot</span>',
          tickerPrice: ({ named }: any) => `Цена ${named('ticker').toUpperCase()}:`,
          down: 'Опустится',
          up: 'Поднимется',
          bid: 'Ставка',
          value: 'Цена',
          successfullyRegisteredForTheRound: {
            up: 'Вы успешно зарегистрировались на раунд с прогнозом: поднимется',
            down: 'Вы успешно зарегистрировались на раунд с прогнозом: опустится',
            number: 'Вы успешно зарегистрировались на раунд'
          }
        },
        attemptsLeftForToday: 'Попыток осталось сегодня:',
        registrationStartsIn: 'Регистрация начнется через:',
        registrationEndsIn: 'Регистрация закончится через:',
        tournamentEndsIn: 'Турнир закончится через:',
        dontHaveTournaments: 'Турниров не найдено',
        dontHaveCategories: 'Категорий не найдено'
      },
      history: {
        title: 'История ваших турниров',
        description: '',
        total: 'Всего',
        lose: 'Проиграно',
        winnings: 'Выиграно',
        noHistory: 'Пока нет записей'
      },
      ratings: {
        title: 'Рейтинги турниров',
        description: '',
        score: 'Рейтинг: {score}',
        winRate: 'Процент побед',
        noRatingsAvailable: 'Нет доступных рейтингов'
      }
    },
    ranking: {
      from: 'от',
      yourPosition: 'Ваша позиция',
      stoneLeague: 'Каменная Лига',
      bronzeLeague: 'Бронзовая Лига',
      silverLeague: 'Серебряная Лига',
      goldLeague: 'Золотая Лига',
      platinumLeague: 'Платиновая Лига',
      diamondLeague: 'Бриллиантовая Лига',
      cosmosLeague: 'Космическая Лига',
      iceLeague: 'Ледяная Лига'
    },
    raffleTab: {
      title: '1000 случайных участников разделят приз в 10000 TON',
      description: 'Чем больше у вас тикетов, тем выше ваши шансы выиграть!',
      raffleEndsIn: 'Raffle ends in:',
      yourTickets: 'Ваши тикеты',
      wantMoreTickets: 'Хотите больше тикетов?',
      buyTickets: 'Купить тикет',
      howRaffleWorks: {
        title: 'Методы получения:',
        option1: 'покупая за звезды',
        option2: 'один тикет за 5 приглашенных друзей',
        option3: 'один тикет за просмотр 50 промо видео',
        description:
          'Когда оплачиваете используя звезды в лотерее или в пассивных доходах, вы получите бесплатный тикет.'
      }
    },
    games: {
      title: 'Игры',
      description:
        'Участвуйте в наших ежедневных турнирах и анализируйте/прогнозируйте цену определенного криптоактива. Если ваш прогноз верен – вы получите обновление рейтинга.',
      quiz: 'Квиз',
      combo: 'Комбо',
      prediction: 'Удачный трейд'
    },
    luckyTrade: {
      availablePoints: 'Доступно монет',
      needMoreCoins: 'Нужно больше монет?',
      btcPrice: 'Цена BTC:',
      guessPrice: 'Угадай цену BTC',
      priceFrom: 'Цена с binance <span class="uppercase">spot</span>',
      down: 'ШОРТ',
      up: 'ЛОНГ',
      results: {
        win: 'ПОБЕДА!',
        lose: 'УПС :(',
        btcPrice: `Цена BTC <span {classes}>{diff}%</span>`,
        from: ({ named }: any) => `С $${named('from')} до $${named('to')}`
      }
    },
    puzzleGame: {
      puzzle: 'Комбо',
      userComboFailed: 'Не в єтот раз :(',
      userComboSuccess: 'Ура! Ты получил 100,000 монет!',
      findCorrectCombination:
        'Найдите правильную комбинацию из 4 иконок. У вас есть только одна попытка!',
      check: 'Проверить',
      notLucky: 'Сегодня не ваш день',
      nextGameStarts: 'Следующая игра начнется через'
    },
    maintenance: {
      text: 'Мы закрыты на техническое обслуживание. Скоро вернемся!'
    }
  },
  uk: {
    common: {
      hr: 'год',
      boosts: 'Буст',
      energyLimit: 'Ліміт енергії',
      tapPower: 'Сила тапу',
      howItWorks: 'Як це працює',
      comingSoon: 'Скоро',
      claimReward: 'Отримати нагороду',
      hide: 'Сховати',
      level: 'Рівень',
      premium: 'Преміум',
      less: 'Менше',
      more: 'Більше',
      back: 'Повернутися',
      viewMore: 'Показати більше',
      check: 'Перевірити',
      join: 'Підпишись',
      notEnoughCoins: 'Недостатньо монет',
      games: 'Ігри',
      tournaments: 'Турніри',
      d: 'д',
      h: 'г',
      m: 'х',
      s: 'с',
      above: 'вище',
      below: 'нижче',
      cost: 'Вартість',
      loading: 'Завантаження'
    },
    welcomeSlides: {
      welcome: {
        title: 'Ласкаво просимо до GDF Trade',
        subTitle:
          'Почни пригоди з GDF: тапай, збирай монети, підіймайся по лiгам та отримуй бiльше токенiв на ейрдроп!'
      },
      inviteFriends: 'Запрошуй друзiв та отрумуй нагороди, щоб прокачуватись швидше!',
      participateInRaffle: 'Приєднуйся до раффлу та отримай шанс виграти топ призи.',
      claimRealTon: 'Заробляй 10% кешбек у USDT від своїх рефералів.',
      next: 'Далі',
      letsGo: 'Поїхали'
    },
    tab: {
      home: 'Головна',
      boosts: 'Бусти',
      invite: 'Друзі',
      earns: 'Завдання',
      claim: 'Claim',
      raffle: 'Раффл',
      event: 'Турнір',
      tournament: 'Tourney'
    },
    homeTab: {
      info: {
        tournamentStarts: 'Наш турнир почнеться через',
        takePartInEvent: 'Приєднуйся до події',
        winWithUs: 'Вигравай з нами!',
        getInvolved: 'Прийняти участь',
        stayTuned: "Залишайся на зв'язку",
        newEventsAreComing: 'Нові івенти вже скоро'
      }
    },
    boostTab: {
      maxEnergyLevelCaptured: 'Досягнуто ліміту єнергії',
      maxTapPowerReached: 'Досягнуто ліміту сили тапів',
      boost: 'Прокачати',
      howItWorks: {
        description:
          'Заробляти монети легко, торкаючись екрана. Але ви можете заробляти ще швидше з Бустами!',
        title1: '1. Мультітап підсилення:',
        text1:
          'Це Підсилення дозволяє заробляти кілька монет за кожен дотик. Його можна покращити кілька разів (кожне натискання витрачатиме більше енергії, щоб прискорити процес, і дасть більше монет).',
        tableLevelTitle: 'Рівень/Буст',
        tableTapPowerTitle: 'Сила тапу',
        tableEnergyLimitTitle: 'Ліміт енергії',
        tablePriceTitle: 'Ціна',
        title2: '2. Підвищення ліміту енергії:',
        text2:
          'Це Підсилення збільшує ваш енергетичний потенціал, дозволяючи вам натискати більше. Він заповнюється протягом 15 хвилин на лінійній основі.',
        lvl: ({ named }: any) => `${named('level')} рівень`
      },
      tapPowerUpdateErr: 'Недостатньо кредитів для підвищення рівня сили тапа',
      tapPowerSuccessUpdate: 'Силу тапа успішно прокачано',
      energyLevelUpdateErr: 'Недостатньо кредитів для підвищення ліміту енергії',
      energyLevelSuccessUpdate: 'Ліміт енергіх успішно прокачано'
    },
    earnTab: {
      earn: 'Завдання',
      taskList: 'Перелiк завдань',
      completeTasksDaily: 'Виконуй завдання щодня задля бiльших нагород',
      tasks: {
        joinGdFundTelegramChannel: {
          title: 'Приєднуйся до нашої спiльноти',
          description: 'Підпишись на наш Telegram канал і будь в курсі останніх новин та оновлень.',
          buttonTitle: 'Підпишись'
        },
        joinGdFundTelegramChannel2: {
          title: 'Пiдпишись на канал GD Fund',
          description: 'Підпишись на наш Telegram канал і будь в курсі останніх новин та оновлень.',
          buttonTitle: 'Підписатись'
        },
        followGdFundOnX: {
          title: 'Слідкуй за нами на X',
          description: 'Підпишись на нас на X і будь в курсі останніх новин та оновлень.',
          buttonTitle: 'Підпишись'
        },
        invite3Friends: {
          title: 'Запроси 3 друзів',
          description: 'Запроси 3 друзів приєднатись до GD Fund та отримай 25000 очок.',
          buttonTitle: 'Отримати нагороду'
        },
        dailyLogin: {
          title: 'Щоденний вхід',
          description: 'Заходь у гру щодня задля бiльших нагород',
          buttonTitle: 'Отримати нагороду'
        },
        connectWallet: {
          title: 'Підключити гаманець',
          description: 'Підключіть свій гаманець до GD Fund та отримайте винагороду.',
          connectButtonTitle: 'Підключити гаманець',
          disconnectButtonTitle: 'Відключити гаманець',
          messages: {
            success: 'Гаманець успішно підключено',
            error: 'Не вдалося підключити гаманець',
            disconnect: 'Гаманeць успішно відключено'
          }
        },
        joinToTonNewsChain: {
          title: 'Підпишись на TON News Chain',
          description: 'Підпишись на TON News Chain та отримай винагороду.',
          buttonTitle: 'Підпишись'
        },
        joinDropCryptoZona: {
          title: 'Підпишись на Drop CryptoZona',
          description: 'Підпишись на Drop CryptoZona та отримай винагороду.',
          buttonTitle: 'Підпишись'
        },
        joinGreenCrypto: {
          title: 'Підпишись на Green Crypto',
          description: 'Підпишись на Green Crypto та отримай винагороду.',
          buttonTitle: 'Підпишись'
        },
        launchCapllyBot: {
          title: 'Запусти Caplly бот',
          description: 'Запусти Caplly бот та отримай винагороду.',
          buttonTitle: 'Запустити'
        },
        joinCapllyCommunity: {
          title: 'Підпишись на Caplly Community',
          description: 'Підпишись на Caplly Community та отримай винагороду.',
          buttonTitle: 'Підпишись'
        },
        launchGreenGoldBot: {
          title: 'Запусти GreenGold бот',
          description: 'Запусти GreenGold бот та отримай винагороду.',
          buttonTitle: 'Запустити'
        },
        joinTestTheMillion: {
          title: 'Підпишись на канал Test The Million',
          description: 'Підпишись на канал Test The Million та отримай винагороду.',
          buttonTitle: 'Підпишись'
        },
        subscribeToDailyComboNotifications: {
          title: 'Підпишись на канал Daily Combo',
          description: 'Підпишись на сповіщення Daily Combo.',
          buttonTitle: 'Підпишись'
        },
        launchPiggyBankGame: {
          title: 'Зарабляй $BALLS в PiggyBank!',
          description: 'Запусти Piggy Bank Game та отримай винагороду.',
          buttonTitle: 'Запустити'
        }
      },
      notifications: {
        taskCompletedSuccessfully: 'Завдання успішно виконано Ви отримали {reward} монет!',
        taskWasNotFound: 'Завдання не знайдено',
        taskAlreadyCompleted: 'Завдання вже виконано',
        taskConditionsNotMet: 'Не виконані умови для виконання завдання',
        failedToCompleteTask: 'Не вдалося виконати завдання'
      },
      daily: {
        title: 'Тижні Удачі',
        description:
          'Накопичуйте монети та отримуйте нагороди за щоденний вхід у гру без пропусків.',
        cycle: 'Рiвень',
        day: 'день {day}',
        shorts: 'Shorts',
        tShort: 'T-short!',
        pressToClose: 'Натисніть, щоб закрити'
      }
    },
    inviteTab: {
      tabs: { invite: 'Запросити', claim: 'Отримати' },
      copyLink: 'Скопіювати посилання',
      noReferralsYet: 'Поки що немає рефералів',
      inviteFriends: 'Запросити друзів',
      moreReferrals: 'Більше рефералів - більше бонусів',
      inviteYourFriends: 'Запроси друзів грати та розвиватись разом',
      gifts: {
        inviteAFriend: {
          title: 'Запросити друга',
          subtitle: 'для тебе та твого друга',
          invite: 'Запросити'
        },
        inviteAFriendWithTG: {
          title: 'Запроси друга з Telegram Premium',
          subtitle: 'для тебе та твого друга'
        }
      },
      moreBonuses: 'Більше бонусів',
      bonusForLevellingUp: 'Бонус за підвищення рівня',
      forFriend: ' Для друга',
      listOfYourFriends: 'Список друзів',
      inviteAFriendButton: 'Запросити'
    },
    passiveIncome: {
      passiveEarn: 'Пасивний дохід',
      passiveEarnDescription:
        'Працює 24/7 без зупинок. Бланс монет і пасивний дохід впливають на рейтинг еірдропу - знайди ідеальний баланс!',
      forCoins: 'За монети',
      forStars: 'За зірки',
      forVideo: 'За відео',
      specialOffer: 'Спеціальна пропозиція для вас!',
      specialOffer1: 'BFCM скидки! Діють до:',
      boughtSuccessfully: 'Пасивний дохід успішно куплений',
      earnedWhileOffline: 'Офлайн заробіток',
      passiveIncomePercentToAverageIncomePerUser:
        'Ви <span {classes}>{aboveOrBelow}</span> середнього значення на <span {classes}>{percent}%</span>',
      passiveIncomeIsZero: 'Ваш ранг нижче всіх користувачів',
      upgrade: 'Отримати більше'
    },
    claimTab: {
      claim: 'Отримати',
      getCashback: 'Отримуй 10% кешбек за покупки своїх рефералiв',
      youEarned: 'Ти заробив',
      noEarningsYet: 'Ще немає заробітку',
      inviteAFriend: 'Запросити друга',
      claimed: 'Виведено',
      usersList: 'TOП-10 рефералів',
      usersFullList: 'Реферали',
      noPurchases: 'Ще немає покупок',
      tableHead: {
        date: 'Дата',
        amount: 'Сума',
        hash: 'Хеш'
      }
    },
    userList: {
      title: 'Список твоїх друзів',
      noFriendsYet: 'Поки що немає друзів',
      noUsersYet: 'Поки що немає результатів'
    },
    eventTab: {
      event: 'Турнір',
      title:
        'Збирай монети, щоб отримати шанс розділити призи на суму <span class="text-yellow text-bold">2000 USD</span>',
      description:
        '💰 Збирай якомога більше монет: заробляй через тапи, пасивний дохід, запрошуй друзів та допомагай їм просуватися по лігах. Чим більше монет – тим вищі шанси на перемогу! 🏆',
      howToParticipate: 'Як прийняти участь?',
      prizeTable: {
        header: '🎁 Призовий фонд',
        firstRow: '🥇 1 місце – 1000 USD',
        secondRow: '🥈 2 місце – 150 USD',
        thirdRow: '🥉 3 місце – 100 USD',
        fourthRow: '4-8 місця – 50 USD',
        fifthRow: '9-10 місця – 50 USD у вигляді пасивного доходу',
        sixthRow: '11-15 місця – 40 USD у вигляді пасивного доходу',
        seventhRow: '16-20 місця – 20 USD у вигляді пасивного доходу',
        eighthRow: '21-30 мiсця - 10 USD у вигляді пасивного доходу'
      },
      participateCondition: {
        first: 'Підключи свій TON гаманець у розділі "Завдання".',
        second: {
          title: 'Виконай одну з умов:',
          sub1: 'Запроси 5 друзів під час реєстрації',
          sub2: 'Купи пасивний дохід на суму від 750 старс або більше.'
        }
      },
      register: {
        eventStarts: 'Турнір стартує через',
        registerButton: 'Прийняти участь',
        youAreRegistered: 'Ти зареєстрований на турнір',
        notRegistered: 'Вибачте, ви не зареєстровані на турнір. Будь ласка, зачекайте на наступний.'
      },
      tapPhase: {
        title:
          'Збирайте монети, щоб отримати шанс розділити призи на суму <span class="text-yellow text-bold">150 USD</span>',
        subTitle:
          'Заробіть якомога більше монет, натискаючи, запрошуючи друзів, підвищуючи рівень або отримуючи пасивний дохід. Топ-5 поділять цінні призи',
        rankTable: {
          header: 'Топ-5 поділять цінні призи',
          firstRow: '1-е місце - пасивний дохід на суму 75 USD',
          secondRow: '2-е місце - пасивний дохід на суму 50 USD',
          thirdRow: '3-е місце - пасивний дохід на суму 25 USD',
          fourthRow: '4-е місце - 75% монет, зібраних під час турніру',
          fifthRow: '5-е місце - 50% монет, зібраних під час турніру'
        },
        amountOfCoinsEarned: 'Монет зiбрано',
        eventEndsIn: 'Завершення турнiру через',
        leaderBoard: 'Рейтинг учасникiв'
      },
      resultPhase: {
        title:
          'Збирайте монети, щоб отримати шанс розділити призи на суму <span class="text-yellow text-bold">150 USD</span>',
        eventIsOver: 'Турнiр завершено. Чекайте на наступний, щоб взяти участь'
      },
      notifications: {
        noPhaseFound: 'Немає фази для турніру.',
        notEnoughReferralsOrPurchasePassiveIncome:
          'Реєстрація не вдалася. Вам потрібно мати принаймні 5 рефералів зареєстрованих або купити пасивний дохід на суму 750 зірок або більше під час фази турніру.',
        noConnectedWallets:
          'Реєстрація не вдалася. Вам потрібно підключити свій гаманець, щоб взяти участь у фазі турніру.',
        registrationSuccess: 'Ви успішно зареєструвалися на подію',
        noActiveEvents: 'Немає активних турнірів.'
      }
    },
    tournamentTab: {
      tournament: 'Турнір',
      register: {
        joinTournament: 'Tournament Title',
        description: 'Tournament description.',
        tournamentStarts: 'Tournament starts in'
      },
      tabs: {
        tournaments: 'Турніри',
        history: 'Історія',
        ratings: 'Рейтинг'
      },
      tournaments: {
        title: 'Торгівельні турніри',
        description:
          "Беріть участь у наших щоденних турнірах і аналізуйте/прогнозуйте ціну конкретної криптопари. Якщо ваш прогноз правильний - ви отримаєте оновлення рейтингу. Цей рейтинг додатково вплине на еірдроп як мультиплікатор балансу монет.<span class='gradient-text'>Чим вищий рейтинг -> тим кращий ваш шанс потрапити на вершину еірдропу!</span>",
        categories: {
          fiveMin: '5 хвилин',
          fifteenMin: '15 хвилин',
          oneHour: '1 година',
          oneDay: '1 день',
          fiveMinDescription: 'Передбачте вартість кріпто пари на 5 хвилин',
          fifteenMinDescription: 'Передбачте вартість кріпто пари на 15 хвилин',
          oneHourDescription: 'Передбачте вартість кріпто пари на 1 годину',
          oneDayDescription: 'Передбачте вартість кріпто пари на 1 день',
          pointsAchievedToday: 'Набрано сьогодні:'
        },
        bet: {
          roundTitle: ({ named }: any) =>
            `Передбачте ціну <span class="uppercase text-bold">${named('ticker')}</span> за наступні ${named('title')}.<br/> ${named('message')}`,
          guessPrice: ({ named }: any) =>
            `Вгадайте ціну <span class="uppercase text-bold">${named('ticker')}</span>`,
          attemptsLeft: 'Залишилось спроб: {attempts}',
          priceFromBinance: 'Ціна від binance <span class="uppercase">spot</span>',
          tickerPrice: ({ named }: any) => `Ціна ${named('ticker').toUpperCase()}:`,
          down: 'зменшиться',
          up: 'збільшиться',
          bid: 'Ставка',
          value: 'Ціна',
          successfullyRegisteredForTheRound: {
            up: 'Ви успішно зареєструвалися в раунді з прогнозом: збільшиться',
            down: 'Ви успішно зареєструвалися в раунді з прогнозом: зменшиться',
            donumberwn: 'Ви успішно зареєструвалися в раунді'
          }
        },
        registrationStartsIn: 'Реєстрація почнеться через:',
        registrationEndsIn: 'Реєстрація завершиться через:',
        tournamentEndsIn: 'Турнір завершиться через:',
        attemptsLeftForToday: 'Залишилось спроб сьогодні:',
        dontHaveTournaments: 'Немає турнірів',
        dontHaveCategories: 'Немає категорій'
      },
      history: {
        title: 'Історія ваших турнірів',
        description: '',
        total: 'Всього',
        lose: 'Програшів',
        winnings: 'Виграшів',
        noHistory: 'Ще немає записів'
      },
      ratings: {
        title: 'Турнірні рейтинги',
        description: '',
        score: 'Рейтинг: {score}',
        winRate: 'Коефіцієнт виграшу',
        noRatingsAvailable: 'Рейтинги відсутні'
      }
    },
    ranking: {
      from: 'от',
      yourPosition: 'Ваша позиція',
      stoneLeague: 'Кам’яна Ліга',
      bronzeLeague: 'Бронзова Ліга',
      silverLeague: 'Срібна Ліга',
      goldLeague: 'Золота Ліга',
      platinumLeague: 'Платинова Ліга',
      diamondLeague: 'Діамантова Ліга',
      cosmosLeague: 'Космічна Ліга',
      iceLeague: 'Крижана Ліга'
    },
    raffleTab: {
      title: '1000 випадкових учасників розділять нагороду у 10000 TON',
      description: 'Чим більше у вас тікитів, тим вищі ваші шанси виграти!',
      raffleEndsIn: 'Raffle ends in:',
      yourTickets: 'Ваші тікети',
      wantMoreTickets: 'Бажаєте більше тікетов?',
      buyTickets: 'Придбати тікети',
      howRaffleWorks: {
        title: 'Методы отримання:',
        option1: 'купуючи за зірки',
        option2: 'один тікет за 5 запрошених друзів',
        option3: 'один тікет за перегляд 50 промо відео',
        description:
          'Коли використовуюте зірки при оплаті в лотереї чи в пасивних доходах, ви отпримуєте безкоштовний тікет.'
      }
    },
    games: {
      title: 'Ігри',
      description:
        'Беріть участь у наших щоденних турнірах і аналізуйте/прогнозуйте ціну конкретного криптоактиву. Якщо ваш прогноз правильний - ви отримаєте оновлення рейтингу.',
      quiz: 'Квiз',
      combo: 'Комбо',
      prediction: 'Вдалий трейд'
    },
    luckyTrade: {
      availablePoints: 'Доступно монет',
      needMoreCoins: 'Потрібно більше монет?',
      guessPrice: 'Вгадай ціну BTC',
      btcPrice: 'Ціна BTC:',
      priceFrom: 'Ціна з binance <span class="uppercase">spot</span>',
      down: 'ШОРТ',
      up: 'ЛОНГ',
      results: {
        win: 'ПОБЕДА!',
        lose: 'УПС :(',
        btcPrice: ({ named }: any) => `Ціна BTC <span {classes}>${named('diff')}%</span>`,
        from: ({ named }: any) => `З $${named('from')} до $${named('to')}`
      }
    },
    puzzleGame: {
      puzzle: 'Комбо',
      userComboFailed: 'Не цього разу :(',
      userComboSuccess: 'Ура! Ти отримав 100,000 монет!',
      findCorrectCombination: 'Знайди правильну комбінацію з 4 биків. У тебе лише одна спроба!',
      check: 'Перевірити',
      notLucky: 'Сьогодні не ваш день',
      nextGameStarts: 'Наступна гра почнеться через'
    },
    maintenance: {
      text: 'Ми на технічнону обслуговуванні. Незабаром повертайся!'
    }
  }
}

const socket = new WebSocket(`wss://stream.binance.com:9443/ws`)
//wss://ws-api.binance.com:443/ws-api/v3
const tickerHandlers = new Map()

socket.addEventListener('message', (e) => {
  // console.log('e.data: ', JSON.parse(e.data))

  if (e.data) {
    const { s: currency, e: type, p: price, a: aggTradeId } = JSON.parse(e.data)

    if (type !== 'aggTrade' || price === undefined) {
      return
    }

    const handlers = tickerHandlers.get(currency.toLowerCase()) ?? []
    handlers.forEach((fn: any) => fn({ price, aggTradeId }))
  }
})

export function sendToWebSocket(message: any) {
  const stringifiedMessage = JSON.stringify(message)
  if (socket.readyState === WebSocket.OPEN) {
    socket.send(stringifiedMessage)
    return
  }
  socket.addEventListener(
    'open',
    () => {
      socket.send(stringifiedMessage)
    },
    { once: true }
  )
}

export function subscribeToTickerOnWS(ticker: string) {
  const params = [`${ticker}@aggTrade`] //, `${ticker}@depth`

  sendToWebSocket({
    method: 'SUBSCRIBE',
    params,
    id: 1
  })
}

export function unsubscribeFromTickerOnWS(ticker: string) {
  sendToWebSocket({
    method: 'UNSUBSCRIBE',
    params: [`${ticker}@aggTrade`], //, `${ticker}@depth`
    id: 312
  })
}

export const subscribeToTicker = (ticker: string, cb: (t: any) => void) => {
  const subscribers = tickerHandlers.get(ticker) || []
  tickerHandlers.set(ticker, [...subscribers, cb])
  subscribeToTickerOnWS(ticker)
}

export const unSubscribeToTicker = (ticker: string) => {
  tickerHandlers.delete(ticker)
  unsubscribeFromTickerOnWS(ticker)
}

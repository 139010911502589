<script setup lang="ts"></script>

<template>
  <button class="btn_text">
    <slot />
    <slot name="appendIcon" />
  </button>
</template>

<style scoped>
.btn_text {
  color: var(--white, #f3ffe9);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  letter-spacing: -0.43px;
  background: transparent;
  padding: 0;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>

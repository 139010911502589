<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ComingSoon from '../common/ComingSoon.vue'
import Container from '../common/Container.vue'
import IconBack from '../icons/IconBack.vue'

defineEmits(['close'])

const { t } = useI18n()
</script>

<template>
  <Container>
    <div class="mt-7 flex items-center gap-3" @click="$emit('close')">
      <IconBack class="text-light-green" style="width: 20px; height: 20px" />
      <!-- {{ t('common.back') }} -->
    </div>

    <ComingSoon style="z-index: -1" />
  </Container>
</template>

<style scoped></style>

import { useApi } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type ReferralPayments = App.DTO.Claim.ReferralPaymentsData

export const useUserClaimStore = defineStore('userClaim', () => {
  const referralPayments = ref<App.DTO.Claim.ReferralPaymentsData[]>([])
  const totalClaimAmountAvailable = ref<number>(0)
  const userClaims = ref<App.DTO.Claim.UserClaimData[]>([])

  async function getReferralPayments(page: number, perPage: number) {
    try {
      const query = []
      if (page) query.push(`page=${page}`)
      if (perPage) query.push(`perPage=${perPage}`)
      const queryString = query.length ? `?${query.join('&')}` : ''

      const res = (await useApi().get(`/api/referral-payments${queryString}`))?.data

      referralPayments.value = res?.data || []
      return res
    } catch (error) {
      console.error('Error getting referral payments', error)
    }
  }

  async function getTotalClaimAmountAvailable() {
    try {
      totalClaimAmountAvailable.value =
        (await useApi().get('/api/total-claim-amount-available'))?.data?.data || 0
    } catch (error) {
      console.error('Error getting total claim amount available', error)
    }
  }

  async function submitClaim(amount: number) {
    await useApi()
      .post('/api/claims', { amount })
      .then(() => {
        getReferralPayments(1, 10)
        getTotalClaimAmountAvailable()
      })
  }

  async function getClaims() {
    try {
      userClaims.value = (await useApi().get('/api/claims'))?.data?.data || []
    } catch (error) {
      console.error('Error getting claims', error)
    }
  }

  return {
    referralPayments,
    getReferralPayments,
    totalClaimAmountAvailable,
    getTotalClaimAmountAvailable,
    submitClaim,
    getClaims,
    userClaims
  }
})

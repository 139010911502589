import {
  getActiveEvent,
  getActiveEventPhase,
  getPhaseUsers as getPhaseUsersApi,
  getUserRegistrationPhase,
  registerForPhase as registerUserForPhase
} from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type EventPhase = {
  id: number
  started_at: Date
  ended_at: Date
  is_registered: boolean
  credits_earned: number | undefined
  can_register_after_registration_time_passed: boolean
}

type RegistrationPhase = EventPhase
export type PhaseUsers = { id: number; name: string; balance: number; profile_image_url: string }

export const useGameEventsStore = defineStore('gameEvents', () => {
  const isUserRegisteredForEvent = ref(false)
  const registrationPhase = ref<RegistrationPhase | null>(null)
  const activeEventPhase = ref<EventPhase | null>(null)
  const phaseUsers = ref<PhaseUsers[]>([])
  const fetchingResults = ref(true)
  const eventId = ref(2)

  const hasNewEvent = computed(() =>
    activeEventPhase.value ? !activeEventPhase.value?.is_registered : false
  )

  async function checkUserRegistration(eventId: number, phaseId: number) {
    registrationPhase.value = await getUserRegistrationPhase(eventId, phaseId)

    isUserRegisteredForEvent.value = registrationPhase.value?.is_registered || false
  }

  function registerForPhase(phaseId: number) {
    registerUserForPhase(phaseId).then((registration) => {
      if (registration?.success) {
        isUserRegisteredForEvent.value = true
        if (activeEventPhase.value) activeEventPhase.value.is_registered = true
      }
    })
  }

  async function getCurrentEventPhase(eventId: number) {
    activeEventPhase.value = (await getActiveEventPhase(eventId)) as EventPhase | null
  }

  async function getCurrentEvent() {
    await getActiveEvent().then((event) => {
      eventId.value = event.id
      getCurrentEventPhase(event.id)
    })
  }

  async function getPhaseUsers(phaseId: number) {
    fetchingResults.value
    try {
      phaseUsers.value = await getPhaseUsersApi(phaseId)
    } catch (error) {
      // phaseUsers.value = [
      //   { id: 1, name: 'name-1', balance: 2545, profile_image_url: '' },
      //   { id: 2, name: 'name-2', balance: 355, profile_image_url: '' },
      //   { id: 3, name: 'name-3', balance: 34634, profile_image_url: '' },
      //   { id: 4, name: 'name-4', balance: 254564345, profile_image_url: '' }
      // ]
    } finally {
      fetchingResults.value = false
    }
  }

  return {
    isUserRegisteredForEvent,
    checkUserRegistration,
    registrationPhase,
    registerForPhase,
    getCurrentEvent,
    getCurrentEventPhase,
    activeEventPhase,
    getPhaseUsers,
    phaseUsers,
    hasNewEvent,
    eventId,
    fetchingResults
  }
})

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 23 22"
    stroke-width="1.5"
    class="size-6"
    width="23"
    height="22"
  >
    <path
      d="M15.7626 18.5167V18.7825C14.4059 19.6533 12.7834 20.1667 11.0417 20.1667C9.27256 20.1667 7.62256 19.635 6.24756 18.7275V18.5167C6.24756 16.3809 8.38339 14.6392 11.0051 14.6392C13.6359 14.6392 15.7626 16.3809 15.7626 18.5167Z"
    />
    <path
      opacity="0.4"
      d="M19.7499 11.4583C19.7499 14.5292 18.1641 17.2242 15.7624 18.7825V18.5167C15.7624 16.3808 13.6358 14.6391 11.0049 14.6391C8.38325 14.6391 6.24742 16.3808 6.24742 18.5167V18.7275C3.89159 17.1692 2.33325 14.5017 2.33325 11.4583C2.33325 6.64583 6.22909 2.75 11.0416 2.75C12.2424 2.75 13.3883 2.98832 14.4333 3.42832C14.3141 3.79499 14.2499 4.18 14.2499 4.58333C14.2499 5.27083 14.4424 5.92166 14.7816 6.47166C14.9649 6.78333 15.2033 7.06747 15.4783 7.30581C16.1199 7.89247 16.9724 8.25 17.9166 8.25C18.3199 8.25 18.7049 8.18581 19.0624 8.05748C19.5024 9.10248 19.7499 10.2575 19.7499 11.4583Z"
    />
    <path
      d="M20.6392 2.13587C19.9792 1.3842 18.9983 0.916687 17.9167 0.916687C16.89 0.916687 15.955 1.33837 15.2858 2.02587C14.9008 2.42004 14.6075 2.89668 14.4333 3.42834C14.3142 3.79501 14.25 4.18002 14.25 4.58335C14.25 5.27085 14.4425 5.92168 14.7817 6.47168C14.965 6.78335 15.2033 7.06749 15.4783 7.30583C16.12 7.89249 16.9725 8.25002 17.9167 8.25002C18.32 8.25002 18.705 8.18583 19.0625 8.0575C19.9058 7.79167 20.6117 7.21418 21.0517 6.47168C21.2442 6.16002 21.3908 5.80251 21.4733 5.43585C21.5467 5.16085 21.5833 4.87669 21.5833 4.58335C21.5833 3.64835 21.2258 2.7867 20.6392 2.13587ZM19.2825 5.2525H18.6042V5.96753C18.6042 6.34336 18.2925 6.65503 17.9167 6.65503C17.5408 6.65503 17.2292 6.34336 17.2292 5.96753V5.2525H16.5508C16.175 5.2525 15.8633 4.94084 15.8633 4.565C15.8633 4.18917 16.175 3.8775 16.5508 3.8775H17.2292V3.2267C17.2292 2.85087 17.5408 2.5392 17.9167 2.5392C18.2925 2.5392 18.6042 2.85087 18.6042 3.2267V3.8775H19.2825C19.6583 3.8775 19.97 4.18917 19.97 4.565C19.97 4.94084 19.6675 5.2525 19.2825 5.2525Z"
    />
    <path
      d="M11.005 13.5025C12.4276 13.5025 13.5809 12.3493 13.5809 10.9267C13.5809 9.50407 12.4276 8.35083 11.005 8.35083C9.58244 8.35083 8.4292 9.50407 8.4292 10.9267C8.4292 12.3493 9.58244 13.5025 11.005 13.5025Z"
    />
  </svg>
</template>

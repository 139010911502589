<script setup lang="ts">
import { useTournamentStore, type CategorySchedule, type RoundTickers } from '@/stores/tournament'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Loader from '../common/Loader.vue'
import IconBack from '../icons/IconBack.vue'
// import RoundWidget from './RoundWidget.vue'
import { useGlobalIntervalStore } from '@/stores/globalInterval'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import EventTimer from '../common/EventTimer.vue'
import FullWindowModal from '../common/FullWindowModal.vue'
import CategoryGroupWidget from './CategoryGroupWidget.vue'
import TournamentBet1 from './TournamentBet1.vue'

const currencyToPair = 'usdt'

interface Props {
  categoryId: number
}

const props = defineProps<Props>()
defineEmits(['close', 'select-round'])

const gettingCategory = ref(true)
// const tryCount = ref(0)
const now = ref(new Date())

const { t } = useI18n()

const tournamentStore = useTournamentStore()
const {
  getTournamentById,
  getCurrentCategorySchedule,
  getRoundTickers,
  removeRoundFromSchedule,
  resetTickers
} = tournamentStore
const { tournament, attemptsLeft, selectedCurrencyRoundX, categorySchedule, roundTickers } =
  storeToRefs(tournamentStore)

const isDayTournament = computed(() => props.categoryId == 4)

const round = computed<CategorySchedule>(() =>
  categorySchedule.value?.length
    ? categorySchedule.value[0]
    : {
        // pre_registration_starts_at: new Date(),
        registration_ends_at: new Date(),
        ends_at: new Date(),
        results_ends_at: new Date()
      }
)
const isRegistration = computed(() => now.value < round.value.registration_ends_at)
const isInProgress = computed(
  () => round.value.registration_ends_at <= now.value && now.value < round.value.ends_at
)
const isResults = computed(
  () => round.value.ends_at <= now.value && now.value <= round.value.results_ends_at
)
const isEnded = computed(() => round.value.results_ends_at < now.value)

const currentEndDate = computed(() => {
  if (isRegistration.value) return round.value.registration_ends_at
  if (isInProgress.value) return round.value.ends_at
  if (isResults.value) return round.value.results_ends_at
  return round.value.registration_ends_at
})

const { tick } = storeToRefs(useGlobalIntervalStore())

watch(tick, () => {
  now.value = new Date()
})

const selectTournament = (tournament: RoundTickers) => {
  selectedCurrencyRoundX.value = tournament
}

onMounted(async () => {
  console.log('onMounted tourn 1: ')

  try {
    gettingCategory.value = true
    Promise.allSettled([
      getTournamentById(props.categoryId),
      getCurrentCategorySchedule(props.categoryId),
      getRoundTickers(props.categoryId)
    ])
  } catch (error) {
    //
  } finally {
    gettingCategory.value = false
  }
})

const renewRounds = () => {
  if (isEnded.value) {
    console.log('over: ')
    removeRoundFromSchedule()
    resetTickers()
  }
}
</script>

<template>
  <div v-if="tournament === null" class="flex justify-center">
    <Loader />
  </div>
  <div v-else>
    <div class="mt-10 relative">
      <div class="flex justify-center flex-col items-center gap-2 ma-auto" style="max-width: 80%">
        <h2 class="page-title" v-html="t(tournament.title)"></h2>

        <h3 class="page-sub-title mt-4">
          {{ t(tournament.description) }}
        </h3>
      </div>
      <IconBack class="back-icon" @click="$emit('close')" />
    </div>

    <div class="text-center mt-2 mb-2">
      {{
        `${t('tournamentTab.tournaments.attemptsLeftForToday')} ${attemptsLeft}/${tournament.attemptsMax}`
      }}
    </div>

    <template v-if="!isDayTournament">
      <div class="text-center mt-2 mb-2" v-if="isRegistration">
        <div class="timer-title">
          {{ t('tournamentTab.tournaments.registrationEndsIn') }}
        </div>
      </div>
      <div class="text-center mt-2 mb-2" v-else-if="isInProgress">
        <div class="timer-title">
          {{ t('tournamentTab.tournaments.tournamentEndsIn') }}
        </div>
      </div>
      <div class="text-center mt-2 mb-2" v-else-if="isResults">
        <div class="timer-title">
          Results
          <!-- {{ t('tournamentTab.tournaments.tournamentEndsIn') }} -->
        </div>
      </div>
    </template>

    <CardWithBgGradient v-if="!isDayTournament" class="timer-box" style="min-height: 68px">
      <EventTimer
        :end-date="currentEndDate"
        :index="tournament.id"
        :size="60"
        hide-letters
        hide-days
        hide-hours
        dividerSymbol=" : "
        :color-class="isRegistration ? 'gradient-text_red' : 'gradient-text'"
        @finished="renewRounds"
      />
    </CardWithBgGradient>

    <div class="mt-7">
      <CategoryGroupWidget
        :rounds="roundTickers"
        :is-in-progress="isInProgress || isResults"
        @select-round="selectTournament($event)"
      />
    </div>

    <FullWindowModal v-if="selectedCurrencyRoundX">
      <TournamentBet1
        :currencyToPair="currencyToPair"
        :currentEndDate="currentEndDate"
        :categoryTitle="tournament.title"
        :is-registration="isRegistration"
        :is-in-progress="isInProgress"
        :is-results="isResults"
        :is-day-tournament="isDayTournament"
        @close="selectedCurrencyRoundX = null"
      />
    </FullWindowModal>
  </div>
</template>

<style scoped>
.timer-title {
  font-size: 0.8rem;
}
.back-icon {
  color: #5c7b3d;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.timer-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import IconStar from '../icons/IconStar.vue'
import IconTicketStar from '../icons/IconTicketStar.vue'

defineProps<{ price: number }>()

const lineRef = ref<any>(null)
const lineWidth = ref(0)

const onResize = () => {
  lineWidth.value = lineRef.value?.clientWidth || 400
}

onMounted(() => {
  lineWidth.value = lineRef.value?.clientWidth || 400
  window.addEventListener('resize', onResize)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

const currentValue = computed(() => {
  return 20
})
</script>

<template>
  <div class="">
    <div class="line" ref="lineRef">
      <div class="current-line" :style="{ width: `${currentValue}%` }"></div>
      <div class="current" :style="{ left: `${currentValue}%` }">
        <IconTicketStar style="color: #e1dc64; width: 24px; height: 24px" />
      </div>
      <div class="price">
        <IconStar style="width: 15px; height: 15px; color: #8d8a38" />
        {{ price }}
      </div>
    </div>
    <div class="text">Free ticket</div>
  </div>
</template>

<style scoped>
.line {
  width: 100%;
  height: 14px;
  background: #232319;
  border-radius: 30px;
  border: none;
  position: relative;
}
.current-line {
  height: 14px;
  position: absolute;
  border-radius: 30px;
  border: none;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(90deg, rgba(247, 242, 112, 1) 0%, rgba(0, 0, 0, 1) 100%);
}
.current,
.price {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
}
.current {
  width: 48px;
  height: 48px;
  background: #656334;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.price {
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5%;
  color: #8d8a38;
  transform: translateY(-50%);
  background: var(--white);
  right: 0;
  z-index: 3;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.text {
  margin-top: 22px;
  color: #797c6f;
  text-align: end;
}
</style>

<template>
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4382 44.9166C35.714 44.9166 44.8548 35.7757 44.8548 24.4999C44.8548 13.2241 35.714 4.08325 24.4382 4.08325C13.1623 4.08325 4.02148 13.2241 4.02148 24.4999C4.02148 35.7757 13.1623 44.9166 24.4382 44.9166Z"
      fill="#F3FFE9"
    />
    <path
      opacity="0.4"
      d="M31 33.5H30V33.25C30 32.15 29.1 31.25 28 31.25H25.75V28.96C25.5 28.99 25.25 29 25 29C24.75 29 24.5 28.99 24.25 28.96V31.25H22C20.9 31.25 20 32.15 20 33.25V33.5H19C18.59 33.5 18.25 33.84 18.25 34.25C18.25 34.66 18.59 35 19 35H31C31.41 35 31.75 34.66 31.75 34.25C31.75 33.84 31.41 33.5 31 33.5Z"
    />
    <path
      opacity="0.4"
      d="M18.5203 24.64C17.8603 24.39 17.2803 23.98 16.8203 23.52C15.8903 22.49 15.2803 21.26 15.2803 19.82C15.2803 18.38 16.4103 17.25 17.8503 17.25H18.4103C18.1503 17.78 18.0003 18.37 18.0003 19V22C18.0003 22.94 18.1803 23.83 18.5203 24.64Z"
    />
    <path
      opacity="0.4"
      d="M34.7205 19.82C34.7205 21.26 34.1105 22.49 33.1805 23.52C32.7205 23.98 32.1405 24.39 31.4805 24.64C31.8205 23.83 32.0005 22.94 32.0005 22V19C32.0005 18.37 31.8505 17.78 31.5905 17.25H32.1505C33.5905 17.25 34.7205 18.38 34.7205 19.82Z"
    />
    <path
      d="M28 15H22C19.79 15 18 16.79 18 19V22C18 25.87 21.13 29 25 29C28.87 29 32 25.87 32 22V19C32 16.79 30.21 15 28 15ZM27.84 21.45L27.22 22.21C27.12 22.32 27.05 22.54 27.06 22.69L27.12 23.67C27.16 24.27 26.73 24.58 26.17 24.36L25.26 24C25.12 23.95 24.88 23.95 24.74 24L23.83 24.36C23.27 24.58 22.84 24.27 22.88 23.67L22.94 22.69C22.95 22.54 22.88 22.32 22.78 22.21L22.16 21.45C21.77 20.99 21.94 20.48 22.52 20.33L23.47 20.09C23.62 20.05 23.8 19.91 23.88 19.78L24.41 18.96C24.74 18.45 25.26 18.45 25.59 18.96L26.12 19.78C26.2 19.91 26.38 20.05 26.53 20.09L27.48 20.33C28.06 20.48 28.23 20.99 27.84 21.45Z"
    />
    <path
      opacity="0.4"
      d="M27.8397 21.4499L27.2197 22.2099C27.1197 22.3199 27.0497 22.5399 27.0597 22.6899L27.1197 23.6699C27.1597 24.2699 26.7297 24.5799 26.1697 24.3599L25.2597 23.9999C25.1197 23.9499 24.8797 23.9499 24.7397 23.9999L23.8297 24.3599C23.2697 24.5799 22.8397 24.2699 22.8797 23.6699L22.9397 22.6899C22.9497 22.5399 22.8797 22.3199 22.7797 22.2099L22.1597 21.4499C21.7697 20.9899 21.9397 20.4799 22.5197 20.3299L23.4697 20.0899C23.6197 20.0499 23.7997 19.9099 23.8797 19.7799L24.4097 18.9599C24.7397 18.4499 25.2597 18.4499 25.5897 18.9599L26.1197 19.7799C26.1997 19.9099 26.3797 20.0499 26.5297 20.0899L27.4797 20.3299C28.0597 20.4799 28.2297 20.9899 27.8397 21.4499Z"
    />
  </svg>
</template>

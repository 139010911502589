<script setup lang="ts">
import { computed } from 'vue'
import FullWindowModal from './common/FullWindowModal.vue'
import IconBalance from './icons/IconBalance.vue'

// const { t } = useI18n()

const props = defineProps<{ progress: number }>()

const styles = computed(() => ({
  'animation-duration': `${props.progress * 0.001}s`
}))
</script>
<template>
  <FullWindowModal>
    <div class="loader">
      <!-- <img src="/img/preload/bull_bg.png" class="ofi-a" /> -->
      <!-- <img src="/img/preload/bull.png" class="bull-img" /> -->
      <!-- <div class="text flex justify-between items-center">
        <div class="text-bold">{{ t('common.loading') }}...</div>
        <div class="text-bold">&nbsp;</div>
      </div> -->
      <img src="/img/preload/ellipse.png" class="ellipse" />
      <img src="/img/preload/bull_space_v2.jpeg" class="ofi space-bull" />
      <div class="progress" :style="styles"></div>
      <div class="name-text">
        <IconBalance style="width: 46px; height: 46px" />
        <div class="text-bold">GDF Trade</div>
      </div>
    </div>
  </FullWindowModal>
</template>
<style scoped>
.loader {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}
.space-bull {
  object-fit: cover;
}
.progress {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 3px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  /* background: linear-gradient(#b2ff6b 0%, #fdff6b 100%); */
  background: rgb(90, 81, 210);
  background: linear-gradient(
    90deg,
    rgba(90, 81, 210, 1) 0%,
    rgba(178, 132, 222, 1) 50%,
    rgba(116, 201, 142, 1) 100%
  );
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  animation-delay: 0s;

  /* 5a51d2
  b284de
  74c98e */
}
/* .bull-img {
  position: absolute;
  z-index: 5;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: fill;
} */
/* .text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  padding: 80px 24px 50px;
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.4300000071525574px;
} */
.name-text {
  position: absolute;
  z-index: 8;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.4300000071525574px;
}
.ellipse {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  z-index: 7;
  object-fit: cover;
}
@keyframes grow {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>

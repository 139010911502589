<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import Avatar from './common/Avatar.vue'
import ProgressCircular from './common/ProgressCircular.vue'
import IconHelp from './icons/IconHelp.vue'

defineEmits(['showRankHandler'])

const { user } = storeToRefs(useAuthStore())

const router = useRouter()
</script>

<template>
  <div class="box flex justify-between items-center" v-bind="$attrs">
    <div class="flex items-center">
      <Avatar :avatar="user?.profile_image_url" :name="user?.name" />
      <div class="user-name">{{ user?.name || '' }}</div>
    </div>

    <div class="flex items-center gap-3">
      <ProgressCircular class="progress-bar" @click="$emit('showRankHandler')" />
      <IconHelp
        class="text-primary"
        style="width: 26px"
        @click="router.push({ name: 'meet-slide' })"
      />
    </div>
  </div>
</template>

<style scoped>
.user-name {
  color: var(--white);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
  margin-left: 14px;
}
</style>

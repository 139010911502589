<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import RaffleAmount from './RaffleAmount.vue'

const { t } = useI18n()

const won = computed(() => false)
</script>

<template>
  <div class="">
    <div class="text-center title">
      <template v-if="won">
        <span class="text-yellow text-bold">Congrats!</span> You won:
      </template>
      <div v-else class="page-title yellow looseTitle">
        {{ t('raffleTab.youLoose') }}
      </div>
    </div>

    <RaffleAmount v-if="won" :value="100" :show-title="false" />

    <!-- :users="[ { id: 1, name: 'afadfad', balance: 4324, profit: 24, profile_image_url: '' }, { id: 2,
    name: 'fvdtbetbe', balance: 5343, profit: 40, profile_image_url: '' } ]" listTitle="Other
    winners" -->

    <!-- UsersList  -->

    <template v-if="won">
      <p class="share-text mt-4">{{ t('raffleTab.shareResults') }}</p>

      <!-- <ActionBtn class="mt-4" yellow text="claim" /> -->
    </template>
  </div>
</template>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;
}
.share-text {
  color: #fff;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.looseTitle {
  margin-top: 160px;
  margin-bottom: 160px;
}
</style>

<template>
  <img src="/img/balance.png" class="img" />
</template>

<style scoped>
.img {
  width: 48px;
  height: 48px;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import EventTimer from './EventTimer.vue'

const ends_date = ref(new Date('2024-12-02T23:59:59+00:00'))
const discounts_ends_at = ref(ends_date.value.getTime())

const { t } = useI18n()

const isLimitedOffer = computed(() => Date.now() < discounts_ends_at.value)
</script>

<template>
  <div class="special-offer">
    <img src="/img/passive/discount.webp" class="ofi-a" />
    <div class="special-offer-text">
      <template v-if="isLimitedOffer">
        <div class="text-bold">
          {{ t('passiveIncome.specialOffer1') }}
        </div>
        <EventTimer
          :end-date="ends_date"
          color="#ffffff"
          hide-days
          hide-letters
          divider-symbol=" : "
          :size="14"
        />
      </template>
      <template v-else>
        {{ t('passiveIncome.specialOffer') }}
      </template>
    </div>
  </div>
</template>

<style scoped>
.special-offer {
  width: 100%;
  height: 40px;
  /* align-self: stretch; */
  /* border-radius: 12px; */
  /* border: 1px solid transparent; */
  /* background: linear-gradient(276deg, #fe5258 19.52%, #b4ff72 207.4%); */
  /* margin-top: 27px; */
  /* margin-bottom: 27px; */
  position: relative;
  /* box-sizing: border-box; */
  /* background-clip: padding-box; */
}
.special-offer-text {
  position: absolute;
  inset: 0;
  z-index: 2;
  padding: 0px 6px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: -0.05px;
}
/* .special-offer:before { */
/* content: ''; */
/* position: absolute; */
/* top: 0; */
/* right: 0; */
/* bottom: 0; */
/* left: 0; */
/* z-index: -1; */
/* margin: -1px;  */
/* border-radius: inherit;  */
/* background: linear-gradient(to right, #fe5258 19.52%, #b4ff72 207.4%); */
/* } */
@media (max-width: 400px) {
  .special-offer-text {
    padding: 0px 10px;
    gap: 8px;
    letter-spacing: normal;
  }
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <div class="section-title mt-8">{{ t('eventTab.prizeTable.header') }}:</div>
    <ul style="padding-left: 30px">
      <li>{{ t('eventTab.prizeTable.firstRow') }};</li>
      <li>{{ t('eventTab.prizeTable.secondRow') }};</li>
      <li>{{ t('eventTab.prizeTable.thirdRow') }};</li>
      <li>{{ t('eventTab.prizeTable.fourthRow') }};</li>
      <li>{{ t('eventTab.prizeTable.fifthRow') }};</li>
      <li>{{ t('eventTab.prizeTable.sixthRow') }};</li>
      <li>{{ t('eventTab.prizeTable.seventhRow') }};</li>
      <li>{{ t('eventTab.prizeTable.eighthRow') }}.</li>
    </ul>
  </div>
</template>

<style scoped>
/* remove bullets */
ul {
  list-style-type: none;
}
</style>

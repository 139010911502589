<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{ opacity?: number }>(), { opacity: 1 })

const styles = computed(() => ({
  background: props.opacity ? `rgba(13, 14, 12,${props.opacity})` : '#0d0e0c'
}))
</script>

<template>
  <div class="screen" :style="styles">
    <slot />
  </div>
</template>

<style scoped>
.screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
</style>

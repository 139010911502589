import {
  completeTask as apiCompleteTask,
  getUserCompletedTaskIds,
  getUserTasks,
  getJoinChannelTasks
} from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useUserBalanceStore } from './userBalance'

export type Task = App.DTO.Task.TaskData

export const useUserTasksStore = defineStore('userTasks', () => {
  const tasks = ref<Task[]>([])

  const completedTasks = ref<number[]>([])

  const isTaskIsCompletedById = computed(
    () => (taskId: number) => completedTasks.value.includes(taskId)
  )

  const joinChannelTasks = ref<number[]>([])

  const useUserBalance = useUserBalanceStore()

  async function getCompletedTaskIds() {
    completedTasks.value = await getUserCompletedTaskIds()
  }

  async function getTasks() {
    try {
      tasks.value = await getUserTasks()
    } catch (err) {
      //
    }
  }

  async function completeTask(taskId: number, payload: any = null) {
    const result = await apiCompleteTask(taskId, payload)
    Promise.all([getTasks(), getCompletedTaskIds(), useUserBalance.getBalance()])

    return result
  }

  async function fetchJoinChannelTasks() {
     joinChannelTasks.value = await getJoinChannelTasks()
  }

  const hasNewTask = computed(() => tasks.value.some((task) => !task.completed))

  return {
    tasks,
    getTasks,
    completeTask,
    hasNewTask,
    getCompletedTaskIds,
    isTaskIsCompletedById,
    fetchJoinChannelTasks,
    joinChannelTasks
  }
})

<script setup lang="ts">
import { useGlobalIntervalStore } from '@/stores/globalInterval'
import type { TournamentCurrencyRound } from '@/stores/tournament'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import EventTimer from '../common/EventTimer.vue'
import RoundWidgetV1 from './RoundWidgetV1.vue'

interface Props {
  rounds: TournamentCurrencyRound[]
  hideTimer?: boolean
}

const props = withDefaults(defineProps<Props>(), { hideTimer: false })
defineEmits(['select-round', 'refresh'])

const now = ref(new Date())

const { t } = useI18n()
const { tick } = storeToRefs(useGlobalIntervalStore())

const group = computed(() =>
  props.rounds?.length
    ? props.rounds[0]
    : {
        registration_starts_at: new Date(),
        started_at: new Date(),
        ended_at: new Date(),
        id: 1
      }
)

const isPreRegistration = computed(() => now.value < group.value.registration_starts_at)
const isRegistration = computed(
  () => group.value.registration_starts_at <= now.value && now.value < group.value.started_at
)
const isInProgress = computed(
  () => group.value.started_at <= now.value && now.value < group.value.ended_at
)
const isEnded = computed(() => group.value.ended_at < now.value)

const currentEndDate = computed(() => {
  if (isRegistration.value) return group.value.started_at
  if (isInProgress.value) return group.value.ended_at
  return group.value.registration_starts_at
})

watch(tick, () => {
  now.value = new Date()
})
</script>

<template>
  <CardWithBgGradient class="mt-4">
    <div class="w-100">
      <div v-if="!hideTimer" class="flex justify-between items-center mb-4">
        <div class="">
          <template v-if="isPreRegistration">
            <div class="timer-title">
              {{ t('tournamentTab.tournaments.registrationStartsIn') }}
            </div>
          </template>
          <template v-else-if="isRegistration">
            <div class="timer-title">
              {{ t('tournamentTab.tournaments.registrationEndsIn') }}
            </div>
          </template>
          <template v-else-if="isInProgress">
            <div class="timer-title">
              {{ t('tournamentTab.tournaments.tournamentEndsIn') }}
            </div>
          </template>
          <template v-else-if="isEnded">
            <div class="timer-title"></div>
          </template>
        </div>
        <EventTimer
          :end-date="currentEndDate"
          :size="24"
          hide-letters
          hide-days
          dividerSymbol=" : "
          :color-class="isRegistration ? 'gradient-text_red' : 'gradient-text'"
          @finished="$emit('refresh')"
        />
      </div>

      <div class="rounds-list mt-2 mb-1">
        <RoundWidgetV1
          v-for="round in rounds"
          :key="round.id"
          :round="round"
          :isRegistration="isRegistration"
          :isInProgress="isInProgress"
          :isPreRegistration="isPreRegistration"
          @click.stop="$emit('select-round', round)"
        />
      </div>
    </div>
  </CardWithBgGradient>
</template>

<style scoped>
.rounds-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 40px;
}
</style>

<script setup lang="ts">
import { useTournamentStore } from '@/stores/tournament'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Avatar from '../common/Avatar.vue'
import ListItem from '../common/ListItem.vue'
import Loader from '../common/Loader.vue'
import UsersListPositionIndex from '../common/UsersListPositionIndex.vue'
import UsersPositionInResultsWidget from '../common/UsersPositionInResultsWidget.vue'

const gettingRatings = ref(false)

const { t } = useI18n()

const tournamentStore = useTournamentStore()
const { getRatings } = tournamentStore
const { tournamentRating } = storeToRefs(tournamentStore)

const showCurrentUserRank = computed(
  () => false
  // (tournament_position || 0) > 100
)

onMounted(async () => {
  try {
    gettingRatings.value = true
    await getRatings()
  } catch (error) {
    //
  } finally {
    gettingRatings.value = false
  }
})
</script>

<template>
  <div class="">
    <h2 class="mt-7 page-title" v-html="t('tournamentTab.ratings.title')"></h2>

    <h3 class="page-sub-title mt-5">
      {{ t('tournamentTab.ratings.description') }}
    </h3>

    <div class="rating-list">
      <div v-if="gettingRatings" class="flex justify-center">
        <Loader />
      </div>
      <template v-else-if="tournamentRating.length">
        <ListItem v-for="(user, index) in tournamentRating" :key="user.userId">
          <div class="rating-list-item">
            <Avatar
              :avatar="user.profileImageURL"
              :name="user.userName"
              :size="40"
              style="flex-shrink: 0"
            />

            <div class="flex flex-col gap-1 rating-list-item-body">
              <div class="rating-list-item-title truncate">
                {{ user.userName }}
              </div>
              <div class="flex items-center rating-list-item-tournaments-count">
                {{ t('tournamentTab.ratings.score', { score: user.totalScore }) }}
              </div>
              <div class="flex items-center rating-list-item-win-rate">
                {{ t('tournamentTab.ratings.winRate') }}
                {{ user.userWinRate }} %
              </div>
            </div>

            <UsersListPositionIndex :index="index" />
          </div>
        </ListItem>
      </template>
      <ListItem v-else> {{ t('tournamentTab.ratings.noRatingsAvailable') }}</ListItem>
    </div>

    <!-- user position  -->
    <Teleport to="body" v-if="showCurrentUserRank">
      <UsersPositionInResultsWidget>
        <ListItem>
          <div class="rating-list-item">
            <Avatar
              :avatar="tournamentRating[0].profileImageURL"
              :name="tournamentRating[0].userName"
              :size="40"
              style="flex-shrink: 0"
            />

            <div class="flex flex-col gap-1 rating-list-item-body">
              <div class="rating-list-item-title truncate">
                {{ tournamentRating[0].userName }}
              </div>
              <div class="flex items-center rating-list-item-tournaments-count">
                {{ t('tournamentTab.ratings.score', { score: tournamentRating[0].totalScore }) }}
              </div>
              <div class="flex items-center rating-list-item-win-rate">
                {{ t('tournamentTab.ratings.winRate') }}
                {{ tournamentRating[0].userWinRate }} %
              </div>
            </div>

            <UsersListPositionIndex :index="4" />
          </div>
        </ListItem>
      </UsersPositionInResultsWidget>
    </Teleport>
  </div>
</template>

<style scoped>
.rating-list {
  margin-top: 32px;
}
.rating-list > div {
  margin: 10px 0;
}
.rating-list-item,
.rating-list-item-name,
.rating-list-item-title,
.rating-list-item-subtitle,
.rating-list-item-ind,
.rating-list-item-body {
  overflow: hidden;
}
.rating-list-item-body {
  padding-left: 14px;
}
.rating-list-item {
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  display: grid;
  gap: 4px;
  grid-template-columns: 38px 1fr auto;
  align-items: center;
  color: var(--primary);
}
.rating-list-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
}
.rating-list-item-title {
  color: var(--white);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.07px;
}
.rating-list-item-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-mute);
  margin-left: 16px;
}
.rating-list-item-ind {
  text-align: center;
  font-weight: 700;
}
.rating-list-item-tournaments-count {
  color: var(--main, #b2ff6b);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06px;
}
.rating-list-item-win-rate {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>

<script setup lang="ts">
import { useUserClaimStore, type ReferralPayments } from '@/stores/userClaim'
import { useUserRankStore } from '@/stores/userRank'
import { amountFormat } from '@/utils/amountFormat'
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Avatar from '../common/Avatar.vue'
import ListItem from '../common/ListItem.vue'
import Loader from '../common/Loader.vue'
import IconUsd from '../icons/IconUsd.vue'

const scrollComponentRef = ref(null)
const scrollRef = ref<any>(null)
const userListRef = ref<any>(null)
const fetchingMore = ref(false)
const page = ref(1)
const lastPage = ref(1)
const perPage = ref(20)
const users = ref<any[]>([])
const usersCount = ref(0)
const loading = ref(false)

const { t } = useI18n()
const { rankNameById } = useUserRankStore()

const userClaimStore = useUserClaimStore()
const { getReferralPayments } = userClaimStore

const formatUsers = (users: ReferralPayments[]) => {
  return users.map((referral) => ({
    id: referral.id,
    name: referral.user_name,
    balance: referral.balance,
    rank_id: referral.rank_id,
    profit: referral.total_payed
  }))
}

const handleScroll = async () => {
  const element = scrollComponentRef.value as any

  if (lastPage.value <= page.value) return
  if (element && element.getBoundingClientRect().bottom < window.innerHeight) {
    if (!fetchingMore.value) {
      try {
        fetchingMore.value = true
        page.value += 1
        const res = await getReferralPayments(page.value, perPage.value)

        if (res?.data) users.value.push(...formatUsers([...res.data]))
      } catch (error) {
        //
      } finally {
        fetchingMore.value = false
      }
    }
  }
}

onMounted(async () => {
  scrollRef.value = userListRef.value?.closest('.overflowOn') || window
  try {
    loading.value = true
    const res = await getReferralPayments(page.value, perPage.value)

    users.value = formatUsers(res?.data || [])
    lastPage.value = res?.meta?.last_page || 1
    usersCount.value = res?.meta?.total || 0
  } catch (error) {
    //
  } finally {
    loading.value = false
  }

  if (scrollRef.value) scrollRef.value.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  if (scrollRef.value) scrollRef.value.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div class="overflowOn">
    <section ref="userListRef" style="margin-bottom: 80px">
      <div class="users-list">
        <div class="section-title">{{ t('claimTab.usersFullList') }}</div>
        <div class="flex items-center gap-4">
          <div class="users-count">{{ usersCount }}</div>
        </div>
      </div>

      <div v-if="loading" class="flex justify-center">
        <Loader />
      </div>
      <template v-else>
        <div v-if="users.length > 0" class="list-container">
          <ListItem
            v-for="user in users"
            :key="user.id"
            :title="user.name"
            :subtitle="rankNameById(user.rank_id)"
            :price="amountFormat(user.balance)"
          >
            <template #prepend>
              <Avatar :avatar="user.profile_image_url" :name="user.name" :size="40" />
            </template>
            <template #append>
              <div v-if="user.profit" class="text-primary flex items-center gap-1">
                <template v-if="user.profit">
                  <IconUsd style="width: 26px; height: 26px" />
                  +{{ amountFormat(user.profit) }}
                </template>
              </div>
            </template>
            <template #subtitleIcon>
              <IconUsd style="width: 20px; height: 20px" />
            </template>
          </ListItem>
        </div>
        <div v-else>
          <ListItem> {{ t('claimTab.noPurchases') }} </ListItem>
        </div>

        <!-- pagination  -->
        <div v-if="fetchingMore" class="flex justify-center">
          <Loader />
        </div>
        <div class="" ref="scrollComponentRef"></div>
      </template>
    </section>
  </div>
</template>

<style scoped>
.users-list {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.users-count {
  color: var(--app-gray);
  font-size: 14px;
}
</style>

<template>
  <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4391 45.6788C35.7149 45.6788 44.8558 36.5379 44.8558 25.2621C44.8558 13.9863 35.7149 4.84546 24.4391 4.84546C13.1633 4.84546 4.02246 13.9863 4.02246 25.2621C4.02246 36.5379 13.1633 45.6788 24.4391 45.6788Z"
      fill="#F3FFE9"
    />
    <path
      d="M24 16.6699V33.3299L23.2 34.2399C22.09 35.4999 21.18 35.1599 21.18 33.4799V26.2799H18.09C16.69 26.2799 16.3 25.4199 17.23 24.3699L24 16.6699Z"
      fill="#577B34"
    />
    <path
      opacity="0.4"
      d="M30.77 25.6299L24 33.3299V16.6699L24.8 15.7599C25.91 14.4999 26.82 14.8399 26.82 16.5199V23.7199H29.91C31.31 23.7199 31.7 24.5799 30.77 25.6299Z"
      fill="#577B34"
    />
  </svg>
</template>

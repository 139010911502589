<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useUserBalanceStore } from '@/stores/userBalance'
import { useUserRankStore } from '@/stores/userRank'
import { amountFormat } from '@/utils/amountFormat'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { bonuses } from '../mockData/mock'
import Avatar from './common/Avatar.vue'
import Container from './common/Container.vue'
import ListItem from './common/ListItem.vue'
import Loader from './common/Loader.vue'
import UsersListPositionIndex from './common/UsersListPositionIndex.vue'
import UsersPositionInResultsWidget from './common/UsersPositionInResultsWidget.vue'
import IconBalance from './icons/IconBalance.vue'
import IconCircleBg from './icons/IconCircleBg.vue'
import IconLeftNav from './icons/IconLeftNav.vue'
import IconRightNav from './icons/IconRightNav.vue'

defineEmits(['close'])

const { t } = useI18n()

const userRankStore = useUserRankStore()
const { getUsersByRank, getCurrentUserRank } = userRankStore
const { ranks, usersByRank, currentRankId, currentUserRank } = storeToRefs(userRankStore)

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)

const userBalanceStore = useUserBalanceStore()
const { balance } = storeToRefs(userBalanceStore)

const loading = ref(true)
const gettingUsers = ref(false)
const currentSlide = ref(currentRankId.value - 1)
const carousel = ref<any>(null)

const showCurrentUserRank = computed(
  () =>
    currentRankId.value == currentSlide.value + 1 &&
    (currentUserRank.value?.rank_position || 0) > 100
)

watch(currentSlide, async () => {
  try {
    gettingUsers.value = true
    await getUsersByRank(currentSlide.value + 1)
  } catch (error) {
    //
  } finally {
    gettingUsers.value = false
  }
})

onMounted(async () => {
  try {
    loading.value = true
    currentSlide.value = currentRankId.value - 1
    await Promise.allSettled([getUsersByRank(currentRankId.value), getCurrentUserRank()])
  } catch (error) {
    //
  } finally {
    loading.value = false
  }
})
const activeColor = (ind: number) => bonuses[ind]?.color || ''

const next = () => {
  carousel.value.next()
}
const prev = () => {
  carousel.value.prev()
}
</script>

<template>
  <Container>
    <h1 v-if="loading" class="p-4">Loading...</h1>
    <div v-show="!loading" class="">
      <!-- <div class="close"><div class="close-icon" @click="$emit('close')"></div></div> -->

      <div class="rank-slide-container">
        <Carousel ref="carousel" v-model="currentSlide" :items-to-show="1">
          <!-- @slide-start="changeSlide" -->
          <Slide
            v-for="(item, ind) in ranks"
            :key="item.id"
            :style="{ color: activeColor(ind) }"
            class=""
          >
            <div class="rank-slide">
              <div class="slide-img">
                <div
                  class="slide-shadow"
                  :style="{ 'box-shadow': `0px 0px 50px 90px var(--primary)` }"
                ></div>

                <IconCircleBg style="width: 182px; height: 182px" />
                <div class="slide-img-img">
                  <img :src="`/img/trophy/trophy_${item.id}.png`" alt="" />
                </div>
              </div>
              <div class="slide-info">
                <div class="title">{{ t(item.name) }}</div>
                <div class="subtitle">
                  <template v-if="ranks[item.id - 1]">
                    {{
                      `${t('ranking.from')} ${amountFormat(ranks[item.id - 1].minimum_credits || 0)}`
                    }}
                  </template>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <navigation />
          </template>
        </Carousel>

        <IconLeftNav
          class="slide-navigation-prev"
          style="width: 40px; height: 40px"
          @click="prev"
        />
        <IconRightNav
          class="slide-navigation-next"
          style="width: 40px; height: 40px"
          @click="next"
        />
      </div>

      <div class="rank-list" :class="{ additionalBottomPadding: showCurrentUserRank }">
        <div v-if="gettingUsers" class="flex justify-center">
          <Loader />
        </div>
        <template v-else-if="usersByRank?.length">
          <ListItem v-for="(user, ind) in usersByRank" :key="user.id">
            <div class="rank-list-item">
              <Avatar
                :avatar="user.profile_image_url"
                :name="user.name"
                :size="38"
                style="flex-shrink: 0"
              />
              <div class="flex flex-col gap-1 rank-list-item-body">
                <div class="rank-list-item-title truncate">
                  {{ user.name }}
                </div>
                <div class="flex items-center rank-list-item-balance gap-1">
                  <IconBalance style="width: 20px; height: 20px" />
                  {{ amountFormat(user.credits) }}
                </div>
              </div>
              <UsersListPositionIndex :index="ind" />
            </div>
          </ListItem>
        </template>
        <ListItem v-else> No rankings available </ListItem>
      </div>
    </div>

    <Teleport to="body" v-if="user && showCurrentUserRank">
      <UsersPositionInResultsWidget>
        <ListItem>
          <div class="rank-list-item">
            <Avatar
              :avatar="user.profile_image_url"
              :name="user.name"
              :size="38"
              style="flex-shrink: 0"
            />
            <div class="flex flex-col gap-1 rank-list-item-body">
              <div class="rank-list-item-title truncate">
                {{ user.name }}
              </div>
              <div class="flex items-center rank-list-item-balance">
                <IconBalance style="width: 20px; height: 20px" />
                {{ amountFormat(balance) }}
              </div>
            </div>
            <UsersListPositionIndex v-if="currentUserRank" :index="currentUserRank.rank_position" />
          </div>
        </ListItem>
      </UsersPositionInResultsWidget>
    </Teleport>
  </Container>
</template>

<style scoped>
.progress {
  position: absolute;
  bottom: 0;
  width: 80%;
  overflow: hidden;
}
.rank-list {
  margin-top: 28px;
}
.rank-list > div {
  margin: 10px 0;
}
.rank-slide-container {
  min-height: 100px;
  position: relative;
}
.rank-slide {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slide-img {
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.slide-img-img {
  position: absolute;
  max-width: 132px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slide-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.slide-info {
  color: var(--app-white);
  text-align: center;
}
.slide-info .title {
  color: var(--white, #ecf2e5);
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}
.slide-info .subtitle {
  margin-top: 10px;
  color: var(--gray, #868c80);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.ranking {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--app-background);
  z-index: 101;
  padding-bottom: 40px;
}
.close {
  display: flex;
  justify-content: end;
  padding: 4px 16px;
}
.close-icon {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.close-icon::after,
.close-icon::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background: var(--app-white);
}
.close-icon::after {
  transform: rotate(45deg);
}
.close-icon::before {
  transform: rotate(-45deg);
}
.rank-list-item,
.rank-list-item-title,
.rank-list-item-ind,
.rank-list-item-body {
  overflow: hidden;
}
.rank-list-item-body {
  padding-left: 14px;
}
.rank-list-item {
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  display: grid;
  gap: 4px;
  grid-template-columns: 38px 1fr auto;
  align-items: center;
  color: var(--primary);
}
.rank-list-item-title {
  color: var(--white);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.07px;
}
.rank-list-item-ind {
  text-align: center;
  font-weight: 700;
}
.rank-list-item-balance {
  flex-shrink: 0;
  color: var(--primary, #b2ff6b);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06px;
}
.slide-shadow {
  position: absolute;
  z-index: 0;
  width: 0px;
  height: 0px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -30%);
  border-radius: 100%;
  opacity: 0.3;
  background: transparent;
  /* box-shadow: 0px 0px 262px 146px v-bind(activeColor); */
  /* rgba(0, 0, 0, 0.55); */
}
.slide-navigation-prev,
.slide-navigation-next {
  position: absolute;
  z-index: 0;
  top: 40%;
  transform: translateY(-50%);
  color: var(--app-text-mute);
}
.slide-navigation-prev {
  left: 0px;
}
.slide-navigation-next {
  right: 0px;
}
.additionalBottomPadding {
  padding-bottom: 70px;
}
</style>

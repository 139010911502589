import { useApi } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserBalanceStore = defineStore('userBalance', () => {
  const balance = ref<number>(5000)

  async function getBalance() {
    try {
      balance.value = (await useApi().get('/api/credits'))?.data?.data?.credits || 0
    } catch (error) {
      console.error('Error getting balance', error)
    }
  }

  async function updateBalance(amount: number) {
    if (amount <= 0) {
      return
    }

    try {
      balance.value = (
        await useApi().patch('/api/credits', {
          credits: Math.round(amount)
        })
      ).data.data.credits
    } catch (error) {
      console.error('Error updating balance', error)
      throw error
    }
  }

  function updateBalanceLocally(amount: number) {
    balance.value += amount
  }

  return {
    balance,
    getBalance,
    updateBalance,
    updateBalanceLocally
  }
})

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import IconTicketStar from '../icons/IconTicketStar.vue'

withDefaults(defineProps<{ value: number; showTitle?: boolean }>(), { showTitle: true })

const { t } = useI18n()
</script>

<template>
  <div class="text-center">
    <div v-if="showTitle" class="ticket-title">{{ t('raffleTab.yourTickets') }}</div>

    <div class="text-yellow flex items-center justify-center">
      <div class="mr-1 glow">
        <IconTicketStar style="width: 72px; height: 72px" />
      </div>
      <div class="ticket-value">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.ticket-title {
  color: #a8ab48;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  margin-bottom: 14px;
}
.ticket-value {
  color: var(--yellow);
  font-family: 'Open Sans';
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.28px;
}
.glow {
  position: relative;
  height: 72px;
}
.glow::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 40px 19px var(--yellow);
}
</style>

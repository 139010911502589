import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function notify(text: string, messageType: MessageTypes = 'success') {
  useMessageStore().notify(text, messageType)
}

type MessageTypes = 'success' | 'error'

export const useMessageStore = defineStore('message', () => {
  const message = ref('')
  const type = ref<MessageTypes>('success')

  const { t } = useI18n()

  const resetStore = () => {
    message.value = ''
    type.value = `success`
  }

  function notify(text: string, messageType: MessageTypes = 'success') {
    message.value = t(text)
    type.value = messageType
  }

  return {
    message,
    type,
    resetStore,
    notify
  }
})

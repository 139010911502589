<script setup lang="ts">
import QRCodeVue3 from 'qrcode-vue3'

const name = import.meta.env.VITE_APP_BOT_NAME
const url = import.meta.env.VITE_APP_DIRECT_LINK
</script>

<template>
  <section class="desktop">
    <h1 class="title">Play on your mobile</h1>
    <!-- <img class="img" src="../assets/img/qr_code.jpg" /> -->
    <div class="img">
      <QRCodeVue3
        :value="url"
        :width="270"
        :height="270"
        :download="false"
        image="/img/telegram.svg"
        :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.2, margin: 4 }"
        :dotsOptions="{
          type: 'rounded',
          color: '#000000'
        }"
        :cornersSquareOptions="{ type: 'extra-rounded', color: '#000000' }"
        :backgroundOptions="{ color: '#ffffff' }"
      />
    </div>

    <!-- <QRCodeVue3
      
      fileExt="png"
      :download="true"
      myclass="my-qur"
      imgclass="img-qr"
      downloadButton="my-button"
      :downloadOptions="{ name: 'vqr', extension: 'png' }"
    /> -->

    <span :href="`${url}?startapp`" class="nik-name">{{ name }}</span>
    <div class="version">tdesktop 7.8</div>
  </section>
</template>

<style scoped>
.desktop {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
  background: black;
  color: white;
}
.title {
  font-size: 26px;
  font-weight: bold;
}
.img {
  margin-top: 24px;
  margin-bottom: 16px;
  width: 270px;
  height: 270px;
  /* max-width: 400px; */
  border-radius: 16px;
  overflow: hidden;
}
.nik-name {
  font-family: 'Open Sans';
  font-size: 18px;
  margin-bottom: 16px;
  color: white;
  font-weight: 600;
  word-break: break-all;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.nik-name:hover {
  background: transparent;
  /* opacity: 0.6; */
}
.version {
  font-size: 14px;
}
</style>

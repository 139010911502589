<script setup lang="ts">
import { useUserBalanceStore } from '@/stores/userBalance'
import { useUserRankStore } from '@/stores/userRank'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

interface Props {
  size?: number
  stroke?: number
}

const { size, stroke } = withDefaults(defineProps<Props>(), {
  size: 36,
  stroke: 5
})

const { currentRankId, currentRankLimit } = storeToRefs(useUserRankStore())
const { balance } = storeToRefs(useUserBalanceStore())

const progress = computed(() => (balance.value || 0) / (currentRankLimit.value || 1))

const center = computed(() => 0.5 * size)
const radius = computed(() => (size - stroke) * 0.5)
const circumference = computed(() => radius.value * Math.PI * 2)
const dash = computed(() => progress.value * circumference.value) // * 0.01)

const containerStyle = computed(() => ({
  width: `${size}px`,
  height: `${size}px`
}))

const progressStyle = computed(() => ({
  'transform-origin': 'center',
  transform: 'rotate(-90deg)',
  'stroke-dasharray': `${dash.value}  ${circumference.value - dash.value}`,
  'stroke-dashoffset': `-${stroke * 0.5}`,
  transition: `stroke-dasharray 0.3s linear 0s`,
  stroke: 'url(#GradientColor)' //'#5394fd'
}))
</script>

<template>
  <div class="containerBar" :style="containerStyle">
    <div
      class="inner"
      :style="{
        left: `${stroke}px`,
        right: `${stroke}px`,
        bottom: `${stroke}px`,
        top: `${stroke}px`
      }"
    >
      <!-- {{ currentRankId }}/{{ rankCount }} -->
      <img
        :src="`/img/trophy/trophy_${currentRankId}.png`"
        alt=""
        style="width: 18px; height: 18px"
      />
    </div>

    <svg :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`">
      <defs>
        <!-- <linearGradient id="GradientColor" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stop-color="#b1f770" />
          <stop offset="100%" stop-color="#12170D" />
        </linearGradient> -->

        <!-- <linearGradient id="GradientColor" x1="1" y1="0" x2="0" y2="1">
          <stop offset="0%" stop-color="#b1f770"></stop>
          <stop offset="100%" stop-color="#12170D"></stop>
        </linearGradient> -->

        <!-- gradientTransform="translate(17.4216 17.5) rotate(-90) scale(17.4216)" -->
        <!-- <radialGradient
          id="GradientColor"
          :cx="center"
          :cy="center"
          :r="radius"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stop-color="#B1F770" stop-opacity="0.86" />
          <stop offset="100%" stop-color="#12170D" />
        </radialGradient> -->

        <linearGradient id="Gradient1" gradientTransform="rotate(0)">
          <stop offset="0%" stop-color="#4f6d37" />
          <stop offset="100%" stop-color="#b1f770" />
        </linearGradient>
        <linearGradient id="Gradient2" gradientTransform="rotate(0)">
          <stop offset="0%" stop-color="#4f6d37" />
          <stop offset="100%" stop-color="#12170D" />
        </linearGradient>
        <pattern
          id="GradientColor"
          :x="0"
          :y="0"
          :width="size"
          :height="size"
          patternUnits="userSpaceOnUse"
        >
          <g :transform="`rotate(0, ${center}, ${center})`">
            <rect
              shape-rendering="crispEdges"
              :x="0"
              :y="0"
              :width="size"
              :height="center"
              fill="url(#Gradient1)"
            />
            <rect
              shape-rendering="crispEdges"
              :x="0"
              :y="center"
              :width="size"
              :height="size"
              fill="url(#Gradient2)"
            />
          </g>
        </pattern>
      </defs>
      <circle
        class="bg"
        :cx="center"
        :cy="center"
        :r="radius"
        fill="none"
        :stroke-width="stroke"
      ></circle>
      <circle
        class="value"
        :cx="center"
        :cy="center"
        :r="radius"
        fill="none"
        stroke-linecap="round"
        :stroke-width="stroke"
        :style="progressStyle"
      ></circle>
    </svg>
  </div>
</template>

<style scoped>
.containerBar {
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  color: #b1f770db;
  /* background: conic-gradient(#b1f770, #12170d); */
}
.inner {
  position: absolute;
  background: var(--bg-dark-green);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  color: var(--primary, #b1f770);
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.05px;
  border-radius: 100%;

  /* background: conic-gradient(from 0deg at 50% 50%, #b1f770, #97d361, #7db053, #658e45, #4f6d37, #394f29, #25321c, #12170d); */
  /* background: conic-gradient(from 0deg at 50% 50%, #12170d, #25321c, #394f29, #4f6d37, #658e45, #7db053, #97d361, #b1f770); */
}
.bg {
  stroke: var(--bg-dark-green);
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    class="size-6"
    width="24"
    height="24"
  >
    <path
      opacity="0.4"
      d="M22 9.21V14.79C22 18.89 20.75 20.14 16.65 20.14H9.91001V17.12C9.91001 16.74 9.59001 16.42 9.21001 16.42V7.58C9.59001 7.58 9.91001 7.26 9.91001 6.88V3.86H16.65C20.75 3.86 22 5.11 22 9.21Z"
    />
    <path
      d="M18.47 11.9C18.64 11.74 18.7 11.49 18.63 11.27C18.56 11.04 18.37 10.88 18.13 10.85L16.85 10.66C16.8 10.65 16.76 10.62 16.74 10.58L16.17 9.42C16.07 9.21 15.85 9.08 15.62 9.08C15.38 9.08 15.17 9.21 15.06 9.42L14.49 10.58C14.47 10.63 14.42 10.66 14.37 10.66L13.09 10.85C12.86 10.88 12.66 11.05 12.59 11.27C12.52 11.5 12.58 11.74 12.75 11.9L13.68 12.8C13.72 12.83 13.73 12.89 13.72 12.94L13.5 14.21C13.46 14.44 13.55 14.68 13.75 14.81C13.86 14.89 13.98 14.93 14.11 14.93C14.21 14.93 14.31 14.91 14.4 14.86L15.55 14.26C15.59 14.24 15.65 14.24 15.69 14.26L16.84 14.86C17.05 14.97 17.3 14.95 17.49 14.81C17.68 14.67 17.78 14.44 17.74 14.2L17.52 12.93C17.51 12.88 17.53 12.83 17.56 12.79L18.47 11.9Z"
    />
    <path
      d="M9.21 7.58V16.42C8.83 16.42 8.51 16.74 8.51 17.12V20.14H7.35C3.4 20.14 2.1 18.96 2.01 15.28C2 15.09 2.08 14.91 2.21 14.78C2.34 14.64 2.53 14.57 2.71 14.57C4.11 14.57 5.26 13.42 5.26 12.01C5.26 10.6 4.11 9.44 2.71 9.44C2.51 9.44 2.34 9.37 2.21 9.23C2.08 9.1 2 8.91 2.01 8.73C2.1 5.04 3.4 3.86 7.35 3.86H8.51V6.88C8.51 7.27 8.83 7.58 9.21 7.58Z"
    />
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
    width="40"
    height="40"
  >
    <path
      d="M17.0054 3.8791C18.5199 1.91733 21.4801 1.91733 22.9946 3.8791C23.9467 5.1125 25.5577 5.63594 27.053 5.19776C29.4313 4.50082 31.8262 6.24081 31.8983 8.71808C31.9436 10.2756 32.9393 11.646 34.4065 12.1704C36.7403 13.0045 37.655 15.8198 36.2573 17.8664C35.3785 19.1531 35.3785 20.8469 36.2573 22.1336C37.655 24.1802 36.7403 26.9955 34.4065 27.8296C32.9393 28.354 31.9436 29.7244 31.8983 31.2819C31.8262 33.7592 29.4313 35.4992 27.053 34.8022C25.5577 34.3641 23.9467 34.8875 22.9946 36.1209C21.4801 38.0827 18.5199 38.0827 17.0054 36.1209C16.0533 34.8875 14.4423 34.3641 12.947 34.8022C10.5687 35.4992 8.17382 33.7592 8.10172 31.2819C8.05639 29.7244 7.06074 28.354 5.59348 27.8296C3.25974 26.9955 2.34497 24.1802 3.74274 22.1336C4.62154 20.8469 4.62154 19.1531 3.74274 17.8664C2.34497 15.8198 3.25974 13.0045 5.59348 12.1704C7.06074 11.646 8.05639 10.2756 8.10172 8.71808C8.17382 6.24081 10.5687 4.50082 12.947 5.19776C14.4423 5.63594 16.0533 5.11249 17.0054 3.8791Z"
      fill="#B1F770"
    />
    <path
      d="M20.3037 19.355C20.3307 19.3199 20.3836 19.3199 20.4106 19.355C20.4276 19.377 20.4564 19.3863 20.4831 19.3785C20.5256 19.3661 20.5683 19.3971 20.5696 19.4414C20.5704 19.4692 20.5882 19.4937 20.6144 19.503C20.6561 19.5179 20.6724 19.5682 20.6475 19.6047C20.6318 19.6277 20.6318 19.658 20.6475 19.6809C20.6724 19.7175 20.6561 19.7678 20.6144 19.7827C20.5882 19.792 20.5704 19.8165 20.5696 19.8443C20.5683 19.8885 20.5256 19.9196 20.4831 19.9072C20.4564 19.8993 20.4276 19.9087 20.4106 19.9307C20.3836 19.9658 20.3307 19.9658 20.3037 19.9307C20.2867 19.9087 20.2579 19.8993 20.2312 19.9072C20.1887 19.9196 20.146 19.8885 20.1447 19.8443C20.1439 19.8165 20.1261 19.792 20.0999 19.7827C20.0582 19.7678 20.0419 19.7175 20.0668 19.6809C20.0825 19.658 20.0825 19.6277 20.0668 19.6047C20.0419 19.5682 20.0582 19.5179 20.0999 19.503C20.1261 19.4937 20.1439 19.4692 20.1447 19.4414C20.146 19.3971 20.1887 19.3661 20.2312 19.3785C20.2579 19.3863 20.2867 19.377 20.3037 19.355Z"
      fill="#8FB868"
    />
    <path
      opacity="0.4"
      d="M20.0001 28.3334C24.6025 28.3334 28.3334 24.6024 28.3334 20C28.3334 15.3976 24.6025 11.6667 20.0001 11.6667C15.3977 11.6667 11.6667 15.3976 11.6667 20C11.6667 24.6024 15.3977 28.3334 20.0001 28.3334Z"
      fill="#292D32"
    />
    <path
      d="M23.9666 19.9416C23.6166 19.8833 23.3083 20.1333 23.2583 20.475C23.1583 21.2 22.8333 21.85 22.3333 22.35C21.1833 23.5 19.4 23.6167 18.1083 22.725H18.7333C19.075 22.725 19.3583 22.4417 19.3583 22.1C19.3583 21.7583 19.075 21.475 18.7333 21.475H16.5083C16.1666 21.475 15.8833 21.7583 15.8833 22.1V24.325C15.8833 24.6666 16.1666 24.95 16.5083 24.95C16.85 24.95 17.1333 24.6666 17.1333 24.325V23.5416C17.9666 24.2166 18.975 24.575 19.9916 24.575C21.1583 24.575 22.325 24.1333 23.2166 23.2417C23.9083 22.55 24.35 21.6583 24.4916 20.6583C24.55 20.3 24.3083 19.9833 23.9666 19.9416Z"
      fill="#292D32"
    />
    <path
      d="M16.1166 20.0667C16.4249 20.0667 16.6916 19.8417 16.7333 19.5333C16.8333 18.8083 17.1583 18.1583 17.6583 17.6583C18.8083 16.5083 20.5916 16.3917 21.8833 17.2833H21.2583C20.9166 17.2833 20.6333 17.5667 20.6333 17.9083C20.6333 18.25 20.9166 18.5333 21.2583 18.5333H23.4833C23.8249 18.5333 24.1083 18.25 24.1083 17.9083V15.6833C24.1083 15.3417 23.8249 15.0583 23.4833 15.0583C23.1416 15.0583 22.8583 15.3417 22.8583 15.6833V16.4667C21.0666 15.0167 18.4416 15.1083 16.7833 16.7667C16.0916 17.4583 15.6499 18.35 15.5083 19.35C15.4583 19.6917 15.6999 20.0083 16.0416 20.0583C16.0583 20.0667 16.0916 20.0667 16.1166 20.0667Z"
      fill="#292D32"
    />
  </svg>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="coming-soon">
    <div class="coming-soon-text">{{ t('common.comingSoon') }}...</div>
  </div>
</template>

<style scoped>
.coming-soon {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  top: 0;
  left: 0;
}
.coming-soon-text {
  font-size: 30px;
  font-weight: 700;
}
</style>

<script setup lang="ts">
import useReferral from '@/application/services/useReferral'
import { notify } from '@/stores/message'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionBtn from './common/ActionBtn.vue'
import BtnPrimary from './common/BtnPrimary.vue'
import Container from './common/Container.vue'
import DialogModal from './common/DialogModal.vue'
import TabsPanel from './common/TabsPanel.vue'
import IconCopy from './icons/IconCopy.vue'
import IconFriend from './icons/IconFriend.vue'
import ClaimCashback from './invite/ClaimCashback.vue'
import InviteFriends from './invite/InviteFriends.vue'

defineProps<{
  scrollRef: any
}>()

const TAB_INVITE = 'inviteTab.tabs.invite'
const TAB_CLAIM = 'inviteTab.tabs.claim'

const modalOpen = ref(false)
const currentTab = ref(TAB_INVITE)

const tabs = reactive([
  { id: 1, title: TAB_INVITE },
  { id: 2, title: TAB_CLAIM }
])

const { copyableLink, sharableLink } = useReferral()
const { t } = useI18n()

const copyToClipboard = () => {
  if (!navigator.clipboard) {
    notify('Async: Clipboard API not available', 'error')
    return
  }

  if (!copyableLink.value) {
    notify('Async: No link to copy', 'error')
    return
  }

  try {
    navigator.clipboard.writeText(copyableLink.value)
    modalOpen.value = false
    notify('Url copied successfully')
  } catch (error) {
    notify(`Async: Could not copy url: ${error}`, 'error')
  }
}
const inviteHandler = () => {
  if (!sharableLink.value) {
    notify('Async: No link to share', 'error')
    return
  }

  const link = document.createElement('a')

  link.href = sharableLink.value
  document.body.appendChild(link)
  link.click()
  link.remove()
}
</script>

<template>
  <Container class="referral-container">
    <TabsPanel class="my-7" :tabs="tabs" v-model:tab="currentTab" />

    <InviteFriends
      v-if="currentTab == TAB_INVITE"
      :scrollRef="scrollRef"
      @invite-friend="modalOpen = true"
    />
    <ClaimCashback v-if="currentTab == TAB_CLAIM" />
  </Container>

  <div class="refLink-container" v-if="!modalOpen">
    <Container>
      <BtnPrimary @click="modalOpen = true" block big>
        {{ t('inviteTab.inviteAFriendButton') }}
        <template #append-icon>
          <IconFriend style="width: 22px; height: 22px" />
        </template>
      </BtnPrimary>
    </Container>
  </div>
  <!-- <a v-if="refLink" :href="refLink" class="ref-link" v-motion="glowAnimate">Invite a friend</a>
      <button class="ref-copy" @click="copyToClipboard"><Icon name="copy" /></button> -->

  <DialogModal v-if="modalOpen" :title="t('inviteTab.inviteFriends')" @close="modalOpen = false">
    <ActionBtn class="mt-4" :text="t('inviteTab.inviteFriends')" @click="inviteHandler">
      <template #append-icon>
        <IconFriend style="width: 22px; height: 22px" />
      </template>
    </ActionBtn>
    <ActionBtn class="mt-4 ref-copy" :text="t('inviteTab.copyLink')" @click="copyToClipboard">
      <template #append-icon>
        <IconCopy class="text-black" style="width: 22px; height: 22px" />
      </template>
    </ActionBtn>
  </DialogModal>
</template>

<style scoped>
.refLink-container {
  position: fixed;
  z-index: 101;
  bottom: 108px;
  left: 0;
  right: 0;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import ComingSoon from './common/ComingSoon.vue'
import Container from './common/Container.vue'
import RaffleEnded from './raffle/RaffleEnded.vue'
import RaffleTickets from './raffle/RaffleTickets.vue'
import RaffleWinners from './raffle/RaffleWinners.vue'

type EventStatus = 'ticket' | 'ended' | 'winners'

const showTab = ref(import.meta.env.DEV)

const eventStatus = ref<EventStatus>('ticket')

const currentEvent = computed(() => {
  switch (eventStatus.value) {
    case 'ticket':
      return RaffleTickets
    case 'ended':
      return RaffleEnded
    case 'winners':
      return RaffleWinners

    default:
      return RaffleTickets
  }
})
</script>

<template>
  <Container v-if="showTab">
    <component :is="currentEvent" />
  </Container>
  <ComingSoon v-else />
</template>

<style scoped></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.38128 6.38128C6.72299 6.03957 7.27701 6.03957 7.61872 6.38128L14 12.7626L20.3813 6.38128C20.723 6.03957 21.277 6.03957 21.6187 6.38128C21.9604 6.72299 21.9604 7.27701 21.6187 7.61872L15.2374 14L21.6187 20.3813C21.9604 20.723 21.9604 21.277 21.6187 21.6187C21.277 21.9604 20.723 21.9604 20.3813 21.6187L14 15.2374L7.61872 21.6187C7.27701 21.9604 6.72299 21.9604 6.38128 21.6187C6.03957 21.277 6.03957 20.723 6.38128 20.3813L12.7626 14L6.38128 7.61872C6.03957 7.27701 6.03957 6.72299 6.38128 6.38128Z"
    />
  </svg>
</template>

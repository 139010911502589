<script setup lang="ts"></script>

<template>
  <div class="card">
    <slot />
  </div>
</template>

<style scoped>
.card {
  display: flex;
  color: white;
  padding: 12px 16px;
  max-width: 100%;
  overflow: hidden;
  border-radius: 22px;
  border: 1px solid rgba(177, 247, 112, 0.46);
  background: radial-gradient(
    103.53% 111.49% at -1.76% 81.25%,
    rgba(150, 207, 96, 0.38) 0%,
    #314222 98.5%
  );
}
</style>

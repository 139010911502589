export const TAB_HOME = 'tab.home'
export const TAB_INVITE = 'tab.invite'
export const TAB_BOOSTS = 'tab.boosts'
export const TAB_EARNS = 'tab.earns'
export const TAB_CLAIM = 'tab.claim'
export const TAB_RAFFLE = 'tab.raffle'
export const TAB_EVENT = 'tab.event'
export const TAB_TOURNAMENT = 'tab.tournament'
export const TAB_RANKING = 'ranking'
export const TAB_GAMES = 'games'

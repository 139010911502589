<script setup lang="ts">
import { ref } from 'vue'
import HowRaffleWorks from './HowRaffleWorks.vue'
import RaffleAmount from './RaffleAmount.vue'
// import RaffleEndTime from './RaffleEndTime.vue'

const ticketValue = ref(100)
</script>

<template>
  <div class="">
    <h2 class="mt-4 page-mid-title">
      Raffle is over. Check back later if you're eligible for rewards.
    </h2>

    <RaffleAmount class="mt-12" :value="ticketValue" />

    <!-- <RaffleEndTime class="mt-12" finishTime="1w 3d 16h 20m" done /> -->

    <HowRaffleWorks class="mt-4" />
  </div>
</template>

<style scoped></style>

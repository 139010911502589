export const amountFormat = (num: number | undefined) => {
  if (num == undefined) return ''
  return Math.abs(num) > 999999
    ? Number((Math.abs(num) / 1000000).toFixed(2)) + 'M'
    : Math.abs(num) > 999
      ? Number((Math.abs(num) / 1000).toFixed(2)) + 'k'
      : Number(Math.abs(num))
}

export const amountFormatWithSymbol = (num: number, symbol = ',') => {
  const numString = String(Math.round(num))
  const spacesCount = Math.ceil(numString.length / 3) - 1
  const reverseStrArr = numString.replace(' ', '').split('').reverse()
  for (let i = 0; i < spacesCount; i++) {
    reverseStrArr.splice(3 + i + 3 * i, 0, symbol)
  }

  return reverseStrArr.reverse().join('')
}

export const hashToShortString = (str: string, showLetters: number = 6) => {
  if (str.length <= showLetters * 2 + 3) return str
  const firstPart = str.slice(0, showLetters)
  const lastPart = str.slice(str.length - showLetters, str.length)
  return `${firstPart}...${lastPart}`
}

export const fixedDigitsAfterPoint = (num: number, showDigits: number = 3) => {
  const str = num.toFixed(showDigits)

  return str
}

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="text-center">
    <h2 class="title">{{ t('raffleTab.howRaffleWorks.title') }}</h2>
    <ul class="list">
      <li>{{ t('raffleTab.howRaffleWorks.option1') }}</li>
      <li>{{ t('raffleTab.howRaffleWorks.option2') }}</li>
      <li>{{ t('raffleTab.howRaffleWorks.option3') }}</li>
    </ul>
    <p class="text">
      {{ t('raffleTab.howRaffleWorks.description') }}
    </p>
  </div>
</template>

<style scoped>
.title {
  color: #fff;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}
.list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 52%;
  text-align: start;
}
.list li {
  position: relative;
  color: #fff;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 24px;
}
.list li:not(:last-child) {
  margin-bottom: 14px;
}
.list li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  background: var(--yellow);
}
.text {
  color: #fff;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px auto 0;
  max-width: 90%;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
    width="24"
    height="24"
  >
    <path
      d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.74999V2C8.74999 1.59 8.40999 1.25 7.99999 1.25C7.58999 1.25 7.24999 1.59 7.24999 2V3.56C4.54999 3.81 3.23999 5.42 3.03999 7.81C3.01999 8.1 3.25999 8.34 3.53999 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"
    />
    <path
      opacity="0.4"
      d="M21 10.84V12.58C21 13.19 20.46 13.66 19.86 13.56C19.58 13.52 19.29 13.49 19 13.49C15.97 13.49 13.5 15.96 13.5 18.99C13.5 19.45 13.68 20.09 13.87 20.67C14.09 21.32 13.61 21.99 12.92 21.99H8C4.5 21.99 3 19.99 3 16.99V10.83C3 10.28 3.45 9.82999 4 9.82999H20C20.55 9.83999 21 10.29 21 10.84Z"
    />
    <path
      d="M19 15C16.79 15 15 16.79 15 19C15 21.21 16.79 23 19 23C21.21 23 23 21.21 23 19C23 16.79 21.21 15 19 15ZM20.6 20.64C20.45 20.79 20.26 20.86 20.07 20.86C19.88 20.86 19.69 20.79 19.54 20.64L19.01 20.11L18.46 20.66C18.31 20.81 18.12 20.88 17.93 20.88C17.74 20.88 17.55 20.81 17.4 20.66C17.11 20.37 17.11 19.89 17.4 19.6L17.95 19.05L17.42 18.52C17.13 18.23 17.13 17.75 17.42 17.46C17.71 17.17 18.19 17.17 18.48 17.46L19.01 17.99L19.51 17.49C19.8 17.2 20.28 17.2 20.57 17.49C20.86 17.78 20.86 18.26 20.57 18.55L20.07 19.05L20.6 19.58C20.89 19.88 20.89 20.35 20.6 20.64Z"
    />
    <path
      d="M8.5 15C8.24 15 7.98 14.89 7.79 14.71C7.61 14.52 7.5 14.26 7.5 14C7.5 13.74 7.61 13.48 7.79 13.29C8.02 13.06 8.37 12.95 8.7 13.02C8.76 13.03 8.82 13.05 8.88 13.08C8.94 13.1 9 13.13 9.06 13.17C9.11 13.21 9.16 13.25 9.21 13.29C9.39 13.48 9.5 13.74 9.5 14C9.5 14.26 9.39 14.52 9.21 14.71C9.16 14.75 9.11 14.79 9.06 14.83C9 14.87 8.94 14.9 8.88 14.92C8.82 14.95 8.76 14.97 8.7 14.98C8.63 14.99 8.56 15 8.5 15Z"
    />
    <path
      d="M12 15C11.74 15 11.48 14.89 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.67 12.92 12.34 12.92 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71C12.52 14.89 12.26 15 12 15Z"
      fill="#B1F770"
    />
    <path
      d="M8.5 18.5C8.24 18.5 7.98 18.39 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 7.99 16.63 8.12 16.58C8.49 16.42 8.93 16.51 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21C9.02 18.39 8.76 18.5 8.5 18.5Z"
      fill="#B1F770"
    />
  </svg>
</template>

<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="32" height="32" rx="16" fill="currentColor" />
    <path
      d="M18.7402 23.1866L13.2357 17.682C12.5856 17.0319 12.5856 15.9681 13.2357 15.3181L18.7402 9.81348"
      stroke="#EAFFD7"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

import {
  getPredictionTournament,
  getPredictionTournamentCurrencies,
  getPredictionTournamentCurrencyRounds,
  getPredictionTournaments,
  getTournamentCategorySchedule,
  getTournamentRating,
  getTournamentsScore,
  registerForPredictionTournament,
  registerUserPredictionForCurrency,
  type PredictionPayload
} from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type TournamentRating = App.DTO.PredictionTournament.PredictionTournamentUsersRatingData

export type Tournament = App.DTO.PredictionTournament.PredictionTournamentData

export type TournamentCurrencyRound = Omit<
  App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData,
  'registration_starts_at' | 'started_at' | 'ended_at'
> & {
  registration_starts_at: Date
  started_at: Date
  ended_at: Date
}

export type CategorySchedule = {
  registration_ends_at: Date
  ends_at: Date
  results_ends_at: Date
}
export type RoundTickers = Omit<
  App.DTO.PredictionTournament.PredictionTournamentCurrencyData,
  'user_bid'
> & {
  user_bid: Bet | null
}

export type Bet = 'up' | 'down' | string

export const useTournamentStore = defineStore('tournament', () => {
  const tournamentRating = ref<TournamentRating[]>([])
  const tournamentCategories = ref<Tournament[]>([])
  const tournament = ref<App.DTO.PredictionTournament.PredictionTournamentData | null>(null)
  const tournamentCurrencyRounds = ref<TournamentCurrencyRound[]>([])
  const totalScoreByTournaments = ref<
    App.DTO.PredictionTournament.PredictionTournamentsScoreData[]
  >([])

  const selectedCategory = ref<Tournament | null>(null)
  const selectedCurrencyRound = ref<TournamentCurrencyRound | null>(null)

  const categorySchedule = ref<CategorySchedule[]>([])
  const roundTickers = ref<RoundTickers[]>([])
  const selectedCurrencyRoundX = ref<RoundTickers | null>(null)

  const tournamentCurrencyRoundsForSelectedCategory = computed(() =>
    tournamentCurrencyRounds.value.filter(
      (round) => round.tournament_id === selectedCategory.value?.id
    )
  )

  function registerForTournament(phaseId: number) {
    console.log('phaseId: ', phaseId)
  }

  async function getTournamentById(tournamentId: number) {
    try {
      tournament.value = await getPredictionTournament(tournamentId)
    } catch (e) {
      // tournament.value = {
      //   id: 1,
      //   title: 'tournamentTab.tournaments.categories.oneDay',
      //   description: 'tournamentTab.tournaments.categories.oneDayDescription',
      //   attemptsMax: 2,
      //   attemptsMade: 0,
      //   totalScore: 0
      // }
    }
  }

  const attemptsLeft = computed(
    () => (tournament.value?.attemptsMax || 0) - (tournament.value?.attemptsMade || 0)
  )

  async function getTournamentCategories() {
    try {
      tournamentCategories.value = await getPredictionTournaments()
    } catch (e) {
      // tournamentCategories.value = [
      //   {
      //     id: 1,
      //     title: 'tournamentTab.tournaments.categories.fiveMin',
      //     description: 'tournamentTab.tournaments.categories.fiveMinDescription',
      //     attemptsMax: 10,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   },
      //   {
      //     id: 2,
      //     title: 'tournamentTab.tournaments.categories.fifteenMin',
      //     description: 'tournamentTab.tournaments.categories.fifteenMinDescription',
      //     attemptsMax: 7,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   },
      //   {
      //     id: 3,
      //     title: 'tournamentTab.tournaments.categories.oneHour',
      //     description: 'tournamentTab.tournaments.categories.oneHourDescription',
      //     attemptsMax: 3,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   },
      //   {
      //     id: 4,
      //     title: 'tournamentTab.tournaments.categories.oneDay',
      //     description: 'tournamentTab.tournaments.categories.oneDayDescription',
      //     attemptsMax: 2,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   }
      // ]
    }
  }

  async function getTournamentCategoryById(tournamentId: number) {
    try {
      const result = await getPredictionTournamentCurrencyRounds(tournamentId)

      tournamentCurrencyRounds.value = (result || []).map((round) =>
        convertPredictionTournamentCurrencyRoundDataToTournamentCurrencyRound(round)
      )
    } catch (e) {
      // tournamentCurrencyRounds.value = [
      //   {
      //     id: 1605,
      //     tournament_id: 21,
      //     title: 'BTCUSDT',
      //     registration_starts_at: new Date('2024-11-22T12:05:00+00:00'),
      //     started_at: new Date('2024-11-22T12:05:00+00:00'),
      //     ended_at: new Date('2024-11-22T12:05:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'btc'
      //   }
      //   {
      //     id: 1606,
      //     tournament_id: 21,
      //     title: 'ETHUSDT',
      //     registration_starts_at: new Date('2024-11-22T12:05:00+00:00'),
      //     started_at: new Date('2024-11-22T12:05:00+00:00'),
      //     ended_at: new Date('2024-11-22T12:05:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'eth'
      //   },
      //   {
      //     id: 1607,
      //     tournament_id: 21,
      //     title: 'SOLUSDT',
      //     registration_starts_at: new Date('2024-11-22T12:05:00+00:00'),
      //     started_at: new Date('2024-11-22T12:05:00+00:00'),
      //     ended_at: new Date('2024-11-22T12:05:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'sol'
      //   },
      //   {
      //     id: 1608,
      //     tournament_id: 21,
      //     title: 'TONUSDT',
      //     registration_starts_at: new Date('2024-11-22T12:05:00+00:00'),
      //     started_at: new Date('2024-11-22T12:05:00+00:00'),
      //     ended_at: new Date('2024-11-22T12:05:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'ton'
      //   }
      // ]
      // console.log('e: ')
      // throw e
    }
  }

  function convertPredictionTournamentCurrencyRoundDataToTournamentCurrencyRound(
    data: App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData
  ): TournamentCurrencyRound {
    return {
      ...data,
      registration_starts_at: new Date(data.registration_starts_at),
      started_at: new Date(data.started_at),
      ended_at: new Date(data.ended_at)
    }
  }

  async function registerUserPredictionForRound(roundId: number, prediction: PredictionPayload) {
    await registerForPredictionTournament(roundId, prediction)
  }

  async function getRatings() {
    try {
      tournamentRating.value = await getTournamentRating()
    } catch (e) {
      // tournamentRating.value = [
      //   {
      //     userId: 1,
      //     profileImageURL: '',
      //     rankName: 'rank',
      //     totalScore: '4000',
      //     tournamentsCount: 200,
      //     userName: 'user-1',
      //     winsCount: 55
      //   }
      // ]
    }
  }

  function setRegistrationStatusForRound(
    roundId: number,
    registered: boolean,
    variant: 'up' | 'down'
  ) {
    const roundIndex = tournamentCurrencyRounds.value.findIndex((round) => round.id === roundId)

    if (roundIndex !== -1) {
      tournamentCurrencyRounds.value[roundIndex].is_registered = registered
      tournamentCurrencyRounds.value[roundIndex].user_bid = variant
      if (tournament.value) tournament.value.attemptsMade += 1
    }
    if (selectedCurrencyRound.value) {
      selectedCurrencyRound.value.is_registered = registered
      selectedCurrencyRound.value.user_bid = variant
      // if (tournament.value) tournament.value.attemptsMade += 1
    }
  }

  async function getHistory() {
    totalScoreByTournaments.value = await getTournamentsScore()
  }

  async function pushCurrencyRound(
    round: App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData
  ) {
    tournamentCurrencyRounds.value.unshift(
      convertPredictionTournamentCurrencyRoundDataToTournamentCurrencyRound(round)
    )
  }
  const removeEndedRounds = (rounds: CategorySchedule[]) => {
    const now = new Date()
    return rounds.filter((el) => el.results_ends_at > now)
  }

  const getCurrentCategorySchedule = async (categoryId: number) => {
    try {
      const response = await getTournamentCategorySchedule(categoryId)
      const schedule = response
        .map((round) => ({
          registration_ends_at: new Date(round.registration_ends_at),
          ends_at: new Date(round.ends_at),
          results_ends_at: new Date(round.results_ends_at)
        }))
        .sort(
          (a, b) =>
            new Date(a.registration_ends_at).getTime() - new Date(b.registration_ends_at).getTime()
        )
      categorySchedule.value = removeEndedRounds(schedule)
    } catch (error) {
      // let minutes = new Date().getMinutes()
      // let hours = new Date().getHours() - 2
      // const date = new Date().toISOString().slice(0, 10)
      // const rounds: CategorySchedule[] = []
      // const count = 200
      // const zerAtStart = (num: number) => {
      //   return String(num).padStart(2, '0')
      // }
      // for (let i = 0; i < count; i++) {
      //   if (minutes + i >= 60) {
      //     minutes = ((minutes + i) % 60) - i
      //     hours++
      //   }
      //   rounds.push({
      //     registration_ends_at: new Date(
      //       new Date(`${date}T${zerAtStart(hours)}:${zerAtStart(minutes + i)}:20+00:00`)
      //     ),
      //     ends_at: new Date(
      //       new Date(`${date}T${zerAtStart(hours)}:${zerAtStart(minutes + i)}:40+00:00`)
      //     ),
      //     results_ends_at: new Date(
      //       new Date(`${date}T${zerAtStart(hours)}:${zerAtStart(minutes + i)}:59.999+00:00`)
      //     )
      //   })
      // }
      // categorySchedule.value = removeEndedRounds(rounds)
      // console.log('categorySchedule: ', categorySchedule.value)
    }
  }

  const getRoundTickers = async (categoryId: number) => {
    try {
      roundTickers.value = await getPredictionTournamentCurrencies(categoryId)
    } catch {
      // roundTickers.value = [
      //   {
      //     id: 1605,
      //     tournament_id: categoryId,
      //     title: 'BTCUSDT',
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'btc'
      //   },
      //   {
      //     id: 1606,
      //     tournament_id: categoryId,
      //     title: 'ETHUSDT',
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'eth'
      //   },
      //   {
      //     id: 1607,
      //     tournament_id: categoryId,
      //     title: 'SOLUSDT',
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'sol'
      //   },
      //   {
      //     id: 1608,
      //     tournament_id: categoryId,
      //     title: 'TONUSDT',
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'ton'
      //   }
      // ]
    }
  }

  const removeRoundFromSchedule = () => {
    categorySchedule.value.shift()
    if (categorySchedule.value.length == 0 && selectedCategory.value) {
      getCurrentCategorySchedule(selectedCategory.value?.id)
    }
  }

  const resetTickers = () => {
    roundTickers.value = roundTickers.value.map((el) => ({
      ...el,
      is_registered: false,
      user_bid: null
    }))
    if (selectedCurrencyRound.value) {
      selectedCurrencyRound.value.is_registered = false
      selectedCurrencyRound.value.user_bid = null
    }
  }

  const setRegistrationStatusForRoundX = (id: number, is_registered: boolean, bet: Bet) => {
    registerUserPredictionForCurrency(id, bet).then(() => {
      const tickerToUpdate = roundTickers.value.find((el) => el.id == id)

      if (tickerToUpdate) {
        tickerToUpdate.is_registered = is_registered
        tickerToUpdate.user_bid = bet
      }
      if (selectedCurrencyRound.value) {
        selectedCurrencyRound.value.is_registered = is_registered
        selectedCurrencyRound.value.user_bid = bet
        if (tournament.value) tournament.value.attemptsMade += 1
      }
    })
  }

  return {
    tournamentRating,
    getRatings,
    tournament,
    registerForTournament,
    getTournamentCategoryById,
    tournamentCategories,
    getTournamentCategories,
    tournamentCurrencyRounds,
    getTournamentById,
    registerUserPredictionForRound,
    setRegistrationStatusForRound,
    getHistory,
    totalScoreByTournaments,
    attemptsLeft,
    selectedCategory,
    selectedCurrencyRound,
    pushCurrencyRound,
    getCurrentCategorySchedule,
    getRoundTickers,
    categorySchedule,
    roundTickers,
    selectedCurrencyRoundX,
    removeRoundFromSchedule,
    resetTickers,
    setRegistrationStatusForRoundX
  }
})

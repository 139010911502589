<template>
  <svg
    width="188"
    height="187"
    viewBox="0 0 188 187"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_409_2521)">
      <circle
        cx="93.8727"
        cy="90.8727"
        r="90.8727"
        fill="url(#paint0_linear_409_2521)"
        fill-opacity="0.32"
        shape-rendering="crispEdges"
      />
      <circle
        cx="93.8727"
        cy="90.8727"
        r="84.9782"
        stroke="url(#paint1_linear_409_2521)"
        stroke-width="11.7889"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_409_2521"
        x="0.493602"
        y="0"
        width="186.758"
        height="186.758"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.5064" />
        <feGaussianBlur stdDeviation="1.2532" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_409_2521" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_409_2521"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_409_2521"
        x1="93.8727"
        y1="131.818"
        x2="93.8727"
        y2="5.31875e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B2FF6B" />
        <stop offset="1" stop-color="#6B9940" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_409_2521"
        x1="93.8727"
        y1="243.824"
        x2="97.8352"
        y2="109.893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B2FF6B" />
        <stop offset="1" stop-color="#6B9940" stop-opacity="0.22" />
      </linearGradient>
    </defs>
  </svg>
</template>

export const bonuses = [
  { id: 1, level: 'ranking.stoneLeague', color: '#f99b3f', forFriend: 1250, premium: 2500 },
  { id: 2, level: 'ranking.bronzeLeague', color: '#f99b3f', forFriend: 12500, premium: 25000 },
  { id: 3, level: 'ranking.silverLeague', color: '#c0c0c0', forFriend: 125000, premium: 250000 },
  { id: 4, level: 'ranking.goldLeague', color: '#ffd700', forFriend: 250000, premium: 500000 },
  {
    id: 5,
    level: 'ranking.platinumLeague',
    color: '#E5E4E2',
    forFriend: 500000,
    premium: 1000000
  },
  {
    id: 6,
    level: 'ranking.diamondLeague',
    color: '#b9f2ff',
    forFriend: 1000000,
    premium: 2000000
  },
  {
    id: 7,
    level: 'ranking.cosmosLeague',
    color: '#8e72fc',
    forFriend: 1500000,
    premium: 3000000
  },
  {
    id: 8,
    level: 'ranking.iceLeague',
    color: '#fc7272',
    forFriend: 2500000,
    premium: 5000000
  }
]
export type Bonus = (typeof bonuses)[0]

export const ranks = [
  { id: 1, name: 'Stone League' },
  { id: 2, name: 'Bronze League' },
  { id: 3, name: 'Silver League' },
  { id: 4, name: 'Gold League' },
  { id: 5, name: 'Platinum League' },
  { id: 6, name: 'Diamond League' },
  { id: 7, name: 'Cosmos League' },
  { id: 8, name: 'Ice League' }
]

export const earns = [
  {
    id: 1,
    date: '20.07.2024',
    amount: 1,
    hash: 'UTDWGsNfgUj_IvD7u345vgzAkm8aQgnxbH61TsD10_i2K-S125XDW5'
  },
  {
    id: 2,
    date: '19.07.2024',
    amount: 99,
    hash: 'UTDWGsNfgUj_IvD7u345vgzAkm8aQgnxbH61TsD10_i2K-S125XDW5'
  }
]

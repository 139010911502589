import { energyBoostUpgrade, useApi } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

type TapEnergy = {
  current_energy: number
  energy_limit: number
}

export const useUserEnergyStore = defineStore('userEnergy', () => {
  type EnergyBoost = App.DTO.Energy.TapEnergyLevelData

  const currentEnergy = ref<number>(0)
  const currentEnergyLimit = ref<number>(0)
  const energyBoost = ref<EnergyBoost | null>(null)

  async function getCurrentEnergy() {
    const energy = (await useApi().get('/api/energy')).data.data as TapEnergy
    currentEnergyLimit.value = energy.energy_limit
    currentEnergy.value = energy.current_energy
  }

  async function getAvailableEnergyBoost() {
    try {
      energyBoost.value = {
        ...(await useApi().get('/api/energy-boost-upgrade')).data.data
      } as EnergyBoost
    } catch (err) {
      energyBoost.value = {
        energy_limit: 300,
        price: 2000
      }
    }
  }

  async function upgradeEnergyBoost() {
    try {
      await energyBoostUpgrade()
      await Promise.all([getAvailableEnergyBoost(), getCurrentEnergy()])
    } catch (e) {
      //
    }
  }

  return {
    currentEnergy,
    currentEnergyLimit,
    getCurrentEnergy,
    energyBoost,
    getAvailableEnergyBoost,
    upgradeEnergyBoost
  }
})

export const useUserTapPowerStore = defineStore('userTapPower', () => {
  type TapPowerBoost = App.DTO.TapPower.TapPowerLevelData

  const currentTapPower = ref<number>(1)
  const tapPowerBoost = ref<TapPowerBoost | null>(null)

  async function getCurrentTapPower() {
    const tapPower = (await useApi().get('/api/tap-power')).data.data as { tap_points: number }
    currentTapPower.value = tapPower.tap_points
  }

  async function getAvailableTapPowerBoost() {
    tapPowerBoost.value = {
      ...(await useApi().get('/api/tap-power-upgrade')).data.data
    } as TapPowerBoost
  }

  async function upgradeTapPowerBoost() {
    try {
      await useApi().post('/api/tap-power-upgrade')
      await Promise.all([getAvailableTapPowerBoost(), getCurrentTapPower()])
    } catch (e) {
      //
    }
  }

  return {
    currentTapPower,
    getCurrentTapPower,
    getAvailableTapPowerBoost,
    upgradeTapPowerBoost,
    tapPowerBoost
  }
})

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  avatar?: string | null
  name?: string
  size?: number
}

const { avatar, name, size } = defineProps<Props>()

const style = computed(() => {
  const styles = {} as any
  if (size) {
    styles['width'] = `${size}px`
    styles['height'] = `${size}px`
    styles['font-size'] = `${size * 0.45}px`
  }
  return styles
})

const nameLetter = computed(() => {
  if (name) {
    return name[0].toUpperCase() + name[1]
  }
  return ''
})
</script>

<template>
  <div class="avatar" :class="{ 'avatar-border': !avatar }" :style="style">
    <img v-if="avatar" v-lazy="avatar" alt="" class="avatar-img" />
    <template v-if="name && !avatar">
      {{ nameLetter }}
    </template>
  </div>
</template>

<style scoped>
.avatar {
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 100%;
  background-image: url('');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-weight: 700;
  font-size: 16px;
  color: var(--white);
  overflow: hidden;
}
.avatar-border {
  border: 1px solid;
  border-color: var(--white);
}
.avatar-img {
  max-width: 100%;
  width: 100%;
  object-fit: contain;
}
</style>

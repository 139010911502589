<script setup lang="ts"></script>

<template>
  <div class="container">
    <slot />
  </div>
</template>

<style>
.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 16px;
}
@media (min-width: 400px) {
  .container {
    padding: 0px 24px;
  }
}
</style>

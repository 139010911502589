<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M5.19751 11.62L9.00084 7.81664C9.45001 7.36747 9.45001 6.63247 9.00084 6.18331L5.19751 2.37997"
      stroke="currentColor"
      stroke-width="0.875"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts">
import { useTournamentStore } from '@/stores/tournament'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import ListItem from '../common/ListItem.vue'
import IconCupSolid from '../icons/IconCupSolid.vue'

const { t } = useI18n()

const tournaments = useTournamentStore()

const { totalScoreByTournaments } = storeToRefs(tournaments)

onMounted(() => {
  tournaments.getHistory()
})
</script>

<template>
  <div class="">
    <div style="max-width: 80%" class="ma-auto">
      <h2 class="page-title">{{ t('tournamentTab.history.title') }}!</h2>
      <h3 class="page-sub-title mt-5">{{ t('tournamentTab.history.description') }}</h3>
    </div>

    <div v-if="totalScoreByTournaments?.length > 0" class="list-container mt-10">
      <ListItem v-for="item in totalScoreByTournaments" :key="item.id" :title="t(item.title)">
        <template #subtitle>
          <div class="history-subtitle">
            {{ `${t('tournamentTab.history.total')}: ${item.total_tournament_score}` }}
          </div>
        </template>
        <template #prepend>
          <IconCupSolid
            class="text-dark-green"
            style="width: 48px; height: 48px; max-width: 48px"
          />
        </template>
        <template #append>
          <div class="history-summary">
            <p>
              {{ t('tournamentTab.history.lose') }}:
              {{ `${item.total_games_played - item.total_games_won}/${item.total_games_played}` }}
            </p>
            <p>
              {{ t('tournamentTab.history.winnings') }}:
              {{ `${item.total_games_won}/${item.total_games_played}` }}
            </p>
          </div>
        </template>
      </ListItem>
    </div>
    <ListItem v-else class="mt-8"> {{ t('tournamentTab.history.noHistory') }} </ListItem>
  </div>
</template>

<style scoped>
.history-subtitle {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.history-summary p {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="50" viewBox="0 0 49 50" fill="none">
    <path
      d="M24.4391 45.8053C35.7149 45.8053 44.8558 36.6644 44.8558 25.3886C44.8558 14.1128 35.7149 4.97192 24.4391 4.97192C13.1633 4.97192 4.02246 14.1128 4.02246 25.3886C4.02246 36.6644 13.1633 45.8053 24.4391 45.8053Z"
      fill="#F3FFE9"
    />
    <path
      d="M31.1355 23.9593H27.6646V15.8743C27.6646 13.996 26.6438 13.6081 25.3984 15.0168L24.5 16.0377L16.905 24.6739C15.8638 25.8581 16.2925 26.8177 17.8646 26.8177H21.3354V34.9027C21.3354 36.781 22.3563 37.1689 23.6017 35.7602L24.5 34.7393L32.095 26.1031C33.1363 24.9189 32.7075 23.9593 31.1355 23.9593Z"
      fill="#577B34"
    />
  </svg>
</template>

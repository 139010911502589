<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { notify } from '@/stores/message'
import { useUserClaimStore } from '@/stores/userClaim'
import { amountFormatWithSymbol, hashToShortString } from '@/utils/amountFormat'
import { dateYYYYMMDD } from '@/utils/date'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BtnPrimary from '../common/BtnPrimary.vue'
import CardWithBorderGradient from '../common/CardWithBorderGradient.vue'
import IconTon from '../icons/IconTon.vue'
import IconUsd from '../icons/IconUsd.vue'

const loading = ref(false)

const { t } = useI18n()

const authStore = useAuthStore()
const { getUser } = authStore
const { user } = storeToRefs(authStore)

const claimsStore = useUserClaimStore()
const { getReferralPayments, getTotalClaimAmountAvailable, submitClaim, getClaims } = claimsStore
const { totalClaimAmountAvailable, userClaims } = storeToRefs(claimsStore)

onMounted(async () => {
  loading.value = true
  await Promise.allSettled([
    getUser(),
    getReferralPayments(1, 10),
    getTotalClaimAmountAvailable(),
    getClaims()
  ]).finally(() => {
    loading.value = false
  })
})

const claim = () => {
  if (totalClaimAmountAvailable.value === 0) {
    notify('No claim available', 'error')
    return
  }

  submitClaim(totalClaimAmountAvailable.value).then(() => {
    notify(
      `Claimed! The amount will be sent to ${user.value?.wallet_address_shortened} within 24hrs`,
      'success'
    )
  })
}

//TODO delete claimTab.vue after finished redesign
</script>

<template>
  <div>
    <h2 class="mt-4 page-title" style="font-size: 24px">{{ t('claimTab.getCashback') }}!</h2>

    <div class="mt-15 flex items-center justify-center gap-2 earn-value">
      <IconUsd style="width: 47px; height: 47px" class="text-primary" />{{
        totalClaimAmountAvailable
      }}
    </div>
    <BtnPrimary class="mt-6" :padding-x="35" @click="claim" disabled>
      {{ t('claimTab.claim') }}
    </BtnPrimary>

    <CardWithBorderGradient class="mt-15">
      <div class="earns-table-wrapper">
        <table class="earns-table">
          <tr>
            <th class="text-start" width="33.333%">{{ t('claimTab.tableHead.date') }}</th>
            <th class="text-center" width="33.333%">{{ t('claimTab.tableHead.amount') }}</th>
            <th class="text-end">{{ t('claimTab.tableHead.hash') }}</th>
          </tr>
          <template v-if="userClaims?.length">
            <tr v-for="claim in userClaims" :key="claim.id" class="my-2">
              <td class="date-value">{{ dateYYYYMMDD(claim.created_at) }}</td>
              <td class="amount-value">
                <IconTon style="width: 14px; height: 14px" />
                <div class="text-bold">{{ amountFormatWithSymbol(claim.amount) }} TON</div>
              </td>
              <td class="hash-value">{{ hashToShortString(claim.wallet_address_shortened) }}</td>
            </tr>
          </template>
          <tr v-else class="my-2">
            <td colspan="3" class="text-bold text-center">
              {{ t('claimTab.noEarningsYet') }}
            </td>
          </tr>
        </table>
      </div>
    </CardWithBorderGradient>
  </div>
</template>

<style scoped>
.earn-value {
  color: #fff;
  font-family: Urbanist;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}
.earns-table-wrapper {
  padding: 18px 30px;
}
.earns-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  font-family: Urbanist;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.06px;
}
.earns-table td {
  padding: 8px 0;
}
.earns-table th {
  color: #88ae63;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07px;
}
.earns-table .date-value {
  color: var(--white, #ecf2e5);
  font-weight: 400;
  text-align: start;
}
.earns-table .amount-value {
  color: var(--primary, #b1f770);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.earns-table .hash-value {
  color: var(--primary, #b1f770);
  font-weight: 400;
  text-decoration-line: underline;
  text-align: end;
}
</style>

<script setup lang="ts">
import { computed, ref, shallowRef } from 'vue'
import ComingSoon from './common/ComingSoon.vue'
import Container from './common/Container.vue'
import TabsPanel from './common/TabsPanel.vue'
import TournamentsList from './tournament/TournamentsList.vue'

import RatingsList from './tournament/RatingsList.vue'
import HistoryList from './tournament/TournamentHistory.vue'

const TOURNAMENT_TAB = 'tournamentTab.tabs.tournaments'
const RATING_TAB = 'tournamentTab.tabs.ratings'
const HISTORY_TAB = 'tournamentTab.tabs.history'

const tabs = shallowRef([
  { id: 1, title: TOURNAMENT_TAB },
  { id: 2, title: RATING_TAB },
  { id: 3, title: HISTORY_TAB }
])

const currentTab = ref(TOURNAMENT_TAB)
const showTabs = ref(true)

const showTab = ref(true)

const activeComponent = computed(() => {
  switch (currentTab.value) {
    case TOURNAMENT_TAB:
      return TournamentsList
    case RATING_TAB:
      return RatingsList
    case HISTORY_TAB:
      return HistoryList
    default:
      return TournamentsList
  }
})
</script>

<template>
  <Container v-if="showTab">
    <TabsPanel
      v-if="currentTab === TOURNAMENT_TAB ? showTabs : true"
      class="mt-8"
      :tabs="tabs"
      v-model:tab="currentTab"
    />

    <div class="mt-8">
      <!-- <Transition name="fade" mode="out-in"> -->
      <Component :is="activeComponent" v-model:showTabs="showTabs"></Component>
      <!-- </Transition> -->
    </div>
  </Container>
  <ComingSoon v-else />
</template>

<style scoped></style>

<script setup lang="ts">
import { useUserTasksStore } from '@/stores/userTasks'
import { TAB_BOOSTS, TAB_EARNS, TAB_HOME, TAB_INVITE, TAB_RAFFLE } from '@/utils/tabs'
import { storeToRefs } from 'pinia'
import { shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps(['currentTab'])
defineEmits(['update:currentTab'])

const tabs = shallowRef([
  { title: TAB_HOME, img: 'home' },
  { title: TAB_BOOSTS, img: 'boost' },
  { title: TAB_INVITE, img: 'friends' },
  { title: TAB_RAFFLE, img: 'ruffle' },
  { title: TAB_EARNS, img: 'tasks' }
])

const { hasNewTask } = storeToRefs(useUserTasksStore())

const { t } = useI18n()
</script>

<template>
  <div class="container tabs-container">
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.title"
        class="tab"
        :class="{
          newBadge: hasNewTask && tab.title == TAB_EARNS,
          active: currentTab == tab.title
        }"
        @click="$emit('update:currentTab', tab.title)"
      >
        <!-- :style="{ 'background-image': `url(/img/tabs/${tab.img}.webp)` }" -->
        <img :src="`/img/tabs/${tab.img}_v2.webp`" class="tab-img ofi-a" />
        <div class="tab-title">{{ t(tab.title) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tabs-container {
  position: fixed;
  z-index: 10;
  bottom: 16px;
  left: 0;
  right: 0;
}
.tabs {
  gap: 8px;
  width: 100%;
  max-width: 440px;
  border-radius: 22px;
  border: 1px solid rgba(178, 255, 107, 0.59);
  background: #314222;
  padding: 10px 12px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0 auto;
}
.tab {
  width: 100%;
  height: 58px;
  max-width: 54px;
  min-width: 50px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  /* padding: 48px 6px 8px; */
  /* opacity: 0.5; */
  /* background: var(--app-background-dark); */
  border: 1px solid var(--app-background-light);
  border-radius: 18px;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  color: var(--app-text-mute);
  position: relative;
  overflow: hidden;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
}
.tab-title {
  font-family: Urbanist;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  margin-bottom: 8px;
}
.tab.active {
  opacity: 1;
  color: var(--app-primary);
  border: 1px solid #5c7b3d;
  background: linear-gradient(180deg, #87bd51 -52.68%, #3e5725 90.18%);
}
/* .tab-img-container {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: all 0.2s;
} */
/* .tab-img-container.active {
  opacity: 1;
} */
.tab-img {
  /* max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: contain; */
  padding: 4px;
}

.newBadge::after {
  content: '';
  position: absolute;
  z-index: 5;
  width: 12px;
  height: 12px;
  background: var(--red);
  top: 8px;
  right: 8px;
  border-radius: 100%;
}
@media (min-width: 390px) {
  .tabs {
    gap: 14px;
    padding: 12px 16px;
  }
  /* .tab { */
  /* padding: 48px 8px 8px; */
  /* } */
}
</style>
